import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import ContainersDialog from '../ContainersDialog'
import ContainersTable from '../ContainersTable'

function ContainersSection(props) {
    const { showNotification, disabled } = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const [dialog, setDialog] = useState({ open: false, container: null })

    const containerDetails = useWatch({ name: 'containerDetails' })

    function handleRemoveContainer(data) {
        const containerDetails = [...getValues('containerDetails')].filter((container) =>
            container.id ? container.id !== data.id : container._id !== data._id
        )

        setValue('containerDetails', containerDetails, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('BOL', 'containerDetails') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${containerDetails.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <ContainersTable
                    data={containerDetails}
                    onAddContainer={() => setDialog({ open: true, container: null })}
                    onEditContainer={(container) => setDialog({ open: true, container })}
                    onDeleteContainer={handleRemoveContainer}
                />

                <ContainersDialog
                    onClose={() => setDialog({ open: false, container: null })}
                    open={dialog.open}
                    container={dialog.container}
                    showNotification={showNotification}
                    disabled={false}
                />
            </CngSection>
        </Grid>
    )
}

export default ContainersSection