import { Grid,Box, Card, useTheme } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import Stepper from '../../../components/aciacehighway/Stepper'
import ManifestAddForm from './ManifestAddPage'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'


const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {

  const theme = useTheme()

  return (
    <Card>
      <Stepper steps={['Create manifest']} activeStep={0} />
      <Box
        padding={2}
        style={{ backgroundColor: theme.palette.background.sectionOddBg }}
      >
        <ManifestAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => console.log('submit success')}
          onSetLoading={onSetLoading}
        />
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)
