import React, { useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceOcnBillofladingKeys from 'src/constants/locale/key/AceOcnBilloflading'
import FormProperties from '../../billoflading/FormProperties'
import { Box } from '@material-ui/core'
import BillViewContent from './../../billoflading/ViewContent'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function BillofLadingDialog(props) {
  const {
    billofLading,
    isView,
    manifestId,
    loading,
    onClose,
    onCreateBillofLading,
    onEditBillofLading,
    open,
    showNotification
  } = props
  const { initialValues, makeValidationSchema } = FormProperties.formikProps

  const { translate } = useTranslation(Namespace.ACE_OCN_BILLOFLADING)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [lookups, setLookups] = useState(null)

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function makeTranslatedTextsObject() {
    let updateButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.UPDATE_BUTTON
    )
    let saveButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SAVE_BUTTON
    )


    return {
      updateButton,
      saveButton,
    }
  }

  let viewContent = (
    <BillViewContent
      data={billofLading}
      getLookupValue={getLookupValue}
      //getCountryStateLabel={getCountryStateLabel}
    />
  )

  if(!open){
    return null
  }
  return (
    <>
      <CngDialog
        dialogContent={
          billofLading && isView ? (
            viewContent
          ) : (
            <CngForm
              fieldLevel='form'
              formikProps={{
                initialValues: {
                  ...(billofLading || initialValues),
                  manifestId: manifestId
                },
                makeValidationSchema: makeValidationSchema,
                onSubmit: (data) => {
                  if (billofLading && billofLading.id ) {
                    onEditBillofLading(data)
                  } else {
                    onCreateBillofLading(data)
                  }
                }
              }}
              formState={FormState.COMPLETED}
              renderBodySection={(labelMap, shouldHideMap) => (
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                  //getLookupValue={getLookupValue}
                  //getCountryStateLabel={getCountryStateLabel}
                />
              )}
              innerForm={true}
              renderButtonSection={() => (
                <Box display='flex' justifyContent='flex-end'>
                  <CngButton
                    color='primary'
                    size='medium'
                    type='submit'
                    disabled={loading}
                    shouldShowProgress={loading}
                  >
                    {billofLading && billofLading.id
                      ? translatedTextsObject.updateButton
                      : translatedTextsObject.saveButton}
                  </CngButton>
                </Box>
              )}
            />
          )
        }
        dialogAction={
          isView ? <CngButton onClick={onClose}>OK</CngButton> : null
        }
        dialogTitle='Bill of Lading'
        open={open}
        onClose={onClose}
        maxWidth='lg'
        fullWidth
      />
    </>
  )
}

export default BillofLadingDialog
