import React, { useState, useContext } from 'react'
import { components } from 'cng-web-lib'
import {
    Box,
    Grid,
    InputAdornment,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableHead-root': {
            backgroundColor: theme.palette.background.sectionOddBg,
            '& .MuiTableCell-head': {
                borderBottom: 0,
                fontSize: 12,
                fontWeight: 700,
                lineHeight: 1.2,
                textTransform: 'uppercase'
            }
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                '&:last-child': {
                    '& .MuiTableCell-root': {
                        borderBottom: 0
                    }
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '8px 16px'
        }
    }
}))

const {
    button: { CngButton, CngIconButton }
} = components


function GoodsDetailsTable(props) {
    const { data, onAddGoods, onEditGoods, onDeleteGoods, onViewGoods } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const classes = useStyles()

    const [searchTerm, setSearchTerm] = useState('')

    const filtered = searchTerm !== '' ?
        data.filter((goods) =>
            goods.goodsDesc.toLowerCase().includes(searchTerm.toLowerCase())
        ) : data

    return (
        <>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        {data.length > 0 && (
                            <TextField
                                fullWidth
                                onChange={(event) => setSearchTerm(event.target.value)}
                                placeholder='Search in your entries'
                                size='small'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <FontAwesomeIcon icon={['fal', 'search']} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    </Grid>

                    {onAddGoods && (
                        <Grid item xs='auto'>
                            <CngButton color='secondary' onClick={onAddGoods} size='medium'
                                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}>
                                Add Goods
                            </CngButton>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <TableContainer>
                <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{getTranslatedText('BOL', 'containerNumber')}</TableCell>
                            <TableCell>{getTranslatedText('BOL', 'goodsDesc')}</TableCell>
                            <TableCell>
                                {`${getTranslatedText('BOL', 'numberOfPackages')} / ${getTranslatedText('BOL', 'packageUom')}`}
                            </TableCell>
                            <TableCell>
                                {`${getTranslatedText('BOL', 'grossWeight')} / ${getTranslatedText('BOL', 'grossWeightUom')}`}
                            </TableCell>
                            {(onEditGoods || onDeleteGoods) && (<TableCell align='right'>Action</TableCell>)}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {filtered.length > 0 ? (
                            filtered.map((goods, index) => (
                                <TableRow key={goods._id || goods.id || index}
                                    onClick={() => {
                                        if (onViewGoods) {
                                            onViewGoods(goods)
                                        }
                                    }}
                                >
                                    <TableCell>{goods.containerNo}</TableCell>
                                    <TableCell>{goods.goodsDesc}</TableCell>
                                    <TableCell>
                                        <Typography component='div' variant='inherit'>
                                            {goods.numberOfPackages}
                                            <TinyChip label={goods.packageUom} variant='outlined' />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography component='div' variant='inherit'>
                                            {goods.grossWeight}
                                            <TinyChip label={goods.grossWeightUom} variant='outlined' />
                                        </Typography>
                                    </TableCell>
                                    {(onEditGoods || onDeleteGoods) && (
                                        <TableCell>
                                            <Box display='flex' flexDirection='row' justifyContent='flex-end' style={{ gap: 8 }}>
                                                {onEditGoods && (
                                                    <CngIconButton type='outlined' icon={['fal', 'pen']} onClick={() => onEditGoods(goods)} size='small' />
                                                )}
                                                {onDeleteGoods && (
                                                    <CngIconButton type='outlined' icon={['fal', 'trash']} onClick={() => onDeleteGoods(goods)} size='small' />
                                                )}
                                            </Box>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align='center' colSpan={4 + (onEditGoods || onDeleteGoods ? 1 : 0)}>
                                    <Typography variant='body2' color='textSecondary'>
                                        No records to display
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default GoodsDetailsTable
