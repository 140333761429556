import AceOcnVesselArrivalApiUrls from 'src/apiUrls/AceOcnVesselArrivalApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import {
  components,
  constants,
  useServices
} from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import { useHistory } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'

const {
  button: { CngButton },
  form: { CngEditForm },
  table: { useFetchCodeMaintenanceLookup }
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const {
    id,
    vesselArrivalData,
    onChangeStep,
    onSetLoading,
    onSetVesselArrivalStatus,
    onSubmitManifest,
    showNotification,
    step
  } = props

  const onSuccessCallback = useRef(() => { })
  const history = useHistory()
  const { securedSendRequest } = useServices()

  const [isSuperParty, setSuperParty] = useState(false);

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function preSubmitVesselArrival(data) {
    if (isSuperParty) {
      setSubmitDialog({ open: true, items: data })
    } else {
      data.chargeAs = 'REGULAR'
      handleSubmitVesselArrival(data)
    }
  }

  function handleSubmitVesselArrival(data) {
    onSetLoading(true)

    const fileForUserDetails = FileForUserGetUserDetails()
    if (fileForUserDetails) {
      const { fileForUserId, fileForUserLoginId, fileForUserPartyId } = fileForUserDetails
      data.fileForUserId = fileForUserId
      data.fileForUserLoginId = fileForUserLoginId
      data.fileForUserPartyId = fileForUserPartyId
    }

    securedSendRequest.execute('POST', AceOcnVesselArrivalApiUrls.SUBMIT, data,
      (response) => {
        const data = response.data
        var errorMessages = []
        var errorFlag = false

        var obj = data
        if (obj.errorMessages != null && obj.errorMessages.length > 0) {
          errorMessages.push(obj.errorMessages)
          errorFlag = true
        }

        if (errorFlag) {
          setErrorMessageDialog({ open: true, errorMessages })
        } else {
          showNotification('success', 'Submitted successfully.')
          history.push(pathMap.ACE_OCN_VESSEL_ARRIVAL_LIST_VIEW)
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Failed to submit Vessel Arrival.')
      },
      () => onSetLoading(false)
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngEditForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
              <FormProperties.Fields
                disabled={false}
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            )}
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
              url: AceOcnVesselArrivalApiUrls.GET,
              onPreSuccess: (serverData) => {
                vesselArrivalData.current = serverData
                onSetVesselArrivalStatus(serverData.arrivalStatus)
              }
            }}
            update={{
              url: AceOcnVesselArrivalApiUrls.PUT,
              onPostSubmitSuccess: (e) => {
                vesselArrivalData.current = e
                onSetVesselArrivalStatus(e.arrivalStatus)
                onSuccessCallback.current()
              }
            }}
            id={id}
            renderButtonSection={(_, loading) => (
              <VesselArrivalAddPageButtonSection
                history={history}
                loading={loading}
                onSetLoading={onSetLoading}
                onSubmitVesselArrival={preSubmitVesselArrival}
              />
            )}
          />
        </Grid>
      </Grid>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={handleSubmitVesselArrival}
        open={submitDialog.open}
        items={submitDialog.items}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </>
  )
}

export default EditPage

function VesselArrivalAddPageButtonSection(props) {
  const { history, loading, onSetLoading, onSubmitVesselArrival } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const { getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                // onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Update
              </CngButton>
              </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                size='medium'
                onClick={() => onSubmitVesselArrival(FormProperties.toServerDataFormat(getValues()))}>
                Submit
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACE_OCN_VESSEL_ARRIVAL_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}