import makeValidationSchema from './AciAirStatusNotifyListMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  statusType: '',
  email: '',
  phoneNumber: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `aciAirStatusNotifyList.${index}.${field}` : field
  }, [])

  function makeTranslatedTextsObject() {
    const statusType = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.STATUS_TYPE
    )
    const email = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.EMAIL
    )
    const phoneNumber = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.PHONE_NUMBER
    )

    return { statusType, email, phoneNumber }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.statusType}>
        <CngCodeMasterAutocompleteField
          name={getFieldName('statusType')}
          inputProps={{ maxLength: 3 }}
          label={translatedTextsObject.statusType}
          isRequired
          disabled={disabled}
          size='small'
          codeType='SYSTEM_STATUS'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.email}>
        <CngTextField
          name={getFieldName('email')}
          inputProps={{ maxLength: 150 }}
          label={translatedTextsObject.email}
          isRequired
          disabled={disabled}
          size='small'
          onBlur={(e) =>
            setValue('email', e.target.value.toUpperCase().trim(), {
              shouldValidate: true
            })
          }
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.phoneNumber || shouldHideMap?.hideOptionalFields}>
        <CngTextField
          name={getFieldName('phoneNumber')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.phoneNumber}
          disabled={disabled}
          size='small'
          onBlur={(e) =>
            setValue('phoneNumber', e.target.value.trim(), {
              shouldValidate: true
            })
          }
        />
      </CngGridItem>
    </Grid>
  )
}

const AciAirStatusNotifyListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AciAirStatusNotifyListFormProperties
