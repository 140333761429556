import React, { useEffect, useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import CngSection from 'src/components/cngcomponents/CngSection';
import { constants, useServices } from 'cng-web-lib'
import Response from './Response'
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'

const {
  filter: { EQUAL }
} = constants

function ResponseMessage({ showNotification, id, isViewOnly = false }) {
  const { fetchRecords, securedSendRequest } = useServices()
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title={getTranslatedText('VESSEL_DEPARTURE', 'aceOcnVesselDepartureResponse')}>
          <Response
            showNotification={showNotification}
            id={id}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ResponseMessage;