import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'
import AceOcnVesselArrivalResponseApiUrls from 'src/apiUrls/AceOcnVesselArrivalResponseApiUrls'
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function Response(props) {
  const { showNotification, id, isViewOnly = false } = props

  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecords } = useServices()
  const classes = useStyles()
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)

  function fetchVesselArrivalResponse() {
    return fetchRecords.execute(AceOcnVesselArrivalResponseApiUrls.GET,
      {
        filters: [
          {
            field: 'ocnVesselArrivalId',
            operator: EQUAL,
            value: id
          }
        ]
      }, (res) => res)
  }

  function showVesselArrivalResponse() {
    Promise.all([fetchVesselArrivalResponse()]).then(([respData]) => setData(respData.content))
  }
  
  useEffect(() => {
    showVesselArrivalResponse();
  }, [])

  const columns = [
    {
      field: 'transmissionDate',
      title: getTranslatedText('VESSEL_ARRIVAL', 'transmissionDate'),
      render: (data) =>
        data.transmissionDate &&
        moment(data.transmissionDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'cbpBatchNo',
      title: getTranslatedText('VESSEL_ARRIVAL', 'cbpBatchNo')
    },
    {
      field: 'remarks',
      title: getTranslatedText('VESSEL_ARRIVAL', 'remarks'),
      render: (data) =>
        (data.remarks == null || data.remarks == undefined || data.remarks == "") ? data.errorMsg : data.remarks
    },
    {
      field: 'cbpPort',
      title: getTranslatedText('VESSEL_ARRIVAL', 'cbpPort')
    },
    {
      field: 'receivedDate',
      title: getTranslatedText('VESSEL_ARRIVAL', 'receivedDate'),
      render: (data) =>
        data.receivedDate &&
        moment(data.receivedDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id + d.voyageNumber}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
                <TableRow>
                  <TableCell align='center' colSpan={columns.length}>
                    <Typography variant='body2' color='textSecondary'>
                      No records to display
                  </Typography>
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 2000]}
        component='div'
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

export default Response
