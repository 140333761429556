import React, { useContext } from 'react'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import makeValidationSchema from './ContainerDetailsMakeValidationSchema'
import { useFormContext } from "react-hook-form"

const {
  form: {
    field: { CngTextField,
      CngCodeMasterAutocompleteField }
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNumber: '',
  typeCode: '',
  descriptionCode: '',
  sealNumber: '',
  serviceCode: '',
  containerStatus: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  const { setValue, watch } = useFormContext();

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNumber}>
        <CngTextField
          name='containerNumber'
          label={getTranslatedText('BOL', 'containerNumber')}
          disabled={disabled}
          size='small'
          required
          onChange={(e) => {
            setValue('containerNumber', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue('containerNumber', e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.typeCode}>
        <CngCodeMasterAutocompleteField
          name='typeCode'
          label={getTranslatedText('BOL', 'typeCode')}
          disabled={disabled}
          size='small'
          codeType='BOL_CONTAINER_TYPE_CODE'
          required
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.descriptionCode}>
        <CngCodeMasterAutocompleteField
          name='descriptionCode'
          label={getTranslatedText('BOL', 'descriptionCode')}
          disabled={disabled}
          codeType='BOL_CONTAINER_DESC_CODE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNumber}>
        <CngTextField
          name='sealNumber'
          label={getTranslatedText('BOL', 'sealNumber')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue('sealNumber', e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue('sealNumber', e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.serviceCode}>
        <CngCodeMasterAutocompleteField
          name='serviceCode'
          label={getTranslatedText('BOL', 'serviceCode')}
          disabled={disabled}
          codeType='BOL_CONTAINER_SERVICE_CODE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerStatus}>
        <CngCodeMasterAutocompleteField
          name='containerStatus'
          label={getTranslatedText('BOL', 'containerStatus')}
          disabled={disabled}
          size='small'
          codeType='BOL_CONTAINER_STATUS_CODE'
          required
        />
      </CngGridItem>
    </Grid>
	)
}

const ContainerDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ContainerDetailsFormProperties
