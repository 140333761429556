import React, { useState, useContext , useEffect} from 'react'
import { components,constants } from 'cng-web-lib'
import {
    Box,
    Grid,
    InputAdornment,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableHead-root': {
            backgroundColor: theme.palette.background.sectionOddBg,
            '& .MuiTableCell-head': {
                borderBottom: 0,
                fontSize: 12,
                fontWeight: 700,
                lineHeight: 1.2,
                textTransform: 'uppercase'
            }
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                '&:last-child': {
                    '& .MuiTableCell-root': {
                        borderBottom: 0
                    }
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '8px 16px'
        }
    }
}))

const {
    button: { CngButton, CngIconButton },
    table: { useFetchCodeMaintenanceLookup }
} = components

const {
    filter: { EQUAL }
  } = constants

function ContainersTable(props) {
    const { data, onAddContainer, onEditContainer, onDeleteContainer, onViewContainer } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)
    const [lookups, setLookups] = useState(null)
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

    const classes = useStyles()

    const [searchTerm, setSearchTerm] = useState('')

    const filtered = searchTerm !== '' ?
        data.filter((container) =>
            container.containerNumber.toLowerCase().includes(searchTerm.toLowerCase())
        ) : data

        useEffect(() => {
            Promise.all([
              fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'BOL_CONTAINER_TYPE_CODE' }],
                undefined,
                'code'
              ),
              fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'BOL_CONTAINER_STATUS_CODE' }],
                undefined,
                'code'
              ),
            ]).then(
              ([
                typeCode,
                containerStatus
              ]) => {
                setLookups({
                    typeCode,
                    containerStatus
                })
              }
            )
          }, [])
        
          function getLookupValue(name, value) {
            if (!lookups) return value
        
            return lookups[name] && lookups[name][value] ? lookups[name][value] : value
          }

    return (
        <>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        {data.length > 0 && (
                            <TextField
                                fullWidth
                                onChange={(event) => setSearchTerm(event.target.value)}
                                placeholder='Search in your entries'
                                size='small'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <FontAwesomeIcon icon={['fal', 'search']} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    </Grid>

                    {onAddContainer && (
                        <Grid item xs='auto'>
                            <CngButton color='secondary' onClick={onAddContainer} size='medium'
                                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}>
                                Add Container
                            </CngButton>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <TableContainer>
                <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{getTranslatedText('BOL', 'containerNumber')}</TableCell>
                            <TableCell>{getTranslatedText('BOL', 'typeCode')}</TableCell>
                            <TableCell>{getTranslatedText('BOL', 'containerStatus')}</TableCell>
                            {(onEditContainer || onDeleteContainer) && (<TableCell align='right'>Action</TableCell>)}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {filtered.length > 0 ? (
                            filtered.map((container, index) => (
                                <TableRow key={container._id || container.id || index}
                                    onClick={() => {
                                        if (onViewContainer) {
                                            onViewContainer(container)
                                        }
                                    }}
                                >
                                    <TableCell>{container.containerNumber}</TableCell>
                                    <TableCell>{getLookupValue('typeCode', container.typeCode)}</TableCell>
                                    <TableCell>{getLookupValue('containerStatus', container.containerStatus)}</TableCell>
                                    {(onEditContainer || onDeleteContainer) && (
                                        <TableCell>
                                            <Box display='flex' flexDirection='row' justifyContent='flex-end' style={{ gap: 8 }}>
                                                {onEditContainer && (
                                                    <CngIconButton type='outlined' icon={['fal', 'pen']} onClick={() => onEditContainer(container)} size='small' />
                                                )}
                                                {onDeleteContainer && (
                                                    <CngIconButton type='outlined' icon={['fal', 'trash']} onClick={() => onDeleteContainer(container)} size='small' />
                                                )}
                                            </Box>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align='center' colSpan={3 + (onEditContainer || onDeleteContainer ? 1 : 0)}>
                                    <Typography variant='body2' color='textSecondary'>
                                        No records to display
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ContainersTable
