import makeValidationSchema from './AciAirPartyListMakeValidationSchema'
import { components, constants, useTranslation } from 'cng-web-lib'
import React, { useCallback, useState, useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import { Box, Card, Divider, Grid, Typography } from '@material-ui/core'
import {NaPartyMasterAutoCompleteField, NaStateCodeAutoCompleteField} from 'src/components/na'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  saveFlag: false,
  partyIdentification: "",
  partyType: "",
  partyName1: "",
  partyName2: "",
  address1: "",
  address2: "",
  city: "",
  stateCode: "",
  postalCode: "",
  countryCode: "",
  contactPerson: "",
  telNo: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {  
  const { setValue, watch } = useFormContext()
  const saveFlag = watch('saveFlag')
  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `aciAirPartyList.${index}.${field}` : field
  }, [])
  const countryCode = useWatch({ name: getFieldName('countryCode') });
  const stateCode = useWatch({ name: getFieldName('stateCode') });

  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let partyType = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_TYPE
    )
    let partyDropDown = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_DROP_DOWN
    )
    let saveFlag = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.SAVE_FLAG
    )
    let partyIdentification = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_IDENTIFICATION
    )
    let partyName1 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_NAME1
    )
    let partyName2 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.PARTY_NAME2
    )
    let address1 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.ADDRESS1
    )
    let address2 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.ADDRESS2
    )
    let city = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.CITY
    )
    let stateCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.STATE_CODE
    )
    let postalCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.POSTAL_CODE
    )
    let countryCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.COUNTRY_CODE
    )
    let contactPerson = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.CONTACT_PERSON
    )
    let telNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirPartyList.TEL_NO
    )

    return {
      partyDropDown,
      saveFlag,
      partyIdentification,
      partyType,
      partyName1,
      partyName2,
      address1,
      address2,
      city,
      stateCode,
      postalCode,
      countryCode,
      contactPerson,
      telNo
    }
  }

  function handleApplyTemplate(options) {
    if (options !== null) {
      const {
        partytype,
        partyname1,
        partyname2,
        address1,
        address2,
        cityname,
        postalcode,
        statecode,
        countrycode,
        contactperson,
        telephonenumber
      } = options.data

      setValue('partyType', partytype || '')
      setValue('partyName1', partyname1 || '')
      setValue('partyName2', partyname2 || '')
      setValue('address1', address1 || '')
      setValue('address2', address2 || '')
      setValue('city', cityname || '')
      setValue('postalCode', postalcode || '')
      setValue('stateCode', statecode || '')
      setValue('countryCode', countrycode || '')
      setValue('contactPerson', contactperson || '')
      setValue('telNo', telephonenumber || '')
    } else {
      setValue('partyType', '')
      setValue('partyName1', '')
      setValue('partyName2', '')
      setValue('address1', '')
      setValue('address2', '')
      setValue('city', '')
      setValue('postalCode', '')
      setValue('stateCode', '')
      setValue('countryCode', '')
      setValue('contactPerson', '')
      setValue('telNo', '')
    }
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <CngGridItem xs={12} md={3} shouldHide={shouldHideMap?.partyType}>
              <CngCodeMasterAutocompleteField
                name={getFieldName('partyType')}
                label={translatedTextsObject.partyType}
                isRequired
                disabled={disabled}
                size='small'
                codeType='AIR_PARTY_TYPE'
              />
            </CngGridItem>
            <Grid item xs={12} md>
              <Grid container spacing={1}>
                <Grid item xs={12} md>
                  <Grid container spacing={2}>
                    <CngGridItem xs='auto' shouldHide={shouldHideMap?.saveFlag}>
                      <CngCheckboxField
                        label={
                          <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                            {translatedTextsObject.saveFlag}
                          </Typography>
                        }
                        name={getFieldName('saveFlag')}
                        disabled={disabled}
                        onChange={(e) => setValue('saveFlag', e.target.checked)}
                        size='small'
                        style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                      />
                    </CngGridItem>
                    <CngGridItem xs shouldHide={shouldHideMap?.partyIdentification}>
                      <CngTextField
                        name={getFieldName('partyIdentification')}
                        inputProps={{ maxLength: 35 }}
                        disabled={!saveFlag || disabled}
                        size='small'
                        isRequired={saveFlag}
                        onBlur={(e) => setValue('partyIdentification', e.target.value.toUpperCase().trim())}
                        label={translatedTextsObject.partyIdentification}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
                <Grid item xs='auto'>
                  <Divider orientation='vertical' />
                </Grid>
                <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.partyDropDown}>
                  <NaPartyMasterAutoCompleteField
                    name={getFieldName('partyDropDown')}
                    label={translatedTextsObject.partyDropDown}
                    disabled={disabled}
                    size='small'
                    onChange={(_, options) => handleApplyTemplate(options)}
                    lookupProps={{
                      filters: [
                        {
                          field: 'partyId',
                          operator: EQUAL,
                          value: FileForUserGetPartyId()
                        }
                      ]
                    }}
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partyName1}>
              <CngTextField
                name={getFieldName('partyName1')}
                inputProps={{ maxLength: 35 }}
                disabled={disabled}
                size='small'
                isRequired
                onBlur={(e) => setValue('partyName1', e.target.value.toUpperCase().trim())}
                label={translatedTextsObject.partyName1}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.partyName2 || shouldHideMap?.hideOptionalFields}>
              <CngTextField
                name={getFieldName('partyName2')}
                inputProps={{ maxLength: 35 }}
                disabled={disabled}
                size='small'
                onBlur={(e) => setValue('partyName2', e.target.value.toUpperCase().trim())}
                label={translatedTextsObject.partyName2}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.address1}>
              <CngTextField
                name={getFieldName('address1')}
                inputProps={{ maxLength: 35 }}
                disabled={disabled}
                size='small'
                isRequired
                onBlur={(e) => setValue('address1', e.target.value.toUpperCase().trim())}
                label={translatedTextsObject.address1}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.address2}>
              <CngTextField
                name={getFieldName('address2')}
                inputProps={{ maxLength: 35 }}
                disabled={disabled}
                size='small'
                isRequired
                onBlur={(e) => setValue('address2', e.target.value.toUpperCase().trim())}
                label={translatedTextsObject.address2}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.city}>
              <CngTextField
                name={getFieldName('city')}
                inputProps={{ maxLength: 35 }}
                disabled={disabled}
                size='small'
                isRequired
                onBlur={(e) => setValue('city', e.target.value.toUpperCase().trim())}
                label={translatedTextsObject.city}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.countryCode}>
              <CngCountryAutocompleteField
                name={getFieldName('countryCode')}
                label={translatedTextsObject.countryCode}
                disabled={disabled}
                key={countryCode}
                onChange={(e) => {
                  setValue(getFieldName('stateCode'), '')
                }}
                lookupProps={{
                  label: (record) => `${record.code};${record.descriptionEn}`
                }}
                size='small'
                required
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.stateCode || shouldHideMap?.hideOptionalFields}>
              <NaStateCodeAutoCompleteField
                name={getFieldName('stateCode')}
                label={translatedTextsObject.stateCode}
                size='small'
                countryCode={countryCode}
                key={countryCode + stateCode}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.postalCode || shouldHideMap?.hideOptionalFields}>
              <CngTextField
                name={getFieldName('postalCode')}
                inputProps={{ maxLength: 9 }}
                disabled={disabled}
                size='small'
                onBlur={(e) => setValue('postalCode', e.target.value.toUpperCase().trim())}
                label={translatedTextsObject.postalCode}
              />
            </CngGridItem>    
          </Grid>
        </Grid>
        <CngGridItem xs={12} shouldHideMap={(shouldHideMap?.contactPerson && shouldHideMap?.telNo) || shouldHideMap?.hideOptionalFields}>
          <Card variant='outlined'>
            <Box padding={1}>
              <Grid alignItems='center' container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Typography
                    color='textSecondary'
                    variant='body1'
                    style={{ fontWeight: 600 }}
                  >
                    Contact Person
                  </Typography>
                </Grid>
                <CngGridItem xs={12} md={6} shouldHideMap={shouldHideMap?.contactPerson}>
                  <CngTextField
                    name={getFieldName('contactPerson')}
                    inputProps={{ maxLength: 35 }}
                    disabled={disabled}
                    size='small'
                    onBlur={(e) => setValue('contactPerson', e.target.value.toUpperCase().trim())}
                    label={translatedTextsObject.contactPerson}
                  />
                </CngGridItem>
                <CngGridItem xs={12} md={3} shouldHideMap={shouldHideMap?.telNo}>
                  <CngTextField
                    name={getFieldName('telNo')}
                    inputProps={{ maxLength: 15 }}
                    disabled={disabled}
                    size='small'
                    onBlur={(e) => setValue('telNo', e.target.value.toUpperCase().trim())}
                    label={translatedTextsObject.telNo}
                  />
                </CngGridItem>
              </Grid>
            </Box>
          </Card>
        </CngGridItem>
      </Grid>
    </>
	)
}

const AciAirPartyListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AciAirPartyListFormProperties
