import React, { useContext , useState} from 'react'
import { Grid, Typography,Card,Divider,useTheme } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useWatch , useFormContext} from "react-hook-form"
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TemplateSelectDialogWithOptionACEOCN from '../TemplateSelectDialogWithOptionACEOCN'
const {
    button: { CngButton },
    form: {
        field: {
            CngTextField,
            CngCodeMasterAutocompleteField,
            CngCheckboxField
        }
    },
    CngGridItem
} = components

function BillOfLadingSection(props) {
    const { disabled, shouldHideMap,showNotification } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)
    const { setValue, getValues, reset, watch, trigger} = useFormContext();
    const theme = useTheme()
    const [billTemplateDialog, setBillTemplateDialog] = useState(false)
    const { billIndicator } = useWatch()

    const templateFlag = useWatch({ name: 'templateFlag' });
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        billoflading: null
    })

    return (
        <>
        <Grid item xs={12}>
        <Card variant='outlined' style={{ padding: 16 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm="auto">
              <CngButton
                onClick={() => setBillTemplateDialog(true)}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}
                variant='outlined'
                style={{ borderColor: theme.palette.divider, color: theme.palette.grey[500] }}
              >
                Use template
              </CngButton>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={12} sm>
              <Grid container spacing={2} alignItems="center">
                <CngGridItem xs={12} sm="auto" shouldHide={shouldHideMap.templateFlag}>
                  <CngCheckboxField
                    disabled={disabled}
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {getTranslatedText('BOL', 'templateFlag')}
                      </Typography>
                    }
                    name="templateFlag"
                    onChange={(e) =>
                      setValue('templateFlag', e.target.checked, {
                        shouldValidate: true
                      })
                    }
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm shouldHide={shouldHideMap.templateName}>
                  <CngTextField
                    required = {getValues("templateFlag")}
                    name="templateName"
                    inputProps={{ maxLength: 50 }}
                    placeholder={getTranslatedText('BOL', 'templateName')}
                    disabled={disabled || templateFlag === false}
                    onChange={(e) => {
                      setValue("templateName", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    onBlur={(e) => {
                      if (e.target.value !== undefined) {
                        setValue("templateName", e.target.value.trim(), { shouldValidate: true })
                      }
                    }}
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
        <Grid item xs={12} md={6}>
            <CngSection title={getTranslatedText('BOL', 'aceOcnBilloflading')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>

                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.billIndicator}>
                        <CngCodeMasterAutocompleteField
                            name='billIndicator'
                            label={getTranslatedText('BOL', 'billIndicator')}
                            disabled={disabled}
                            codeType='ACE_OCN_BL_INDICATOR'
                            required
                            key={billIndicator}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.issuerScac}>
                        <CngTextField
                            name='issuerScac'
                            label={getTranslatedText('BOL', 'issuerScac')}
                            disabled={disabled}
                            required
                            size='small'
                            onChange={(e) => {
                              setValue('issuerScac', e.target.value.toUpperCase(), {
                                shouldValidate: true
                              })
                            }}
                            onBlur={(e) => {
                              if (e.target.value !== undefined) {
                                setValue('issuerScac', e.target.value.trim(), {
                                  shouldValidate: true
                                })
                              }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.billNumber}>
                        <CngTextField
                            name='billNumber'
                            label={getTranslatedText('BOL', 'billNumber')}
                            disabled={disabled}
                            required
                            size='small'
                            onChange={(e) => {
                              setValue('billNumber', e.target.value.toUpperCase(), {
                                shouldValidate: true
                              })
                            }}
                            onBlur={(e) => {
                              if (e.target.value !== undefined) {
                                setValue('billNumber', e.target.value.trim(), {
                                  shouldValidate: true
                                })
                              }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.oceanBillNumber}>
                        <CngTextField
                            name='oceanBillNumber'
                            label={getTranslatedText('BOL', 'oceanBillNumber')}
                            disabled={disabled}
                            size='small'
                            onChange={(e) => {
                              setValue('oceanBillNumber', e.target.value.toUpperCase(), {
                                shouldValidate: true
                              })
                              setValue('notifyScac', e.target.value.slice(0, 4), {
                                shouldValidate: true
                              })
                            }}
                            onBlur={(e) => {
                              if (e.target.value !== undefined) {
                                setValue('oceanBillNumber', e.target.value.trim(), {
                                  shouldValidate: true
                                })
                              }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.notifyScac}>
                        <CngTextField
                            required={billIndicator === 'N' || billIndicator === 'P'}
                            name='notifyScac'
                            label={getTranslatedText('BOL', 'notifyScac')}
                            disabled={disabled}
                            size='small'
                            onChange={(e) => {
                              setValue('notifyScac', e.target.value.toUpperCase(), {
                                shouldValidate: true
                              })
                            }}
                            onBlur={(e) => {
                              if (e.target.value !== undefined) {
                                setValue('notifyScac', e.target.value.trim(), {
                                  shouldValidate: true
                                })
                              }
                            }}
                        />
                    </CngGridItem>
                </Grid>
                <TemplateSelectDialogWithOptionACEOCN
                manifestTemplateDialog={billTemplateDialog}
                setManifestTemplateDialog={setBillTemplateDialog}
                setConfirmDialog={setConfirmDialog}
                showNotification={showNotification}
                setValue={setValue}
                reset={reset}
                trigger={trigger}
                title={"Bill of lading Template"}
          />
            </CngSection>
        </Grid>
        </>
    )
}

export default BillOfLadingSection