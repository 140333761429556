import React,{useState} from 'react'
import { components, constants, useTranslation,useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceOcnBillofladingKeys from 'src/constants/locale/key/AceOcnBilloflading'
import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import axios from 'axios'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import AceOcnManifestApiUrls from 'src/apiUrls/AceOcnManifestApiUrls'
import ConfirmDialog from '../../../common/ConfirmDialog'

const {
  button: { CngButton },
  CngDialog
} = components

const {
  filter: { LIKE, EQUAL,IN ,IS_NULL}
} = constants

function AttachBillofLadingDialog(props) {
  const {
    columns,
    manifestId,
    open,
    onAttachBillofLading,
    onClose,
    showNotification
  } = props

  const { translate } = useTranslation(Namespace.ACE_OCN_BILLOFLADING)
  const translatedTextsObject = makeTranslatedTextsObject()
  const {
    createRecord,
    deleteRecord,
    fetchRecords,
    securedSendRequest,
    updateRecord
  } = useServices()

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    data: null
  })

  function makeTranslatedTextsObject() {
    let attachBillSuccessMsg = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ATTACH_CARGO_SUCESS_MSG
    )
    let attach = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ATTACH
    )
    let attachSentRecord = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ATTACH_SENT
    )

    return {
      attachBillSuccessMsg,
      attach,
      attachSentRecord,
    }
  }

  function handleSubmitSelectedBillofLading() {
    let rows = confirmDialog.data
    const result = {
      manifestId,
      billofLadingId: rows.map((billofLading) => billofLading.id)
    }

    securedSendRequest.execute(
        'POST',
        AceOcnManifestApiUrls.ATTACH,
        result,
        (response) => {
          const data = response.data
          if (data.errorMessages != null && data.errorMessages.length > 0) {
            showNotification('error', data.errorMessages)
          } else if (
            data.errorMessages === null ||
            data.errorMessages === undefined
          ) {
            onAttachBillofLading()
            setConfirmDialog({ open: false, data: null })
            onClose()
          }
        },
        (error) => console.log(error)
      )
  }

  return (
    <>
    <CngDialog
      dialogTitle='Attach bill of lading'
      dialogContent={
        <Table
          checkboxSelection
          columns={columns}
          compact
          fetch={{ url: AceOcnBillofladingApiUrls.GET }}
          fetchFilters={[
             { field: 'manifestId', operator:EQUAL, value: 'null' },
             { field: 'status', operator: IN, value: ['1005', '1011']  },
             { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
          ]}
          selectActions={[
            {
              disabled: (rows) => {
                let shouldDisable = false

                rows.forEach((rowData) => {
                  if (
                    rowData.status === '1008'
                  ) {
                    shouldDisable = true
                  }
                })

                return shouldDisable
              },
              icon: <FontAwesomeIcon icon={['fal', 'link']} />,
              label: translatedTextsObject.attach,
              onClick: (rows) => setConfirmDialog({ open: true, data: rows }),
              tooltip: (rows) => {
                let includesSentRecords = false

                rows.forEach((rowData) => {
                  if (
                    rowData.status === '1008' ||  rowData.status === '1011'
                  ) {
                    includesSentRecords = true
                  }
                })

                return includesSentRecords
                  ? translatedTextsObject.attachSentRecord
                  : null
              }
            }
          ]}
          search={{ field: 'ccn', operator: LIKE }}
          showNotification={showNotification}
          sortConfig={{
            type: 'column',
            defaultDirection: 'DESC',
            defaultField: 'updatedDate'
          }}
        />
      }
      dialogAction={
        <CngButton color='secondary' onClick={onClose}>
          Discard
        </CngButton>
      }
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={onClose}
      shouldShowCloseButton
    />
    <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, data: null })}
        confirmDialog={handleSubmitSelectedBillofLading}
        content="All selected bills are going to attach with manifest. Would you like to proceed ?"
        okMsg='Yes'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default AttachBillofLadingDialog
