import React, { useRef, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import { Box, Grid } from '@material-ui/core'
import CngField from 'src/components/cngcomponents/CngField'
import GoodsDetailsFormProperties from './GoodsDetailsFormProperties'

const {
    button: { CngPrimaryButton },
    form: { CngForm },
    CngDialog
} = components

const { FormState } = constants

function GoodsDetailsDialog(props) {
    const { open, onClose, goods, showNotification, disabled , containerNoList} = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)
    const formContext = useFormContext()
    const lastIndex = useRef(0)

    const { initialValues, makeValidationSchema } = GoodsDetailsFormProperties.formikProps

    function handleAddGoods(data) {
        const goods = [...formContext.getValues('goodsDetails'), data].map(
            (good) => ({ ...good, _id: ++lastIndex.current })
        )

        formContext.setValue('goodsDetails', goods)
        onClose()
    }

    function handleEditGoods(data) {
        const goods = [...formContext.getValues('goodsDetails')]
        const index = goods.findIndex((good) =>
            good.id ? good.id === data.id : good._id === data._id
        )

        goods[index] = data

        formContext.setValue('goodsDetails', goods)
        onClose()
    }

    if (!open) {
        return null
    }
   
    let viewContent = goods && (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CngSection title={getTranslatedText('BOL', 'goodsDetails')}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'containerNumber')}>
                                {goods.containerNo}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'goodsDesc')}>
                                {goods.goodsDesc}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'numberOfPackages')}>
                                {goods.numberOfPackages}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'packageUom')}>
                                {goods.packageUom}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'marksAndNum')}>
                                {goods.marksAndNum}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'grossWeight')}>
                                {goods.grossWeight}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'grossWeightUom')}>
                                {goods.grossWeightUom}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'volume')}>
                                {goods.volume}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'volumeUOM')}>
                                {goods.volumeUOM}
                            </CngField>
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>


            <Grid item xs={12}>
                <CngSection title={getTranslatedText('BOL', 'dGgoodsDetails')}>
                    {goods.dangerousGoods && (
                        <Grid container spacing={2}>
                            {goods.dangerousGoods.map(goods => (
                                <Grid key={goods.id || goods._id} item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'imdgNo')}>
                                                {goods.imdgNo}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'flashPointTemp')}>
                                                {goods.flashPointTemp}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'dgCode')}>
                                                {goods.dgCode}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'dgClass')}>
                                                {goods.dgClass}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'dgCodeQual')}>
                                                {goods.dgCodeQual}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'hazardousDesc')}>
                                                {goods.hazardousDesc}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'hazardousPageNo')}>
                                                {goods.hazardousPageNo}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'materialDesc')}>
                                                {goods.materialDesc}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'materialClass')}>
                                                {goods.materialClass}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'emergencyContact')}>
                                                {goods.emergencyContact}
                                            </CngField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <CngField label={getTranslatedText('BOL', 'negative')}>
                                                {goods.negative}
                                            </CngField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CngSection>
            </Grid>
        </Grid>
    )

    return (
        <CngDialog
            dialogContent={
                disabled ? (viewContent) : (
                    <CngForm
                        fieldLevel='form'
                        formikProps={{
                            initialValues: goods || initialValues,
                            makeValidationSchema: makeValidationSchema,
                            onSubmit: (data) => {
                                if (goods) {
                                    handleEditGoods(data)
                                } else {
                                    handleAddGoods(data)
                                }
                            }
                        }}
                        formState={FormState.COMPLETED}
                        innerForm={true}
                        renderBodySection={(labelMap, shouldHideMap) => (
                            <GoodsDetailsFormProperties.Fields showNotification={showNotification} shouldHideMap={shouldHideMap} containerNoList={containerNoList}/>
                        )}
                        renderButtonSection={() => (
                            <Box display='flex' justifyContent='flex-end'>
                                <CngPrimaryButton type='submit' disabled={formContext ? formContext.formState.isSubmitting : false}>
                                    {goods ? "Update" : "Save"}
                                </CngPrimaryButton>
                            </Box>
                        )}
                    />
                )
            }
            dialogTitle={getTranslatedText('BOL', 'goodsDetails')}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            fullWidth
            maxWidth='lg'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default GoodsDetailsDialog
