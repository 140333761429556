import React from 'react'
import { Chip } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import moment from 'moment-timezone'
import AceOcnBillofladingApiUrls from "../../../apiUrls/AceOcnBillofladingApiUrls"
import { DateTimeFormatter, constants } from 'cng-web-lib'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

function TemplateSelectDialogWithOptionACEOCN(props) {
  const {
    manifestTemplateDialog,
    setManifestTemplateDialog,
    showNotification,
    setValue,
    rowActions,
    reset,
    trigger,
    isTable,
    location,
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()

  function handleApplyBillTemplate(template) {
    if (template) {
  const {
      billIndicator,
      billNumber,
      issuerScac,
      oceanBillNumber,
      notifyScac,
      carrierScac,
      vesselName,
      voyageNumber,
      vesselCountry,
      portOfDischarge,
      etaDateArr,
      placeofReciept,
      portOfLoading,
      lastForeignPort,
      //templateName,
      //templateFlag,
      //modeOfSubmission,
      //status,
      totalPackages,
      totalPackagesUom,
      totalGrossWeight,
      totalGrossWeightUom,
      totalVolume,
      totalValueUom,
      messageFunCode,
      commercialParties,
      containerDetails,
      goodsDetails,
  } = template
      
      goodsDetails.forEach((goodsDetail) => {
        goodsDetail.id = null;
        goodsDetail.createdBy = null;
        goodsDetail.createdDate = null;
        goodsDetail.updatedBy = null;
        goodsDetail.updatedDate = null;
        goodsDetail.dangerousGoods.forEach((dangerousGood) => {
          dangerousGood.id = null;
          dangerousGood.createdBy = null;
          dangerousGood.createdDate = null;
          dangerousGood.updatedBy = null;
          dangerousGood.updatedDate = null;
        });
      });

      commercialParties.forEach((commercialParty) => {
        commercialParty.id = null;
        commercialParty.createdBy = null;
        commercialParty.createdDate = null;
        commercialParty.updatedBy = null;
        commercialParty.updatedDate = null;
      });

      containerDetails.forEach((containerDetail) => {
        containerDetail.id = null;
        containerDetail.createdBy = null;
        containerDetail.createdDate = null;
        containerDetail.updatedBy = null;
        containerDetail.updatedDate = null;
      });

      if (isTable) {
        var templateJson = {
          "baseFilterDTO": null,
          "errorMessages": null,
          "customData": null,
          "id": null,
          "version": 0,
          "createdBy": null,
          "createdDate": null,
          "updatedBy": null,
          "updatedDate": null,
          "partyId": null,
          "billIndicator": billIndicator,
          "billNumber": billNumber,
          "issuerScac": issuerScac,
          "oceanBillNumber": oceanBillNumber,
          "notifyScac": notifyScac,
          "carrierScac": carrierScac,
          "templateFlag": false,
          "templateName": "",
          "status": "",
          "vesselName": vesselName,
          "voyageNumber": voyageNumber,
          "vesselCountry": vesselCountry,
          "portOfDischarge": portOfDischarge,
          "etaDateArr": etaDateArr,
          "placeofReciept": placeofReciept,
          "portOfLoading": portOfLoading,
          "lastForeignPort": lastForeignPort,
          "totalPackages": totalPackages,
          "totalPackagesUom": totalPackagesUom,
          "totalGrossWeight": totalGrossWeight,
          "totalGrossWeightUom": totalGrossWeightUom,
          "totalVolume": totalVolume,
          "totalValueUom": totalValueUom,
          "messageFunCode": messageFunCode,
          "commercialParties": commercialParties,
          "containerDetails": containerDetails,
          "goodsDetails": goodsDetails,
        }

        history.push(`${location.pathname}/add`, templateJson)

      } else {
        setValue('billIndicator', billIndicator || '', { shouldDirty: true })
        setValue('billNumber', '' || '', { shouldDirty: true })
        setValue('issuerScac', issuerScac || '', { shouldDirty: true })
        setValue('oceanBillNumber', oceanBillNumber || '', { shouldDirty: true })
        setValue('notifyScac', notifyScac || '', { shouldDirty: true })
        setValue('carrierScac', carrierScac || '', { shouldDirty: true })
        setValue('messageFunCode', messageFunCode || '', { shouldDirty: true })
        setValue('vesselName', vesselName || '', { shouldDirty: true })
        setValue('voyageNumber', voyageNumber || '', { shouldDirty: true })
        setValue('vesselCountry', vesselCountry || '', { shouldDirty: true })
        setValue('portOfDischarge', portOfDischarge || '', { shouldDirty: true })
        setValue('etaDateArr', etaDateArr || '', { shouldDirty: true })
        setValue('placeofReciept', placeofReciept || '', { shouldDirty: true })
        setValue('portOfLoading', portOfLoading || '', { shouldDirty: true })
        setValue('lastForeignPort', lastForeignPort || '', { shouldDirty: true })
        setValue('totalPackages', totalPackages || '', { shouldDirty: true })
        setValue('totalPackagesUom', totalPackagesUom || '', { shouldDirty: true })
        setValue('totalGrossWeight', totalGrossWeight || '', { shouldDirty: true })
        setValue('totalGrossWeightUom', totalGrossWeightUom || '', { shouldDirty: true })
        setValue('totalVolume', totalVolume || '', { shouldDirty: true })
        setValue('totalValueUom', totalValueUom || '', { shouldDirty: true })
        setValue('commercialParties', commercialParties || '', { shouldDirty: true })
        setValue('containerDetails', containerDetails || '', { shouldDirty: true })
        setValue('goodsDetails', goodsDetails || '', { shouldDirty: true })
                
        showNotification('success', 'Template applied successfully.')
        trigger()
      }

    } else {
      reset()
    }
  }

  const columns = [
    {
      field: 'id',
      title: 'ID'
    },
    {
      field: 'templateName',
      title: 'TEMPLATE NAME'
    },
    {
      field: 'billIndicator',
      title: 'Bill Indicator'
    },
    {
      field: 'billNumber',
      title: 'Bill Number'
    },
    {
      field: 'oceanBillNumber',
      title: 'Ocean Bill Number'
    },
    {
      field: 'status',
      sortKey: 'status',
      title: "Status",
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    },
    {
      field: 'createdDate',
      //sortKey: 'CREATED DATE',
      title: 'CREATED DATE',
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      //sortKey: 'UPDATED DATE',
      title: 'UPDATED DATE',
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    isTable ? {
      title: 'Action'
    } : {}
  ]

  const sortOptions = [
    {
      label: 'Status',
      value: 'status'
    }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      defaultSorts={[{ field: 'createdDate', direction: 'ASC' }]}
      fetch={{ url: AceOcnBillofladingApiUrls.GET }}
      fetchFilters={
        [
          { field: 'templateFlag', operator: 'equal', value: true },
          { field: 'status', operator: 'equal', value: '1002' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]
      }
      onApplyTemplate={handleApplyBillTemplate}
      onClose={() => setManifestTemplateDialog(false)}
      open={manifestTemplateDialog}
      search={{ field: 'templateName', operator: LIKE }}
      sortOptions={sortOptions}
      title='Bill of Lading template'
      rowActions={rowActions}
      isTable={isTable}
    />
  )
}

export default TemplateSelectDialogWithOptionACEOCN