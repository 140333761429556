import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    quantity: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(7, 'Max length allowed is 7.')
      .matches(/^\d+$/, 'Only numbers are allowed!'),
    uom: Yup.string()
      .required(requiredMessage)
      .max(3, 'Max length allowed is 3.')
      .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric values are allowed.')
      .nullable()
  })
}

export default makeValidationSchema
