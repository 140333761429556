import React, { useContext, useCallback } from 'react'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import _ from 'lodash'
import { useFormContext } from "react-hook-form"

const {
  form: {
    field: { CngTextField ,
      CngCodeMasterAutocompleteField}
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  imdgNo: "",
  flashPointTemp: "",
  dgCode: "",
  dgClass: "",
  dgCodeQual: "",
  hazardousDesc: "",
  hazardousPageNo: "",
  materialDesc: "",
  materialClass: "",
  emergencyContact: "",
  negative:"",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ disabled, shouldHideMap, index }) {
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  const { setValue, watch } = useFormContext();


  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `dangerousGoods.${index}.${field}` : field
  }, [])

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.imdgNo}>
        <CngCodeMasterAutocompleteField
          name={getFieldName('imdgNo')}
          label={getTranslatedText('BOL', 'imdgNo')}
          disabled={disabled}
          codeType='BOL_DG_GOODS_IMDG_NO'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.flashPointTemp}>
        <CngTextField
          name={getFieldName('flashPointTemp')}
          label={getTranslatedText('BOL', 'flashPointTemp')}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dgCode}>
        <CngTextField
          name={getFieldName('dgCode')}
          label={getTranslatedText('BOL', 'dgCode')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('dgCode'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('dgCode'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dgClass}>
        <CngTextField
          name={getFieldName('dgClass')}
          label={getTranslatedText('BOL', 'dgClass')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('dgClass'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('dgClass'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dgCodeQual}>
        <CngCodeMasterAutocompleteField
          name={getFieldName('dgCodeQual')}
          label={getTranslatedText('BOL', 'dgCodeQual')}
          disabled={disabled}
          codeType='BOL_DG_GOODS_CODE_QUAL'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.hazardousDesc}>
        <CngTextField
          name={getFieldName('hazardousDesc')}
          label={getTranslatedText('BOL', 'hazardousDesc')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('hazardousDesc'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('hazardousDesc'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.hazardousPageNo}>
        <CngTextField
          name={getFieldName('hazardousPageNo')}
          label={getTranslatedText('BOL', 'hazardousPageNo')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('hazardousPageNo'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('hazardousPageNo'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.materialDesc}>
        <CngTextField
          name={getFieldName('materialDesc')}
          label={getTranslatedText('BOL', 'materialDesc')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('materialDesc'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('materialDesc'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.materialClass}>
        <CngTextField
          name={getFieldName('materialClass')}
          label={getTranslatedText('BOL', 'materialClass')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('materialClass'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('materialClass'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.emergencyContact}>
        <CngTextField
          name={getFieldName('emergencyContact')}
          label={getTranslatedText('BOL', 'emergencyContact')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('emergencyContact'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('emergencyContact'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.negative}>
        <CngTextField
          name={getFieldName('negative')}
          label={getTranslatedText('BOL', 'negative')}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue(getFieldName('negative'), e.target.value.toUpperCase(), {
              shouldValidate: true
            })
          }}
          onBlur={(e) => {
              if (e.target.value !== undefined) {
                  setValue(getFieldName('negative'), e.target.value.trim(), {
                  shouldValidate: true
                  })
              }
          }}
        />
      </CngGridItem>
    </Grid>
	)
}

const DGgoodsDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default DGgoodsDetailsFormProperties
