import Namespace from 'src/constants/locale/Namespace'
import { Yup } from 'cng-web-lib'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const regexAlphaNumeric =
  '^[a-zA-Z0-9]+$'
  const errMsgAlphaNumeric =
  'Only Alpha Numeric Values are allowed'
  const regexAlphaNumericSpace =
  '^[a-zA-Z0-9 ]+$'
  const errMsgAlphaNumericSPace=
  'Only Alpha Numeric with Space is allowed'

  return Yup.object({
    scac: Yup.string().required(requiredMessage).nullable().max(4,"Max allowed length is 04.").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    voyageNumber: Yup.string().required(requiredMessage).nullable().max(5,"Max allowed length is 5.").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    vesselName: Yup.string().required(requiredMessage).nullable().max(23,"Max allowed length is 23.").matches(regexAlphaNumericSpace,errMsgAlphaNumericSPace),
    portOfDischarge: Yup.string().required(requiredMessage).nullable(),
    vesselCountry: Yup.string().nullable(),
    etaDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage).nullable(),
    modeOfTransport: Yup.string().required(requiredMessage).nullable(),
    paperlessInbondParticipant: Yup.string().required(requiredMessage).nullable(),
    issuerScac: Yup.string().required(requiredMessage).nullable().max(4,"Max allowed length is 04.").matches(regexAlphaNumeric,errMsgAlphaNumeric),
  })
}

export default makeValidationSchema
