import AceOcnVesselArrivalApiUrls from 'src/apiUrls/AceOcnVesselArrivalApiUrls'
import FormProperties from './FormProperties'
import { Grid, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import VesselArrivalViewContent from './VesselArrivalViewContent'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import pathMap from 'src/paths/pathMap'


const {
  form: { CngViewForm },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ViewPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()

  const { fetchRecord, fetchRecords, securedSendRequest } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        AceOcnVesselArrivalApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_OCN_STATUS' }],
        undefined,
        'code'
      ),
      fetchRecords.execute(NaCodeMaintenanceApiURLs.GET,
        {
          customData: { codeMType: 'DocPortCode' },
          filters: [{ field: 'set_indicator', operator: EQUAL, value: 'IFX_US' }],
        },
        (res) => {
          const result = {}
          res.content.forEach((item) => { result[item.code] = item.descriptionEn })
          return result
        }
      ),
      fetchRecords.execute(NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: 'CountryCode' },
        },
        (res) => {
          const result = {}
          res.content.forEach((item) => { result[item.code] = item.descriptionEn })
          return result
        }
      ),
    ]).then(
      ([
        data,
        status,
        portOfDischarge,
        country
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.ACE_OCN_VESSEL_ARRIVAL_LIST_VIEW)
        }

        data = FormProperties.toClientDataFormat(data);
        setData(data)
        setLookups({
          status,
          portOfDischarge,
          country
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  if (data === null) {
    return <CircularProgress />
  }

  return (
    <VesselArrivalViewContent
      data={data}
      getLookupValue={getLookupValue}
    />
  )
}

export default ViewPage
