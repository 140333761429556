import FormProperties from './FormProperties'
import { Grid,useTheme , Card,Box} from '@material-ui/core'
import React ,{useRef, useState} from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Stepper from '../../../components/aciacehighway/Stepper'
import ManifestEditPage from './ManifestEditPage'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import BillAttachTable from '../manifest/billoflading/BillAttachTable'
import ViewLogsHistory from './ViewLogsHistory'

const {
  form: { CngEditForm }
} = components

function EditPage({ history, loading, location, onSetLoading, showNotification }) {
  const { id } = useParams()

  const { state } = location
  const manifestData = useRef(state?.manifestData || null)
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [manifestStatus, setManifestStatus] = useState(null)
  const [stepIconColor, setStepIconColor] = useState({})
  const [completed, setCompleted] = useState({})
  const theme = useTheme()
  

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <ManifestEditPage
            id={id}
            manifestData={manifestData}
            onChangeStep={(step) => setStep(step)}
            onSetLoading={onSetLoading}
            onSetManifestStatus={(status) => setManifestStatus(status)}
            //onSubmitManifest={preSubmit}
            showNotification={showNotification}
            step={step}
          />
        )
      case 1:
        return (
          <BillAttachTable
            history={history}
            showNotification={showNotification}
            manifestId={id}
            onChangeStep={(step) => setStep(step)}
            step={step}
            loading={loading}
            onSetLoading={onSetLoading}
            manifestData={manifestData}
          />
        )
       case 2:
         return (
           <ViewLogsHistory
             manifestId={id}
             history={history}
             onChangeStep={(step) => setStep(step)}
             //onSubmitManifest={handleSubmitManifest}
             step={step}
           />
         )
    }
  }

  return (
    <>
    <Card>
      <Stepper
          steps={!manifestStatus || manifestStatus === 'DR' ? ['Create manifest', 'Add Bill of Lading'] : ['Create manifest', 'Add Bill of Lading', 'View logs & history']}
        activeStep={step}
        completed={completed}
        onSetActiveStep={(step) => setStep(step)}
        stepIconColor={stepIconColor}
      />
      <Box
        padding={2}
        style={{ backgroundColor: theme.palette.background.sectionOddBg }}
      >
        {renderContent(step)}
      </Box>
    </Card>
    </>
  )
}

export default withFormState(EditPage)
