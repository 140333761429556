import React, { useCallback, useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { getStatusMetadata } from '../../../../common/NACommon'
import CngField from '../../../../components/cngcomponents/CngField'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import moment from 'moment'
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography
} from '@material-ui/core'

const {
  button: { CngButton, CngIconButton },
  CngDialog
} = components

function SetResponseMessageDialog(props) {
  const {
    amendmentCodeOptions,
    onClose,
    onSaveBillofLading,
    onSubmitBillofLading,
    open,
    billofLadings,
    translatedTextsObject
  } = props

  const getStatusList = useCallback(() => {
    const result = []
    const statusList = [...new Set(billofLadings.map((billofLading) => billofLading.status))]

    statusList.forEach((status) => {
      result.push({
        data: { code: status, ...getStatusMetadata(status) },
        billofLadings: billofLadings.filter((billofLadings) => billofLadings.status === status)
      })
    })

    return result
  }, [billofLadings])

  return (
    <CngDialog
      dialogContent={
        <Grid container spacing={2}>
          {getStatusList().map((status, index) => (
            <Grid key={index} item xs={12}>
              <StatusItem
                amendmentCodeOptions={amendmentCodeOptions}
                index={index}
                status={status}
                translatedTextsObject={translatedTextsObject}
              />
            </Grid>
          ))}
        </Grid>
      }
      dialogAction={
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onClose} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onSaveBillofLading(billofLadings)}
                  size='medium'
                >
                  Save
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => {
                    onSubmitBillofLading(billofLadings)
                    onClose()
                  }}
                  size='medium'
                >
                  Submit to customs
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle='Set Message Function'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default SetResponseMessageDialog

function StatusItem(props) {
  const {
    index,
    status: { data, billofLadings },
    translatedTextsObject
  } = props

  const [expanded, setExpanded] = useState(false)
  const [messageFunction, setMessageFunction] = useState('A')

  useEffect(() => {
    billofLadings.forEach((billofLading) => {
      billofLading.messageFunCode = messageFunction
    })
  }, [messageFunction])

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md='auto'>
            <Typography variant='body1'>
              <Typography
                variant='inherit'
                style={{
                  color: data?.color || 'inherit',
                  fontWeight: 600
                }}
              >
                {data?.label}
              </Typography>
              &nbsp;
              <Typography variant='inherit'>({billofLadings.length})</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <Grid container spacing={2}>
              <Grid item xs={12} md>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor={`message-function-label-${index}`}>
                    {translatedTextsObject.messageFunction}
                  </InputLabel>
                  <Select
                    label={translatedTextsObject.messageFunction}
                    inputProps={{
                      id: `message-function-label-${index}`
                    }}
                    native
                    onChange={(event) => setMessageFunction(event.target.value)}
                    size='small'
                    value={messageFunction}
                  >
                    <option value='A'>
                      {translatedTextsObject.msgFuncOriginal}
                    </option>
                    <option value='R'>
                      {translatedTextsObject.msgFuncChange}
                    </option>
                    <option value='D'>
                      {translatedTextsObject.msgFuncCancel}
                    </option>
                  </Select>
                </FormControl>
              </Grid>
             </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
