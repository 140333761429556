import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import withFormState from 'src/components/aciacehighway/hocs/withFormState'
import BillofLadingEditForm from './BillofLadingEditPage'
import ViewBillLogsHistory from 'src/views/aceocean/billoflading/ViewBillLogsHistory'

const {
  button: { CngButton },
  form: { CngEditForm },
  CngTabs
} = components

function EditPage(props) {
  const { history, showNotification, onSetLoading , location} = props
  const { id } = useParams()
  const { state } = location
  const billofLadingData = useRef(state?.billofLadingData || null)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: "Edit Bill of Lading",
              tabContent: (
                <BillofLadingEditForm
                  showNotification={showNotification}
                  id={id}
                  billofLadingData={billofLadingData}
                  history={history}
                />
              )
            },
            {
              tabName: "Response",
              tabContent: (
                <ViewBillLogsHistory
                  billofLadingData={billofLadingData}
                  history={history}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default withFormState(EditPage)
