import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    saveFlag: Yup.boolean().required(requiredMessage),
    partyIdentification: Yup.string()
      .when('saveFlag', {
        is: false,
        then: (schema) => schema,
        otherwise: Yup.string().when('saveFlag', {
          is: true,
          then: Yup.string()
            .trim()
            .min(1, 'Max length allowed is 1.')
            .max(35, 'Max length is 35.')
            .required(requiredMessage)
            .matches(
              '[a-zA-Z0-9s]$',
              'Only alphanumeric characters are allowed.'
            )
        })
      })
      .nullable(),
    partyType: Yup.string()
      .required(requiredMessage)
      .min(2, 'Min length needed is 2.')
      .max(2, 'Max length allowed is 2.')
      .nullable(),
    partyName1: Yup.string()
      .trim()
      .max(35, 'Max length allowed is 35.')
      .required(requiredMessage)
      .matches('[a-zA-Z0-9s.,]$', 'Only alphanumeric values are allowed.'),
    partyName2: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .max(35, 'Max length allowed is 35.')
            .matches('[a-zA-Z0-9s.,]$', 'Only alphanumeric values are allowed.')
            .nullable()
    ),
    address1: Yup.string()
      .trim()
      .max(35, 'Max length allowed is 35.')
      .required(requiredMessage)
      .matches(
        /^[a-zA-Z0-9\s-.,]+$/,
        'Only alphanumeric, space and dashes are allowed.'
      ),
    address2: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .max(35, 'Max length allowed is 35.')
            .matches(
              /^[a-zA-Z0-9\s-.,]+$/,
              'Only alphanumeric, space and dashes are allowed.'
            )
            .nullable()
    ),
    city: Yup.string()
      .trim()
      .max(35, 'Max length allowed is 35.')
      .required(requiredMessage)
      .matches('[a-zA-Z0-9s]$', 'Only alphanumeric values are allowed.'),
    countryCode: Yup.string()
      .max(2, 'Max length allowed is 2.')
      .required(requiredMessage)
      .nullable(),
    postalCode: Yup.string()
      .when('countryCode', {
        is: 'CA',
        then: Yup.string()
          .trim()
          .max(9, 'Max length allowed is 9.')
          .required('Please enter a value for Postal Code.')
          .matches(
            /^[a-zA-Z]\d[a-zA-Z][ ]?\d[a-zA-Z]\d$/i,
            'Please enter Postal Code in proper format. (e.g. A1B 1C1)'
          ),
        otherwise: Yup.string().when('countryCode', {
          is: 'US',
          then: Yup.string()
            .trim()
            .max(9, 'Max length allowed is 9.')
            .required('Please enter a value for Postal Code.')
            .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed.')
        })
      })
      .nullable(),
    stateCode: Yup.string()
      .when('countryCode', {
        is: (countryCode) => countryCode === 'US' || countryCode === 'CA',
        then: Yup.lazy((value) =>
          value === ''
            ? Yup.string()
                .trim()
                .required('Please select a value for Province/State Code.')
            : Yup.string()
        ),
        otherwise: Yup.string().when('countryCode', {
          is: (countryCode) => countryCode !== 'US' || countryCode !== 'CA',
          then: Yup.lazy((value) =>
            value === ''
              ? Yup.string()
              : Yup.string()
                  .trim()
                  .nullable()
                  .matches('[a-zA-Z]$', 'Only alpha values are allowed.')
          )
        })
      })
      .nullable(),
    contactPerson: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .matches('[a-zA-Zs]$', 'Only alpha values are allowed.')
            .max(35, 'Max length allowed is 35.')
            .nullable()
    ),
    telNo: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .nullable()
            .matches('^[0-9]+$', 'Only numerical values are allowed.')
            .min(5, 'Min length needed is 5.')
            .max(15, 'Max length allowed is 15.')
    )
  })
}

export default makeValidationSchema
