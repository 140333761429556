import React, { useEffect, useState,useRef } from 'react'
import { constants, components, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import ViewContent from './ViewContent'
import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import FormProperties from './FormProperties'
import ViewBillLogsHistory from 'src/views/aceocean/billoflading/ViewBillLogsHistory'
import { Grid } from '@material-ui/core'



import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup },
  CngTabs
} = components

const { filter: { EQUAL } } = constants

function ViewPage(props) {
  const { history, showNotification } = props
  const { id } = useParams()
  const { fetchRecord } = useServices()
  const billofLadingData= useRef({})
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(AceOcnBillofladingApiUrls.GET, id, (res) => res.content[0]),

      // BL Indicator
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_OCN_BL_INDICATOR' }],
        undefined, 'code'
      ),
    ]).then(([data, billIndicator]) => {
      data = FormProperties.toClientDataFormat(data);
      billofLadingData.current = data
      setData({ ...data })
      setLookups({ billIndicator })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  if (data === null) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={3}>
    <Grid item xs={12}>
      <CngTabs
        headerColor='primary'
        tabs={[
          {
            tabName: "View Bill of Lading",
            tabContent: (
              <ViewContent
                showNotification={showNotification}
                id={id}
                data={data}
                getLookupValue={getLookupValue}
                billofLadingData={billofLadingData}
              />
            )
          },
          {
            tabName: "Response",
            tabContent: (
              <ViewBillLogsHistory
                billofLadingData={billofLadingData}
                history={history}
              />
            )
          }
        ]}
      />
    </Grid>
  </Grid>
);
}

export default ViewPage
