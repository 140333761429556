import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import ResponseLog from 'src/views/aceocean/manifest/ResponseLog'
const { button: { CngButton } } = components
const { filter: { EQUAL } } = constants

function ViewBillLogsHistory(props) {
  const { history, manifestId, onSubmitManifest, billofLadingData } = props
  const { fetchRecords } = useServices()
  return (
    <>
      <ResponseLog manifestId={""+billofLadingData.current?.manifestId+""}/>
    </>
  )
}

export default ViewBillLogsHistory
