import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import {
  Box,
  Grid,
  makeStyles,
  Select,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AttachBillofLadingDialog from './AttachBillofLadingDialog'
import { getStatusMetadata } from '../../../../common/NACommon'
import BillofLadingDialog from './BillofLadingDialog'
import BillofLadingFormProperties from '../../billoflading/FormProperties'

const {
  button: { CngButton, CngIconButton },
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL,LIKE }
} = constants

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.name === 'ONE_DARK' ? 'transparent' : theme.palette.common.white,
    fontSize: 14,
    maxWidth: '100%',
    width: 150,
    '& > .MuiOutlinedInput-input': {
      padding: 8
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32
    }
  }
}))

function BillofLadingViewTable(props) {
  const { disableView = false, getCountryStateLabel, getLookupValue, manifestId, loading, showNotification} = props

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACE_OCEAN_BILL_OF_LADING
  ])
  const theme = useTheme()
  const tableRef = useRef(null)
  const [attachBillofLadingDialog, setAttachBillofLadingDialog] = useState(false)
  const {
    fetchRecords
  } = useServices()

  const [billofLadingDialog, setBillofLadingDialog] = useState({
    open: false,
    billofLading: null,
    isView: false
  })

  const columns = [
    {
      field: "billNumber",
      sortKey: "billNumber",
      title: "Bill Number",
    },
    {
      field: "oceanBillNumber",
      sortKey: "oceanBillNumber",
      title: "Ocean Bill Number",
    },
    {
      field: "etaDateArr",
      sortKey: "etaDateArr",
      title: "ETA",
    },
    {
      field: "messageFunCode",
      sortKey: "messageFunCode",
      title: "Message Function Code",
    },
    {
      field: "status",
      sortKey: "status",
      title: "Status",
      render: (data) => {

        let status = getStatusMetadata(data.status)

        let content = [
          <Box>
            <Typography variant='inherit' style={{ color: status.color }}>
              {status.label}
            </Typography>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            {content}
          </Box>
        )
      }
    },
  ]

  return (
    <>
      <Table
        actions={[
        ]}
        columns={columns}
        compact
        fetch={{ url: AceOcnBillofladingApiUrls.GET }}
        fetchFilters={[
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'manifestId', operator: EQUAL, value: manifestId }
        ]}
        onRowClick={(rowData) =>
          setBillofLadingDialog({ open: true, billofLading: BillofLadingFormProperties.toClientDataFormat(rowData), isView: true })
        }
        rowActions={[
        ]}
        search={{ field: 'billNumber', operator: LIKE }}
        showNotification={showNotification}
        tableRef={tableRef}
      />
      <AttachBillofLadingDialog
        columns={columns}
        manifestId={manifestId}
        open={attachBillofLadingDialog}
        onAttachBillofLading={() => {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }
        }}
        onClose={() => setAttachBillofLadingDialog(false)}
        showNotification={showNotification}
      />
      <BillofLadingDialog
        //getCountryStateLabel={getCountryStateLabel}
        billofLading={billofLadingDialog.billofLading}
        isView={billofLadingDialog.isView}
        manifestId={manifestId}
        loading={loading}
        onClose={() =>
          setBillofLadingDialog({ open: false, billofLading: null, isView: false })
        }
        open={billofLadingDialog.open}
        showNotification={showNotification}
        getLookupValue={getLookupValue}
      />
    </>
  )
}

export default BillofLadingViewTable
