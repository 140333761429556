import React, { useContext, useCallback } from 'react'
import { useFormContext } from "react-hook-form";
import { components, constants } from 'cng-web-lib'
import { Grid, Box, Typography } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import { NaPartyMasterAutoCompleteField } from 'src/components/na'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import _ from 'lodash'

const {
  form: {
    field: {
      CngTextField,
      CngCheckboxField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: "",
  partyName: "",
  address1: "",
  address2: "",
  address3: "",
  telNo: "",
  saveFlag: false,
  partyIdentifier: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ disabled, shouldHideMap, index }) {
  const { setValue, watch } = useFormContext();
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `commercialParties.${index}.${field}` : field
  }, [])

  const saveFlag = watch(getFieldName('saveFlag'))
  const partyType = watch(getFieldName('partyType'))

  function handleApplyTemplate(template) {
    if (!template) {
      return
    }

    const {
      partyname1,
      address1,
      address2,
      address3,
      telephonenumber
    } = template

    setValue(getFieldName("partyName"), partyname1 || '', { shouldDirty: true })
    setValue(getFieldName("address1"), address1 || '', { shouldDirty: true })
    setValue(getFieldName("address2"), address2 || '', { shouldDirty: true })
    setValue(getFieldName("address3"), address3 || '', { shouldDirty: true })
    setValue(getFieldName("telNo"), telephonenumber || '', { shouldDirty: true })
  }

	return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.partyType}>
          <CngCodeMasterAutocompleteField
            name={getFieldName("partyType")}
            label={getTranslatedText('BOL', 'partyType')}
            disabled={disabled}
            codeType='BOL_PARTY_TYPE'
            required
            size="small"
            key={partyType}
            onChange={(value) => setValue(getFieldName('partyType'), value)}
          />
        </CngGridItem>

        <CngGridItem xs={12} lg={8}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} md={3} shouldHide={shouldHideMap?.saveFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {getTranslatedText('BOL', 'saveFlag')}
                  </Typography>
                }
                name={getFieldName('saveFlag')}
                onChange={(e) => setValue(getFieldName('saveFlag'), e.target.checked)}
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md shouldHide={shouldHideMap?.partyIdentification}>
              <CngTextField
                required
                name={getFieldName('partyIdentifier')}
                inputProps={{ maxLength: 35 }}
                label={getTranslatedText('BOL', 'partyIdentifier')}
                disabled={disabled || !saveFlag}
                onChange={(e) => {
                  setValue(getFieldName('partyIdentifier'), e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue(getFieldName('partyIdentifier'), e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.partyDropDown}>
              <NaPartyMasterAutoCompleteField
                name={getFieldName("partyDropDown")}
                label={getTranslatedText('BOL', 'partyDropDown')}
                disabled={disabled}
                onChange={(_, options) => { handleApplyTemplate(options?.data) }}
                lookupProps={{
                  filters: [{ field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }]
                }}
                size="small"
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>

        <CngGridItem xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.partyName}>
              <CngTextField
                name={getFieldName("partyName")}
                inputProps={{ maxLength: 71 }}
                label={getTranslatedText('BOL', 'partyName')}
                disabled={disabled}
                onChange={(e) => {
                  setValue(getFieldName("partyName"), e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue(getFieldName("partyName"), e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
                required
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.address1}>
              <CngTextField
                name={getFieldName("address1")}
                inputProps={{ maxLength: 175 }}
                label={getTranslatedText('BOL', 'address1')}
                disabled={disabled}
                onChange={(e) => {
                  setValue(getFieldName("address1"), e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue(getFieldName("address1"), e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
                required
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.address2}>
              <CngTextField
                name={getFieldName("address2")}
                inputProps={{ maxLength: 175 }}
                label={getTranslatedText('BOL', 'address2')}
                disabled={disabled}
                onChange={(e) => {
                  setValue(getFieldName("address2"), e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue(getFieldName("address2"), e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.address3}>
              <CngTextField
                name={getFieldName("address3")}
                inputProps={{ maxLength: 175 }}
                label={getTranslatedText('BOL', 'address3')}
                disabled={disabled}
                onChange={(e) => {
                  setValue(getFieldName("address3"), e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue(getFieldName("address3"), e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
                size="small"
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.telNo}>
              <CngTextField
                name={getFieldName("telNo")}
                inputProps={{ maxLength: 12 }}
                label={getTranslatedText('BOL', 'telNo')}
                disabled={disabled}
                onChange={(e) => {
                  setValue(getFieldName("telNo"), e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue(getFieldName("telNo"), e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
                size="small"
              />
            </CngGridItem>
          </Grid>
        </CngGridItem>
      </Grid>
    </Box>
	)
}

const CommercialPartiesFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CommercialPartiesFormProperties
