import React, { useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'
import { Grid, Card, CardContent, Divider, Typography, Box, useTheme, InputAdornment, IconButton } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useTranslation
} from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import BillofLadingViewTable from './billoflading/BillofLadingViewTable'
import History from '../manifest/History'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSwitchField,
      CngCodeMasterAutocompleteField
    },
  },
  table: {
    CngLocalModeDialogFormTable,
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  scac: "",
  vesselName: "",
  voyageNumber: "",
  vesselCountry: "",
  portOfDischarge: "",
  etaDate: "",
  modeOfTransport: "",
  paperlessInbondParticipant: "",
  issuerScac: "",
  //partyId: "",
  //submittedBy: "",
  //submittedDate: "",
  modeOfSubmission: "",
  status: "",
  messageFunCode: "",
})

function ManifestViewContent(props) {

  const { id, data, getLookupValue } = props
  console.log(props);
  console.log(data);
  const { translate } = useTranslation(Namespace.ACE_OCN_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    let aceOcnManifest = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.TITLE
    )
    let scac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SCAC
    )
    let vesselName = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_NAME
    )
    let voyageNumber = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VOYAGE_NUMBER
    )
    let vesselCountry = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_COUNTRY
    )
    let portOfDischarge = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PORT_OF_DISCHARGE
    )
    let etaDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.E_T_A
    )
    let modeOfTransport = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_TRANSPORT
    )
    let paperlessInbondParticipant = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PAPERLESS_INBOND_PARTICIPANT
    )
    let issuerScac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.ISSUER_SCAC
    )
    let partyId = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_DATE
    )
    let modeOfSubmission = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_SUBMISSION
    )
    let status = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.STATUS
    )
    let messageFunCode = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MESSAGE_FUN_CODE
    )

    return {
      aceOcnManifest,
      scac,
      vesselName,
      voyageNumber,
      vesselCountry,
      portOfDischarge,
      etaDate,
      modeOfTransport,
      paperlessInbondParticipant,
      issuerScac,
      partyId,
      submittedBy,
      submittedDate,
      modeOfSubmission,
      status,
      messageFunCode
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.aceOcnManifest} >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.scac}>
                   {data.scac}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.vesselName}>
                  {data.vesselName}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.voyageNumber}>
                  {data.voyageNumber}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.vesselCountry}>
                  {data.vesselCountry}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.etaDate}>
                  {data.etaDate}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.portOfDischarge}>
                  {data.portOfDischarge}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.modeOfTransport}>
                  {data.modeOfTransport}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.paperlessInbondParticipant}>
                {data.paperlessInbondParticipant ? 'Yes' : 'No'}
                </CngField>
              </CngGridItem>

              <CngGridItem xs={12} sm={6} lg={4}>
                <CngField label={translatedTextsObject.issuerScac}>
                  {data.issuerScac}
                </CngField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="partyId"
                  label={translatedTextsObject.partyId}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="submittedBy"
                  label={translatedTextsObject.submittedBy}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="submittedDate"
                  label={translatedTextsObject.submittedDate}
                />
              </CngGridItem>
            </Grid>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CngSection title="Bill of Lading">
          <BillofLadingViewTable
            manifestId={data.id}
            //getLookupValue={getLookupValue}
           // getCountryStateLabel={getCountryStateLabel}
          />
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <History manifestId={data.id} />
      </Grid>
          
      {/* <Grid item xs={12}>
          <ActivityLogs manifestId={data.id} />
      </Grid> */}
    </Grid>
  )
}

export default ManifestViewContent
