import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React,{useRef,useState,useEffect} from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import pathMap from '../../../paths/pathMap'
import ConfirmDialog from 'src/views/common/ConfirmDialog'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage({ history,showNotification,billofLadingData }) {
  const { id } = useParams()
  const onSuccessCallback = useRef(() => {})
  const [lookups, setLookups] = useState(null)
  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }
  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AceOcnBillofladingApiUrls.GET,
            onPreSuccess: (serverData) => {
              billofLadingData.current = serverData
            }
          }}
          update={{
            url: AceOcnBillofladingApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              billofLadingData.current = e
              onSuccessCallback.current()
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <ButtonSection
                history={history}
                loading={loading}
            />
        )}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage

function ButtonSection(props) {
  const { history, loading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton color='primary' disabled={loading} type='submit' size='medium' shouldShowProgress={loading}>
            Update
          </CngButton>
        </Grid>
      </Grid>

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACE_OCN_BILLOFLADING_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
