import React, { useState, useContext } from 'react'
import StatusBar from 'src/components/aciacehighway/StatusBar'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import ContainersTable from './ContainersTable'
import ContainersDialog from './ContainersDialog'
import GoodsDetailsTable from './GoodsDetailsTable'
import GoodsDetailsDialog from './GoodsDetailsDialog'
import moment from 'moment-timezone'
import { Box, Card, Grid, Typography, useTheme } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import History from '../billoflading/History'

function ViewContent(props) {
    const { id, data, getLookupValue, billofLadingData } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const [containerDialog, setContainerDialog] = useState({ open: false, container: null })
    const [goodsDialog, setGoodsDialog] = useState({ open: false, goods: null })

    const theme = useTheme()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StatusBar status={data.status} />
            </Grid>

            <Grid item xs={12}>
                <CngSection title={getTranslatedText('BOL', 'aceOcnBilloflading')}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'billIndicator')}>
                                {getLookupValue('billIndicator', data.billIndicator)}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'issuerScac')}>
                                {data.issuerScac}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'billNumber')}>
                                {data.billNumber}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'oceanBillNumber')}>
                                {data.oceanBillNumber}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'notifyScac')}>
                                {data.notifyScac}
                            </CngField>
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>

            <Grid item xs={12}>
                <CngSection title={getTranslatedText('BOL', 'voyageReference')}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'carrierScac')}>
                                {data.carrierScac}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'vesselName')}>
                                {data.vesselName}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'voyageNumber')}>
                                {data.voyageNumber}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'vesselCountry')}>
                                {data.vesselCountry}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'portOfDischarge')}>
                                {data.portOfDischarge}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'etaDateArr')}>
                                {data.etaDateArr ? moment(data.etaDateArr).format('YYYY-MM-DD') : data.etaDateArr}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'placeofReciept')}>
                                {data.placeofReciept}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'portOfLoading')}>
                                {data.portOfLoading}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'lastForeignPort')}>
                                {data.lastForeignPort}
                            </CngField>
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>

            <Grid item xs={12}>
                <CngSection title={getTranslatedText('BOL', 'commercialParties')}>
                    {data.commercialParties.length > 0 ? (
                        <Grid container spacing={1}>
                            {data.commercialParties.map(party => (
                                <Grid key={party.id || party._id} item xs={12}>
                                    <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                                        <Box padding={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4} >
                                                    <CngField label={getTranslatedText('BOL', 'partyType')}>
                                                        {getLookupValue('partyType', party.partyType)}
                                                    </CngField>
                                                </Grid>
                                                <Grid item xs={12} sm={4} >
                                                    <CngField label={getTranslatedText('BOL', 'partyName')}>
                                                        {party.partyName}
                                                    </CngField>
                                                </Grid>
                                                <Grid item xs={12} sm={4} >
                                                    <CngField label={getTranslatedText('BOL', 'address1')}>
                                                        {party.address1}
                                                    </CngField>
                                                </Grid>
                                                <Grid item xs={12} sm={4} >
                                                    <CngField label={getTranslatedText('BOL', 'address2')}>
                                                        {party.address2}
                                                    </CngField>
                                                </Grid>
                                                <Grid item xs={12} sm={4} >
                                                    <CngField label={getTranslatedText('BOL', 'address3')}>
                                                        {party.address3}
                                                    </CngField>
                                                </Grid>
                                                <Grid item xs={12} sm={4} >
                                                    <CngField label={getTranslatedText('BOL', 'telNo')}>
                                                        {party.telNo}
                                                    </CngField>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography variant='body2' color='textSecondary'>
                            No records to display
                        </Typography>
                    )}
                </CngSection>
            </Grid>

            <Grid item xs={12}>
                <CngSection title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('BOL', 'containerDetails') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${data.containerDetails.length} Entries`}
                        </Typography>
                    </Typography>
                }>
                    <ContainersTable
                        data={data.containerDetails}
                        onViewContainer={(container) => setContainerDialog({ open: true, container })}
                    />
                </CngSection>
            </Grid>

            <ContainersDialog
                onClose={() => setContainerDialog({ open: false, container: null })}
                open={containerDialog.open}
                container={containerDialog.container}
                disabled
            />

            <Grid item xs={12}>
                <CngSection title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('BOL', 'goodsDetails') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${data.goodsDetails.length} Entries`}
                        </Typography>
                    </Typography>
                }>
                    <GoodsDetailsTable
                        data={data.goodsDetails}
                        onViewGoods={(goods) => setGoodsDialog({ open: true, goods })}
                    />
                </CngSection>
            </Grid>

            <GoodsDetailsDialog
                onClose={() => setGoodsDialog({ open: false, goods: null })}
                open={goodsDialog.open}
                goods={goodsDialog.goods}
                disabled
            />
            <Grid item xs={12}>
                <CngSection title={"Goods Information"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'totalPackages')}>
                                {data.totalPackages}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'totalPackagesUom')}>
                                {data.totalPackagesUom}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'totalGrossWeight')}>
                                {data.totalGrossWeight}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'totalGrossWeightUom')}>
                                {data.totalGrossWeightUom}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'totalVolume')}>
                                {data.totalVolume}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CngField label={getTranslatedText('BOL', 'totalValueUom')}>
                                {data.totalValueUom}
                            </CngField>
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>

            <Grid item xs={12}>
                <History bolId={data.id} />
            </Grid>
        </Grid>
    )
}

export default ViewContent