import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
  '^[a-zA-Z0-9]+$'
  const errMsgAlphaNumeric =
  'Only Alpha Numeric Values are allowed'
  const regexNumeric =
  '^[0-9]+$'
  const errMsgregexNumeric=
  'Only Numeric value is allowed'
  
  return Yup.object({
    containerNo: Yup.string().nullable().max(14,"Max allowed lenth is 14").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    goodsDesc: Yup.string().nullable().max(458,"Max allowed lenth is 458").matches("^[^*~]+$","Cannot contain special characters ~*"),
    numberOfPackages: Yup.string().nullable().max(10,"Max allowed lenth is 10").matches(regexNumeric,errMsgregexNumeric),
    packageUom: Yup.string().nullable(),
    marksAndNum: Yup.string().nullable().max(1000,"Max allowed lenth is 1000").matches("^[^*~]+$","Cannot contain special characters ~*"),
    grossWeight: Yup.string().nullable().max(10,"Max allowed lenth is 10").matches(regexNumeric,errMsgregexNumeric),
    grossWeightUom: Yup.string().nullable(),
    volume: Yup.string().nullable().max(10,"Max allowed lenth is 10").matches(regexNumeric,errMsgregexNumeric),
    volumeUOM: Yup.string().nullable(),

    dangerousGoods: Yup.array(
      Yup.object({
        imdgNo: Yup.string().nullable(),
        flashPointTemp: Yup.string().nullable().max(3,"Max allowed length is 03").matches(regexNumeric,errMsgregexNumeric),
        dgCodeQual: Yup.string().nullable(),
        dgCode: Yup.string().nullable().max(10,"Max Length allowed is 10").matches("^[^~|\"*<>]+$","Cannot contain special characters ~|*<>\""),
        dgClass: Yup.string().nullable().max(4,"Max Length allowed is 04").matches("^[^~|\"*<>]+$","Cannot contain special characters ~|*<>\""),
        hazardousDesc: Yup.string().nullable().max(30,"Max Length allowed is 30").matches("^[^~|\"*<>]+$","Cannot contain special characters ~|*<>\""),
        hazardousPageNo: Yup.string().nullable().max(6,"Max Length allowed is 6").matches(regexAlphaNumeric,errMsgAlphaNumeric),
        materialDesc: Yup.string().nullable().max(30,"Max Length allowed is 30").matches("^[^~|\"*<>]+$","Cannot contain special characters ~|*<>\""),
        materialClass: Yup.string().nullable().max(30,"Max Length allowed is 30").matches("^[^~|\"*<>]+$","Cannot contain special characters ~|*<>\""),
        emergencyContact: Yup.string().nullable().max(24,"Max Length allowed is 24").matches(regexAlphaNumeric,errMsgAlphaNumeric),
        negative:Yup.string().nullable().max(1,"Max Length allowed is 01").matches('^[N]+$',"Please enter N if Flash Point Temperature is less than zero"),
      })
    )
  })
}

export default makeValidationSchema
