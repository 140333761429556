import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Allowed special characters are  ,+-$#@%&()!\'`^_:;.?[]{}/="|\\'

  return Yup.object({
    vesselCode: Yup.string().nullable().max(35,"Max allowed length is 35").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    vesselName: Yup.string().nullable().max(35,"Max allowed length is 35").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    vesselOwner: Yup.string().nullable().max(35,"Max allowed length is 35").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    vesselCountry: Yup.string().nullable(),
  })
}

export default makeValidationSchema
