import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
  '^[a-zA-Z0-9]+$'
  const errMsgAlphaNumeric =
  'Only Alpha Numeric Values are allowed'
  const regexAlphaNumericSpace =
  '^[a-zA-Z0-9 ]+$'
  const errMsgAlphaNumericSPace=
  'Only Alpha Numeric with Space is allowed'

  const regexAlphaNumericSpecChar = "^[a-zA-Z0-9!\"\',-./:;?=()_@./#&+\-,\\s]*$"
  const erregexAlphaNumericSpecChar = "Please enter alphanumeric values and allowed charter is ,-./:;?=()_@./#&+"
  const regexNumeric =
  '^[0-9]+$'
  const errMsgNumeric =
  'Only Numeric Values are allowed'

  return Yup.object({
    manifestId: Yup.string().nullable(),
    billIndicator: Yup.string().nullable(),
    billNumber: Yup.string().nullable().max(12, "Max allowed length is 12").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    issuerScac: Yup.string().nullable().max(4,"Max allowed length is 4").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    oceanBillNumber: Yup.string().nullable().max(16,"Max allowed length is 16").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    notifyScac: Yup.string().nullable().max(4,"Max allowed length is 4").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    carrierScac: Yup.string().nullable().max(4,"Max allowed length is 4").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    vesselName: Yup.string().nullable().max(23,"Max allowed length is 23.").matches(regexAlphaNumericSpace,errMsgAlphaNumericSPace),
    voyageNumber: Yup.string().nullable().max(5,"Max allowed length is 5.").matches(regexAlphaNumeric,errMsgAlphaNumeric),
    vesselCountry: Yup.string().nullable(),
    portOfDischarge: Yup.string().nullable(),
    etaDateArr: Yup.lazy((value) =>
      value === '' || value === null
        ? Yup.string().nullable()
        : Yup.date().validFormat().typeError(dateTypeErrorMessage)),
    placeofReciept: Yup.string().nullable().max(17,"Max allowed length is 17").matches(regexAlphaNumericSpecChar,erregexAlphaNumericSpecChar),
    portOfLoading: Yup.string().nullable(),
    lastForeignPort: Yup.string().nullable(),
    totalPackages: Yup.string().nullable().max(10,"Max allowed length is 10").matches(regexNumeric,errMsgNumeric),
    totalPackagesUom: Yup.string().nullable(),
    totalGrossWeight: Yup.string().nullable().max(10,"Max allowed length is 10").matches(regexNumeric,errMsgNumeric),
    totalGrossWeightUom: Yup.string().nullable(),
    totalVolume: Yup.string().nullable().max(10,"Max allowed length is 10").matches(regexNumeric,errMsgNumeric),
    totalValueUom: Yup.string().nullable(),
    messageFunCode: Yup.string().nullable(),

    commercialParties: Yup.array(
      Yup.object({
        partyIdentifier: Yup.string().nullable(),
        partyType: Yup.string().nullable(),
        partyName: Yup.string().nullable().max(35,"Max Allowed Length is 35").matches(regexAlphaNumericSpecChar,erregexAlphaNumericSpecChar),
        address1: Yup.string().nullable().max(35,"Max Allowed Length is 35").matches(regexAlphaNumericSpecChar,erregexAlphaNumericSpecChar),
        address2: Yup.string().nullable().max(35,"Max Allowed Length is 35").matches(regexAlphaNumericSpecChar,erregexAlphaNumericSpecChar),
        address3: Yup.string().nullable().max(35,"Max Allowed Length is 35").matches(regexAlphaNumericSpecChar,erregexAlphaNumericSpecChar),
        telNo: Yup.string().nullable().max(35,"Max Allowed Length is 35").matches(/^[0-9- ]*$/, "Can contain only numeric values with - and space.").nullable(),
      })
    ),

    containerDetails: Yup.array(),

    goodsDetails: Yup.array(),
  })
}

export default makeValidationSchema
