import React, { useRef, useState, useEffect } from 'react'
import { components } from 'cng-web-lib'
import { Grid, Box, Card } from '@material-ui/core'
import pathMap from 'src/paths/pathMap'
import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import withFormState from 'src/components/aciacehighway/hocs/withFormState'
import FormProperties from './FormProperties'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import { useLocation } from "react-router-dom";

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage(props) {
  const { history, showNotification, onSetLoading } = props
  const loc = useLocation()

  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.ACE_OCN_BILLOFLADING}/edit/` + e.id)
  )

  let formikPropsData;
  let manifestId;
  if(loc.state !== undefined) {
    formikPropsData = {
          ...FormProperties.formikProps,
          initialValues: loc.state
    }
    manifestId=loc.state.manifestId;
  } else {
    formikPropsData = FormProperties.formikProps
  }

  return (
    <Card>
      <Box padding={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngAddForm
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              history={history}
              showNotification={showNotification}
              renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                <FormProperties.Fields
                  disabled={false}
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              )}
              formikProps={formikPropsData}
              toClientDataFormat={FormProperties.toClientDataFormat}
              toServerDataFormat={FormProperties.toServerDataFormat}
              create={{
                url: AceOcnBillofladingApiUrls.POST,
                onSuccess: (e) => {
                  showNotification('success', 'Bill of Lading created.')
                  onSuccessCallback.current(e)
                }
              }}
              renderButtonSection={(loading) => (
                <ButtonSection
                  history={history}
                  loading={loading}
                  onSetLoading={onSetLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)


function ButtonSection(props) {
  const { history, loading, onSetLoading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton color='primary' disabled={loading} type='submit' size='medium' shouldShowProgress={loading}>
            Save
          </CngButton>
        </Grid>
      </Grid>

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACE_OCN_BILLOFLADING_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
