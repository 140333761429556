import { Box, Typography, Card, CardContent, Grid,Chip, Tooltip } from '@material-ui/core'
import {
  components,
  constants,
  useServices
} from 'cng-web-lib'

import AceOcnVesselDepartureApiUrls from 'src/apiUrls/AceOcnVesselDepartureApiUrls'
import React, { useEffect, useRef, useState, useContext } from 'react'
import Table from '../../../components/aciacehighway/Table'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId, FileForUserGetUserDetails, GetFilterByUserIdCriteria } from 'src/common/FileForUserCommon'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import moment from 'moment-timezone'
import { getStatusMetadata } from '../../../common/NACommon'
import TemplateSelectDialogWithOptionACEOceanVesselDeparture from './manifest/TemplateSelectDialogWithOptionACEOceanVesselDeparture'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE, NOT_EQUAL }
} = constants

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    history,
    location,
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    vesselDeparture: null
  })

  const tableRef = useRef(null)

  const [manifestTemplateDialog, setManifestTemplateDialog] = useState(false)

  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)

  function handleDeleteVesselDeparture() {
    if (confirmDialog.vesselDeparture) {
      deleteRecord.execute(
        AceOcnVesselDepartureApiUrls.DELETE,
        confirmDialog.vesselDeparture,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, vesselDeparture: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  const columns = [
    {
      field: "carrierScac",
      sortKey: "carrierScac",
      title: getTranslatedText("VESSEL_DEPARTURE","carrierScac"),
    },
    {
      field: "vesselName",
      sortKey: "vesselName",
      title: getTranslatedText("VESSEL_DEPARTURE","vesselName"),
    },
    {
      field: "voyageNumber",
      sortKey: "voyageNumber",
      title: getTranslatedText("VESSEL_DEPARTURE","voyageNumber"),
    },
    {
      field: "portOfDischarge",
      sortKey: "portOfDischarge",
      title: getTranslatedText("VESSEL_DEPARTURE","portOfDischarge"),
    },
    {
      field: "departureDate",
      sortKey: "departureDate",
      title: getTranslatedText("VESSEL_DEPARTURE","departureDate"),
      render: (data) =>
        data.departureDate && moment(data.departureDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "lastForeignPort",
      sortKey: "lastForeignPort",
      title: getTranslatedText("VESSEL_DEPARTURE","lastForeignPort"),
    },
    {
      field: "departureStatus",
      sortKey: "departureStatus",
      title: getTranslatedText("VESSEL_DEPARTURE","departureStatus"),
      render: (data) => {

        const status = getStatusMetadata(data.departureStatus)

        return (
          <>
            <Tooltip key='more' title='View response(s)' placement='top'>
              <Chip
                label={status.label}
                size='small'
                style={{
                  backgroundColor: status.color,
                  color: status.contrastColor
                }}
                clickable
              />
            </Tooltip>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDate && moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </>
        )
      }
    },
    {
      field: "submittedDate",
      sortKey: "submittedDate",
      title: getTranslatedText("VESSEL_DEPARTURE","submittedDate"),
      render: (data) =>
        data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "responseDate",
      sortKey: "responseDate",
      title: getTranslatedText("VESSEL_DEPARTURE","responseDate"),
      render: (data) =>
        data.responseDate && moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  const filters = [
    {
      name: "carrierScac",
      label: getTranslatedText("VESSEL_DEPARTURE","carrierScac"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "vesselName",
      label: getTranslatedText("VESSEL_DEPARTURE","vesselName"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "voyageNumber",
      label: getTranslatedText("VESSEL_DEPARTURE","voyageNumber"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "portOfDischarge",
      label: getTranslatedText("VESSEL_DEPARTURE","portOfDischarge"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "departureDate",
      label: getTranslatedText("VESSEL_DEPARTURE","departureDate"),
      type: 'daterange',
      operatorType: LIKE
    },
    {
      name: "lastForeignPort",
      label: getTranslatedText("VESSEL_DEPARTURE","lastForeignPort"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "departureStatus",
      label: getTranslatedText("VESSEL_DEPARTURE","departureStatus,"),
      type: 'textfield',
      operatorType: LIKE,
    },
    {
      name: "submittedDate",
      label: getTranslatedText("VESSEL_DEPARTURE","submittedDate"),
      type: 'daterange',
      operatorType: LIKE
    },
    {
      name: "responseDate",
      label: getTranslatedText("VESSEL_DEPARTURE","responseDate"),
      type: 'daterange',
      operatorType: LIKE
    }
  ]

  useEffect(() => {
    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, [])

  let filter = GetFilterByUserIdCriteria("ACEOCN")

  return (
    <>
      {sbPartyIdFetched && (<Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setManifestTemplateDialog(true)
            },
            label: getTranslatedText("VESSEL_DEPARTURE","createVesselDeparture"),
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: AceOcnVesselDepartureApiUrls.EXPORT }}
        fetch={{ url: AceOcnVesselDepartureApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.departureStatus === "1008" || rowData.departureStatus === "1000") {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }
        }
        rowActions={[
          {
            disabled: (rowData) => (rowData.departureStatus === "1008" || rowData.departureStatus === "1000"),
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: getTranslatedText("VESSEL_DEPARTURE","editButton"),
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              (rowData.departureStatus === "1008" || rowData.departureStatus === "1000")
                ? getTranslatedText("VESSEL_DEPARTURE","editSentRecord")
                : null
          },
          {
            disabled: (data) => data.departureStatus !== "1005",
            label: getTranslatedText("VESSEL_DEPARTURE","deleteButton"),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, vesselDeparture: data }),
            tooltip: (rowData) =>
              rowData.departureStatus !== "1005"
                ? getTranslatedText("VESSEL_DEPARTURE","deleteNotDraftRecord")
                : null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        selectActions={[
        ]}
      />)}
      <TemplateSelectDialogWithOptionACEOceanVesselDeparture
        manifestTemplateDialog={manifestTemplateDialog}
        handleClose={() => setManifestTemplateDialog(false)}
        title={getTranslatedText("VESSEL_DEPARTURE","createVesselDeparture")}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, vesselDeparture: null })}
        confirmDialog={handleDeleteVesselDeparture}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText("VESSEL_DEPARTURE","deleteButton")}
      />
    </>
  )
}

export default TablePage
