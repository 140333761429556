import React, { useState, useContext } from 'react'
import StatusBar from 'src/components/aciacehighway/StatusBar'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import moment from 'moment-timezone'
import { Box, Card, Grid, Typography, useTheme } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import TinyChip from '../../../components/aciacehighway/TinyChip'

function VesselDepartureViewContent(props) {
    const { id, data, getLookupValue } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const theme = useTheme()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StatusBar status={data.departureStatus} />
            </Grid>

            <Grid item xs={12}>
                <CngSection title={getTranslatedText("VESSEL_DEPARTURE", "aceOcnVesselDeparture")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "departureStatus")}>
                                {getLookupValue("status", data.departureStatus)}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "carrierScac")}>
                                {data.carrierScac}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "vesselName")}>
                                {data.vesselName}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "voyageNumber")}>
                                {data.voyageNumber}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "vesselCountry")}>
                                <Typography component='div' variant='inherit'>
                                    {getLookupValue('country', data.vesselCountry)}
                                <TinyChip label={data.vesselCountry} variant='outlined' />
                                </Typography>
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "portOfDischarge")}>
                                <Typography component='div' variant='inherit'>
                                    {getLookupValue('portOfDischarge', data.portOfDischarge)}
                                <TinyChip label={data.portOfDischarge} variant='outlined' />
                                </Typography>
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "departureDate")}>
                                {data.departureDate}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "departureTime")}>
                                {data.departureTime}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "lastForeignPort")}>
                                <Typography component='div' variant='inherit'>
                                    {getLookupValue('lastForeignPort', data.lastForeignPort)}
                                <TinyChip label={data.lastForeignPort} variant='outlined' />
                                </Typography>
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_DEPARTURE", "noOfBl")}>
                                {data.noOfBl}
                            </CngField>
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>
        </Grid>
    )
}

export default VesselDepartureViewContent