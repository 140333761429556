import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import VesselMasterKeys from 'src/constants/locale/key/AceOcnVesselMaster'
import { Grid, Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { useWatch, useFormContext } from "react-hook-form"
import CngSection from '../../../components/cngcomponents/CngSection'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  vesselCode: "",
  vesselName: "",
  vesselOwner: "",
  vesselCountry: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACE_OCN_VESSEL_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue } = useFormContext();

  useWatch({ name: "fileForUserId" })
  useWatch({ name: "fileForUserLoginId" })
  useWatch({ name: "fileForUserPartyId" })
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let vesselMaster = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.TITLE
    )
    let vesselCode = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_CODE
    )
    let vesselName = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_NAME
    )
    let vesselOwner = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_OWNER
    )
    let vesselCountry = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_COUNTRY
    )

    return {
      vesselMaster,
      vesselCode,
      vesselName,
      vesselOwner,
      vesselCountry
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.vesselMaster}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>Mandatory fields</Typography>
            }>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselCode}>
                    <CngTextField
                      name="vesselCode"
                      label={translatedTextsObject.vesselCode}
                      size='small'
                      disabled={disabled}
                      onChange={(e) => {
                        setValue('vesselCode', e.target.value.toUpperCase(), {
                          shouldValidate: true
                        })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('vesselCode', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselName}>
                    <CngTextField
                      name="vesselName"
                      label={translatedTextsObject.vesselName}
                      size='small'
                      required
                      disabled={disabled}
                      onChange={(e) => {
                        setValue('vesselName', e.target.value.toUpperCase(), {
                          shouldValidate: true
                        })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('vesselName', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselOwner}>
                    <CngTextField
                      name="vesselOwner"
                      label={translatedTextsObject.vesselOwner}
                      size='small'
                      disabled={disabled}
                      onChange={(e) => {
                        setValue('vesselOwner', e.target.value.toUpperCase(), {
                          shouldValidate: true
                        })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('vesselOwner', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselCountry}>
                    <CngCountryAutocompleteField
                      name="vesselCountry"
                      label={translatedTextsObject.vesselCountry}
                      size='small'
                      lookupProps={{
                        label: (record) => {
                          return `${record.code};${record.descriptionEn}`
                        }
                      }}
                      disabled={disabled}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
