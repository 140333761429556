import React, { useEffect, useMemo , useState} from 'react'
import { useFormContext, useWatch } from "react-hook-form"
import { Grid,Box } from '@material-ui/core'
import { DataFlattener, DateTimeFormatter } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

import BillOfLadingSection from './Sections/BillOfLadingSection'
import VoyageReferenceSection from './Sections/VoyageReferenceSection'
import CommercialPartiesSection from './Sections/CommercialPartiesSection'
import ContainersSection from './Sections/ContainersSection'
import GoodsDetailsSection from './Sections/GoodsDetailsSection'
import BillOfLadingGoodsDetailsSection from './Sections/BillOfLadingGoodsDetailsSection'


const DEFAULT_INITIAL_VALUES = Object.freeze({
  billIndicator: "",
  billNumber: "",
  issuerScac: "",
  oceanBillNumber: "",
  notifyScac: "",
  carrierScac: "",
  vesselName: "",
  voyageNumber: "",
  vesselCountry: "",
  portOfDischarge: "",
  etaDateArr: "",
  placeofReciept: "",
  portOfLoading: "",
  lastForeignPort: "",
  templateName: "",
  templateFlag: false,
  modeOfSubmission: "UI",
  status: "1005",
  totalPackages: "0",
  totalPackagesUom: "",
  totalGrossWeight: "0",
  totalGrossWeightUom: "KG",
  totalVolume: "0",
  totalValueUom: "CF",
  messageFunCode: "",
  commercialParties: [],
  containerDetails: [],
  goodsDetails: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { setValue ,getValues} = useFormContext();

  const containerDetails = useWatch({ name: "containerDetails" });
  const goodsDetails = useWatch({ name: "goodsDetails" });
  const [containerNoList, setContainerNoList] = useState([]);

  let templateName = getValues("templateName");
  useWatch({ name: "fileForUserId" })
  useWatch({ name: "fileForUserLoginId" })
  useWatch({ name: "fileForUserPartyId" })
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();

    if(templateName!==null &&  templateName!==""){
      setValue("templateFlag",true)
    }
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    var MenuItemCode = 'NON_CONTAINERIZED';
    var jsonArray = [];
    var jsonObject = { text: MenuItemCode, value: "NC" };
    jsonArray.push(jsonObject);
    if (containerDetails) {
        (containerDetails).forEach((item) => {
                MenuItemCode = item.containerNumber;
                jsonObject = { text: MenuItemCode, value: MenuItemCode };
                jsonArray.push(jsonObject); 
        })
        setContainerNoList(jsonArray);
    }
}, [containerDetails]);

  const memoedBillOfLadingSection = useMemo(() => (
    <BillOfLadingSection disabled={disabled} shouldHideMap={shouldHideMap} showNotification={showNotification} />
  ), [])

  const memoedVoyageReferenceSection = useMemo(() => (
    <VoyageReferenceSection disabled={disabled} shouldHideMap={shouldHideMap} />
  ), [])

  const memoedCommercialPartiesSection = useMemo(() => (
    <CommercialPartiesSection />
  ), [])

  const memoedContainersSection = useMemo(() => (
    <ContainersSection showNotification={showNotification} />
  ), [])

  const memoedGoodsDetailsSection = useMemo(() => (
    <GoodsDetailsSection showNotification={showNotification} containerNoList={containerNoList} />
  ), [containerNoList])

  const memoedBillOfLadingGoodsDetailsSection = useMemo(() => (
    <BillOfLadingGoodsDetailsSection shouldHideMap={shouldHideMap} goodsDetails={goodsDetails} />
  ), [goodsDetails])


  return (
    <Grid container spacing={3}>
      {memoedBillOfLadingSection}
      {memoedVoyageReferenceSection}
      {memoedCommercialPartiesSection}
      {memoedContainersSection}
      {memoedGoodsDetailsSection}
      {memoedBillOfLadingGoodsDetailsSection}
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData
  localData.eTA = DateTimeFormatter.toClientDate(localData.eTA)
  localData.submittedDate = DateTimeFormatter.toClientDate(localData.submittedDate)

  return localData;
}

function toServerDataFormat(localData) {
  localData.eTA = DateTimeFormatter.toServerDate(localData.eTA)
  localData.submittedDate = DateTimeFormatter.toServerDate(localData.submittedDate)

  return localData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
