import React, { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import DGgoodsDetailsFormProperties from './DGgoodsDetailsFormProperties'
import CngSection from 'src/components/cngcomponents/CngSection'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import makeValidationSchema from './GoodsDetailsMakeValidationSchema'
import _ from 'lodash'

const {
  form: {
    field: { CngTextField ,
      CngSelectField,
      CngCodeMasterAutocompleteField}
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNo: '',
  goodsDesc: '',
  numberOfPackages: '0',
  packageUom: '',
  marksAndNum: '',
  grossWeight: '0',
  grossWeightUom: 'KG',
  volume: '0',
  volumeUOM: 'CF',
  dangerousGoods: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap, containerNoList }) {
  const { setValue, watch } = useFormContext()
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)

  const dangerousGoods = watch('dangerousGoods')

	return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection
          title={getTranslatedText('BOL', 'goodsDetails')}
          subheader={
            <Typography color='textSecondary' component='span' variant='caption'>
              <Typography color='error' component='span' variant='inherit'>*</Typography>
              Mandatory fields
            </Typography>
          }>
          <Grid container spacing={2}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNo}>
            <CngSelectField
              name='containerNo'
              label={getTranslatedText('BOL', 'containerNo')}
              required
              items={containerNoList}
              size='small'
            />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.goodsDesc}>
              <CngTextField
                name='goodsDesc'
                label={getTranslatedText('BOL', 'goodsDesc')}
                disabled={disabled}
                required
                size='small'
                onChange={(e) => {
                  setValue('goodsDesc', e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue('goodsDesc', e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.numberOfPackages}>
              <CngTextField
                name='numberOfPackages'
                label={getTranslatedText('BOL', 'numberOfPackages')}
                disabled={disabled}
                required
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.packageUom}>
              <CngCodeMasterAutocompleteField
                name='packageUom'
                label={getTranslatedText('BOL', 'packageUom')}
                disabled={disabled}
                codeType='BOL_PACKAGES_UOM'
                lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                }}
                required
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.marksAndNum}>
              <CngTextField
                name='marksAndNum'
                label={getTranslatedText('BOL', 'marksAndNum')}
                disabled={disabled}
                required
                size='small'
                onChange={(e) => {
                  setValue('marksAndNum', e.target.value.toUpperCase(), {
                    shouldValidate: true
                  })
                }}
                onBlur={(e) => {
                    if (e.target.value !== undefined) {
                        setValue('marksAndNum', e.target.value.trim(), {
                        shouldValidate: true
                        })
                    }
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWeight}>
              <CngTextField
                name='grossWeight'
                label={getTranslatedText('BOL', 'grossWeight')}
                disabled={disabled}
                required
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWeightUom}>
              <CngCodeMasterAutocompleteField
                name='grossWeightUom'
                label={getTranslatedText('BOL', 'grossWeightUom')}
                disabled={disabled}
                codeType='BOL_GOODS_GROSS_UOM'
                required
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volume}>
              <CngTextField
                name='volume'
                label={getTranslatedText('BOL', 'volume')}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volumeUOM}>
              <CngCodeMasterAutocompleteField
                name='volumeUOM'
                label={getTranslatedText('BOL', 'volumeUOM')}
                disabled={disabled}
                codeType='BOL_GOODS_VOLUME_UOM'
                size='small'
              />
            </CngGridItem>
          </Grid>
        </CngSection>
      </Grid>

      <Grid item xs={12}>
        <CollapsibleSection
          defaultExpanded={dangerousGoods.length > 0}
          onExpandedChange={(expanded) => {
            const { initialValues } = DGgoodsDetailsFormProperties.formikProps
            setValue('dangerousGoods', expanded ? [{ ...initialValues, _id: 1 }] : [])
          }}
          title={getTranslatedText('BOL', 'dGgoodsDetails')}>

          <Grid container spacing={1}>
            {dangerousGoods.map((goods, index) => {
              return (
                <React.Fragment key={goods._id || goods.id || index}>
                  <Grid item xs={12}>
                    <DGgoodsDetailsFormProperties.Fields index={index} />
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>

        </CollapsibleSection>
      </Grid>
    </Grid>
	)
}

const GoodsDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default GoodsDetailsFormProperties
