import { Grid, Card, Box, useTheme } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import VesselArrivalViewForm from './VesselArrivalViewPage'
import ResponseMessage from './response/ResponseMessage'
import Stepper from '../../../components/aciacehighway/Stepper'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'

const {
  form: { CngViewForm },
  CngTabs
} = components

function ViewPage(props) {
  const { location, showNotification, onSetLoading, history } = props
  const { state } = location
  const { id } = useParams()
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [completed, setCompleted] = useState({})
  const [stepIconColor, setStepIconColor] = useState({})
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  const theme = useTheme()

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <VesselArrivalViewForm
            showNotification={showNotification}
          />)
      case 1:
        return (
          <ResponseMessage
            showNotification={showNotification}
            id={id}
            isViewOnly={true}
          />
        )
    }
  }

  return (
    <>
      <Card>
        <Stepper
          steps={[getTranslatedText("VESSEL_ARRIVAL", "viewVesselArrivalTab"), getTranslatedText("VESSEL_ARRIVAL", "viewVesselArrivalResponseTab")]}
          activeStep={step}
          completed={completed}
          onSetActiveStep={(step) => setStep(step)}
          stepIconColor={stepIconColor}
        />
        <Box
          padding={2}
          style={{ backgroundColor: theme.palette.background.sectionOddBg }}
        >
          {renderContent(step)}
        </Box>
      </Card>
    </>
  )
}

export default ViewPage
