import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Typography, } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import GoodsDetailsDialog from '../GoodsDetailsDialog'
import GoodsDetailsTable from '../GoodsDetailsTable'

function GoodsDetailsSection(props) {
    const { showNotification, disabled ,containerNoList} = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const [dialog, setDialog] = useState({ open: false, goods: null })

    const goodsDetails = useWatch({ name: 'goodsDetails' })

    function handleRemoveGoods(data) {
        const goodsDetails = [...getValues('goodsDetails')].filter((goods) =>
            goods.id ? goods.id !== data.id : goods._id !== data._id
        )
        setValue('goodsDetails', goodsDetails, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title={
                    <Typography variant='h5' style={{ fontWeight: 700 }}>
                        {getTranslatedText('BOL', 'goodsDetails') + ' '}
                        <Typography color='textSecondary' component='span' variant='caption'>
                            {`/ ${goodsDetails.length} Entries`}
                        </Typography>
                    </Typography>
                }
                alignItems='flex-end'
            >

                <GoodsDetailsTable
                    data={goodsDetails}
                    onAddGoods={() => setDialog({ open: true, goods: null })}
                    onEditGoods={(goods) => setDialog({ open: true, goods })}
                    onDeleteGoods={handleRemoveGoods}
                />

                <GoodsDetailsDialog
                    onClose={() => setDialog({ open: false, goods: null })}
                    open={dialog.open}
                    goods={dialog.goods}
                    showNotification={showNotification}
                    disabled={false}
                    containerNoList={containerNoList}
                />
            </CngSection>
        </Grid>
    )
}

export default GoodsDetailsSection