import React, { useRef, useEffect, useContext } from 'react'
import { Grid, Card, Box, Tooltip } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { useFormContext, useWatch } from 'react-hook-form'
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import CommercialPartiesFormProperties from '../CommercialPartiesFormProperties'

const { button: { CngIconButton } } = components

const { initialValues } = CommercialPartiesFormProperties.formikProps

function CommercialPartiesSection() {
    const { getValues, setValue } = useFormContext()
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const commercialParties = useWatch({ name: 'commercialParties' })
    const lastIndex = useRef(commercialParties.length - 1)

    // Push party info default value with crew type 'Shipper' or 'Consignee' if there isn't any
    useEffect(() => {
        const { initialValues } = CommercialPartiesFormProperties.formikProps

        if (commercialParties.length === 0) {
            setValue('commercialParties', [
                { ...initialValues, partyType: 'CZ', _id: 1 },
                { ...initialValues, partyType: 'CN', _id: 2 }
            ])
        } else {
            const hasShipper = commercialParties.findIndex(party => party.partyType === 'CZ') !== -1
            const hasConsignee = commercialParties.findIndex(party => party.partyType === 'CN') !== -1

            if (!hasShipper || !hasConsignee) {
                const result = [...getValues('commercialParties')]

                if (!hasShipper) {
                    result.push({ ...initialValues, partyType: 'CZ' })
                } else if (!hasConsignee) {
                    result.push({ ...initialValues, partyType: 'CN' })
                }

                setValue('commercialParties', result.map((party, index) => ({ ...party, _id: index + 1 })))
            }
        }
    }, [commercialParties])


    function handleAddPartyInfo(data) {
        const commercialParties = [...getValues('commercialParties'), { ...data, _id: ++lastIndex.current }]
        setValue('commercialParties', commercialParties)
    }

    function handleDeletePartyInfo(data) {
        const commercialParties = [...getValues('commercialParties')].filter((party) =>
            party.id ? party.id !== data.id : party._id !== data._id
        )
        setValue('commercialParties', commercialParties)
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('BOL', 'commercialParties')}>
                <Grid container spacing={2}>
                    {commercialParties.map((party, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>
                                        <Grid item xs={12}>
                                            <CommercialPartiesFormProperties.Fields index={index} />
                                        </Grid>

                                        <Grid item xs='auto'>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Add'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'plus']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo(initialValues)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Clone'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'copy']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo({ ...party, id: undefined })}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Delete'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'trash']} size='small' type='outlined'
                                                                onClick={() => handleDeletePartyInfo(party)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CngSection>
        </Grid>
    )

}

export default CommercialPartiesSection