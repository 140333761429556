import makeValidationSchema from './AciAirManifestQtyListMakeValidationSchema'
import { components, constants, useTranslation } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import { Grid } from '@material-ui/core'
import { useFormContext } from "react-hook-form";

const {
  form: {
    field: { CngTextField, CngUomAutocompleteField }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  quantity: "",
  uom: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `aciAirManifestQtyList.${index}.${field}` : field
  }, [])

  function makeTranslatedTextsObject() {
    const quantity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirManifestQtyList.QUANTITY
    )
    const uom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirManifestQtyList.UOM
    )

    return { quantity, uom }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name={getFieldName('quantity')}
          inputProps={{ maxLength: 7 }}
          label={translatedTextsObject.quantity}
          isRequired
          disabled={disabled}
          size='small'
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue('quantity', e.target.value.trim(), {
                shouldValidate: true
              })
            }
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.uom}>
        <CngUomAutocompleteField
          name={getFieldName('uom')}
          label={translatedTextsObject.uom}
          isRequired
          disabled={disabled}
          size='small'
          lookupProps={{
            label: (record) => [record.code, record.descriptionEn].join(';'),
            filters: [{ field: 'uomType', operator: EQUAL, value: 'ACI' }]
          }}
        />
      </CngGridItem>
    </Grid>
	)
}

const AciAirManifestQtyListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AciAirManifestQtyListFormProperties
