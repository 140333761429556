import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    statusType: Yup.string()
      .required(requiredMessage)
      .min(1, 'Min length needed is 1.')
      .max(4, 'Max length allowed is 4.')
      .matches(/^\d+$/, 'Only numbers are allowed!')
      .nullable(),
    email: Yup.string()
      .trim()
      .email('Please enter a valid email address!')
      .required(requiredMessage)
      .max(150, 'Max length allowed is 150.'),
    phoneNumber: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .max(15, 'Max length allowed is 15.')
            .matches(/^\d+$/, 'Only numbers are allowed!')
            .nullable()
    )
  })
}

export default makeValidationSchema
