import { Grid, Box, Card, useTheme } from '@material-ui/core'
import React, { useRef, useState, useContext } from 'react'
import { components } from 'cng-web-lib'
import Stepper from '../../../components/aciacehighway/Stepper'
import VesselArrivalEditForm from './VesselArrivalEditPage'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useParams } from 'react-router-dom'
import ResponseMessage from './response/ResponseMessage'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'

const {
  form: { CngAddForm }
} = components

function EditPage({ history, loading, location, showNotification, onSetLoading }) {

  const { id } = useParams()

  const { state } = location

  const vesselArrivalData = useRef(state?.vesselArrivalData || null)
  const [vesselArrivalStatus, setVesselArrivalStatus] = useState(null)
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [completed, setCompleted] = useState({})
  const [stepIconColor, setStepIconColor] = useState({})
  const theme = useTheme()
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <VesselArrivalEditForm
            id={id}
            vesselArrivalData={vesselArrivalData}
            onChangeStep={(step) => setStep(step)}
            onSetVesselArrivalStatus={setVesselArrivalStatus}
            history={history}
            showNotification={showNotification}
            onSetLoading={onSetLoading}
            step={step}
          />)
      case 1:
        return (
          <ResponseMessage
            showNotification={showNotification}
            id={id}
            isViewOnly={true}
          />
        )
    }
  }

  return (
    <>
      <Card>
        <Stepper
          steps={!vesselArrivalStatus || vesselArrivalStatus === 'DR' ? [getTranslatedText("VESSEL_ARRIVAL", "createVesselArrivalTab")] : [getTranslatedText("VESSEL_ARRIVAL", "createVesselArrivalTab"), getTranslatedText("VESSEL_ARRIVAL", "viewVesselArrivalResponseTab")]}
          activeStep={step}
          completed={completed}
          onSetActiveStep={(step) => setStep(step)}
          stepIconColor={stepIconColor}
        />
        <Box
          padding={2}
          style={{ backgroundColor: theme.palette.background.sectionOddBg }}
        >
          {renderContent(step)}
        </Box>
      </Card>
    </>
  )

}

export default withFormState(EditPage)
