import React from "react";
import {
  components,
  useTranslation
} from 'cng-web-lib'
import MaterialTable from "material-table"
import AciAirKeys from 'src/constants/locale/key/AciAirResponse';
import Namespace from 'src/constants/locale/Namespace';

const {
    card: {  },
    table: {
    }
  } = components

  function ErrorResponse({
    errorCode,
    rejectType,
    errorDesc,
    responseText,
    showHeader
  }){
    
    const { translate } = useTranslation([Namespace.ACI_AIR])
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
      let errorCode = translate(
        Namespace.ACI_AIR,
        AciAirKeys.ERRORCODE
      )
    
      
      let rejectType = translate(
        Namespace.ACI_AIR,
        AciAirKeys.REJECT_TYPE
      )
      
      let errorDesc = translate(
        Namespace.ACI_AIR,
        AciAirKeys.ERROR_DESC
      )
  
      let responseText = translate(
        Namespace.ACI_AIR,
        AciAirKeys.RESPONSE_TEXT
      )
  
      return {
        errorCode,
        rejectType,
        errorDesc,
        responseText
      }
    }

    return (
      <span>
        <MaterialTable
        options={{
          paging: false,
          showTitle: false,
          search: false,
          sorting: false,
          header:showHeader,
          toolbar:false
        }}
        columns={[
          { title: translatedTextsObject.errorCode, field: "code" },
          { title: translatedTextsObject.rejectType, field: "rejectType" },
          { title: translatedTextsObject.errorDesc, field: "description",
            cellStyle: () => {
              return {width:'40%'}
            } 
          },
          { title: translatedTextsObject.responseText, field: "text",
            cellStyle: () => {
              return {width:'35%'}
            }
          },
        ]}
        data={[
          {
            code: errorCode,
            rejectType: rejectType,
            description: errorDesc,
            text: responseText
          },
        ]}
      />
      </span>
    )
  }
  export default ErrorResponse;