import React, { useEffect, useContext } from 'react'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { NaUSPortAutocompleteField, NaUSForeignPortAutocompleteField } from 'src/components/na'
import CngSection from '../../../components/cngcomponents/CngSection'
import { useFormContext, useWatch } from 'react-hook-form'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import moment from 'moment-timezone'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField,
      CngTimeField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: null,
  manifestHeaderId: null,
  carrierScac: "",
  vesselName: "",
  voyageNumber: "",
  vesselCountry: "",
  portOfDischarge: "",
  departureDate: null,
  departureTime: "23:59",
  lastForeignPort: "",
  noOfBl: 0,
  departureStatus: "",
  responseDate: null,
  vesselCode: "",
  entityNo: "",
  modeOfTransport: "",
  eta: null,
  partyId: null,
  submittedBy: null,
  submittedDate: null,
  createdBy: null,
  createdDate: null,
  updatedBy: null,
  updatedDate: null,
  version: 0,
  modeOfSubmission: "UI",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const { getTranslatedText } = useContext(ACEOceanTranslationContext)

  const { setValue, getValues, watch, trigger } = useFormContext();

  watch("departureTime")
  watch("fileForUserId");
  watch("fileForUserLoginId")
  watch("fileForUserPartyId");
  
  useEffect(() =>{
    if(getValues("departureStatus") == null || getValues("departureStatus") == undefined || getValues("departureStatus") == ""){
      setValue("departureStatus","1005")
    }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
        setValue("fileForUserId", fileForUserDetails.fileForUserId)
        setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
        setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  },[])
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText("VESSEL_DEPARTURE","aceOcnVesselDeparture")}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>Mandatory fields</Typography>
            }>
            <Grid container spacing={2}>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.departureStatus}>
                 <CngCodeMasterAutocompleteField
                  name="departureStatus"
                  label={getTranslatedText("VESSEL_DEPARTURE","departureStatus")}
                  disabled={true}
                  codeType='ACE_OCN_STATUS'
                  size="small"
                  key={ getValues("departureStatus")}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.carrierScac}>
                <CngTextField
                  name="carrierScac"
                  label={getTranslatedText("VESSEL_DEPARTURE","carrierScac")}
                  disabled={true}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselName}>
                <CngTextField
                  name="vesselName"
                  label={getTranslatedText("VESSEL_DEPARTURE","vesselName")}
                  disabled={true}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.voyageNumber}>
                <CngTextField
                  name="voyageNumber"
                  label={getTranslatedText("VESSEL_DEPARTURE","voyageNumber")}
                  disabled={true}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselCountry}>
                <CngCountryAutocompleteField
                  name="vesselCountry"
                  label={getTranslatedText("VESSEL_DEPARTURE","vesselCountry")}
                  disabled={true}
                  lookupProps={{
                    label: (record) => {
                      return `${record.code};${record.descriptionEn}`
                    }
                  }}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.portOfDischarge}>
                <NaUSPortAutocompleteField
                  name="portOfDischarge"
                  label={getTranslatedText("VESSEL_DEPARTURE","portOfDischarge")}
                  size='small'
                  disabled={true}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.departureDate}>
                <CngDateField
                  name="departureDate"
                  label={getTranslatedText("VESSEL_DEPARTURE","departureDate")}
                  disabled={disabled}
                  size='small'
                  required
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.departureTime}>
                <CngTimeField
                      required
                      name="departureTime"
                      label={getTranslatedText("VESSEL_DEPARTURE","departureTime")}
                      format="HH:mm"
                      disabled={disabled}
                      size='small'
                      ampm={false}
                    />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.lastForeignPort}>
                <NaUSForeignPortAutocompleteField
                  name="lastForeignPort"
                  label={getTranslatedText("VESSEL_DEPARTURE","lastForeignPort")}
                  disabled={disabled}
                  size='small'
                  required
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.noOfBl}>
                <CngTextField
                  name="noOfBl"
                  label={getTranslatedText("VESSEL_DEPARTURE","noOfBl")}
                  disabled={disabled}
                  size='small'
                  required
                />
              </CngGridItem>

              {/*
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="manifestHeaderId"
              label={getTranslatedText("VESSEL_DEPARTURE","manifestHeaderId}
              disabled={disabled}
            />
          </CngGridItem>
           <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.responseDate}>
            <CngDateField
              name="responseDate"
              label={getTranslatedText("VESSEL_DEPARTURE","responseDate")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselCode}>
            <CngTextField
              name="vesselCode"
              label={getTranslatedText("VESSEL_DEPARTURE","vesselCode")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.entityNo}>
            <CngTextField
              name="entityNo"
              label={getTranslatedText("VESSEL_DEPARTURE","entityNo")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.modeOfTransport}>
            <CngTextField
              name="modeOfTransport"
              label={getTranslatedText("VESSEL_DEPARTURE","modeOfTransport")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eta}>
            <CngDateField
              name="eta"
              label={getTranslatedText("VESSEL_DEPARTURE","eta")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
            <CngTextField
              name="partyId"
              label={getTranslatedText("VESSEL_DEPARTURE","partyId")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.submittedBy}>
            <CngTextField
              name="submittedBy"
              label={getTranslatedText("VESSEL_DEPARTURE","submittedBy")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.submittedDate}>
            <CngDateField
              name="submittedDate"
              label={getTranslatedText("VESSEL_DEPARTURE","submittedDate")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdBy}>
            <CngTextField
              name="createdBy"
              label={getTranslatedText("VESSEL_DEPARTURE","createdBy")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdDate}>
            <CngDateField
              name="createdDate"
              label={getTranslatedText("VESSEL_DEPARTURE","createdDate")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedBy}>
            <CngTextField
              name="updatedBy"
              label={getTranslatedText("VESSEL_DEPARTURE","updatedBy")}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedDate}>
            <CngDateField
              name="updatedDate"
              label={getTranslatedText("VESSEL_DEPARTURE","updatedDate")}
              disabled={disabled}
            />
           </CngGridItem>*/}
            </Grid>
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;

  localData.departureTime = moment(localData.departureDate).format('HH:mm');
  localData.departureDate = moment(localData.departureDate).format('YYYY-MM-DD');
  
  localData.responseDate = DateTimeFormatter.toClientDate(
    localData.responseDate
  );
  localData.eta = DateTimeFormatter.toClientDate(
    localData.eta
  );
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {

  localData.departureDate = localData.departureDate + ' ' + localData.departureTime

  localData.responseDate = DateTimeFormatter.toServerDate(
    localData.responseDate
  );
  localData.eta = DateTimeFormatter.toServerDate(
    localData.eta
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
