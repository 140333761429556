import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { components, useServices } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from "react-router-dom"
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import AceOcnVesselArrivalApiUrls from 'src/apiUrls/AceOcnVesselArrivalApiUrls'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {
  const loc = useLocation();

  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.ACE_OCN_VESSEL_ARRIVAL}/edit/` + e.id)
  )

  let formikPropsData

  if (loc.state !== undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: loc.state
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: AceOcnVesselArrivalApiUrls.POST,
            onSuccess: (e) => {
              showNotification('success', 'Add record has succeeded.')
              onSuccessCallback.current(e)
              onSetLoading(false)
            }
          }}
          renderButtonSection={(loading) => (
            <VesselArrivalAddPageButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage

function VesselArrivalAddPageButtonSection(props) {
  const { history, loading, onSetLoading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                // onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACE_OCN_VESSEL_ARRIVAL_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}