import React, { useEffect, useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { NaUSPortAutocompleteField } from 'src/components/na'
import CngSection from '../../../components/cngcomponents/CngSection'
import moment from 'moment-timezone'
import { useFormContext, useWatch } from 'react-hook-form'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: null,
  manifestHeaderId: null,
  carrierScac: "",
  vesselName: "",
  voyageNumber: "",
  vesselCountry: "",
  portOfDischarge: "",
  arrivalDate: null,
  lastForeignPort: "",
  noOfBl: 0,
  arrivalStatus: "1005",
  responseDate: null,
  modeOfTransport: "",
  eta: null,
  partyId: null,
  submittedBy: null,
  submittedDate: null,
  createdBy: null,
  createdDate: null,
  updatedBy: null,
  updatedDate: null,
  version: 0,
  modeOfSubmission: "UI",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const { getTranslatedText } = useContext(ACEOceanTranslationContext)

  const { setValue, getValues, watch, trigger } = useFormContext();

  useEffect(() => {
    if (getValues("arrivalStatus") == null || getValues("arrivalStatus") == undefined || getValues("arrivalStatus") == "")
      setValue("arrivalStatus", "1005")
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection title={getTranslatedText("VESSEL_ARRIVAL", "ACE_OCN_VESSEL_ARRIVAL")}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>Mandatory fields</Typography>
            }>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.arrivalStatus}>
                    <CngCodeMasterAutocompleteField
                      name="arrivalStatus"
                      label={getTranslatedText("VESSEL_ARRIVAL", "arrivalStatus")}
                      disabled={true}
                      codeType='ACE_OCN_STATUS'
                      size="small"
                      key={getValues("arrivalStatus")}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.carrierScac}>
                    <CngTextField
                      name="carrierScac"
                      label={getTranslatedText("VESSEL_ARRIVAL", "carrierScac")}
                      disabled={true}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselName}>
                    <CngTextField
                      name="vesselName"
                      label={getTranslatedText("VESSEL_ARRIVAL", "vesselName")}
                      disabled={true}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.voyageNumber}>
                    <CngTextField
                      name="voyageNumber"
                      label={getTranslatedText("VESSEL_ARRIVAL", "voyageNumber")}
                      disabled={true}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.vesselCountry}>
                    <CngCountryAutocompleteField
                      name="vesselCountry"
                      label={getTranslatedText("VESSEL_ARRIVAL", "vesselCountry")}
                      size='small'
                      lookupProps={{
                        label: (record) => {
                          return `${record.code};${record.descriptionEn}`
                        }
                      }}
                      disabled={true}
                      key={getValues("vesselCountry")}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.portOfDischarge}>
                    <NaUSPortAutocompleteField
                      name="portOfDischarge"
                      label={getTranslatedText("VESSEL_ARRIVAL", "portOfDischarge")}
                      size='small'
                      required
                      disabled={true}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={4} lg={4} shouldHide={shouldHideMap.arrivalDate}>
                    <CngDateField
                      name="arrivalDate"
                      label={getTranslatedText("VESSEL_ARRIVAL", "arrivalDate")}
                      size='small'
                      format='YYYY-MM-DD'
                      required
                      disabled={disabled}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={4} lg={4} shouldHide={shouldHideMap.arrivalTime}>
                    <CngTimeField
                      size='small'
                      required
                      name='arrivalTime'
                      label={getTranslatedText("VESSEL_ARRIVAL", "arrivalTime")}
                      format='HH:mm'
                      disabled={disabled}
                      ampm={false}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);

  localData.responseDate = DateTimeFormatter.toClientDate(
    localData.responseDate
  );

  localData.arrivalTime = moment(localData.arrivalDate).tz("Canada/Eastern").format('HH:mm');
  localData.arrivalDate = moment(localData.arrivalDate).tz("Canada/Eastern").format('YYYY-MM-DD');

  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.arrivalDate = DateTimeFormatter.toServerDate(
    localData.arrivalDate
  );
  localData.responseDate = DateTimeFormatter.toServerDate(
    localData.responseDate
  );

  localData.arrivalDate = localData.arrivalDate + ' ' + localData.arrivalTime;

  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
