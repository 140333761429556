import AceOcnManifestApiUrls from 'src/apiUrls/AceOcnManifestApiUrls'
import FormProperties from './FormProperties'
import { CircularProgress } from '@material-ui/core'
import React, {useState,useEffect} from 'react'
import { components,useServices,constants} from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import pathMap from 'src/paths/pathMap'
import ManifestViewContent from './ManifestViewContent'

const {
  form: { CngViewForm },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ManifestViewPage({ showNotification,data,setData }) {
  const { id } = useParams()
  const history = useHistory()
  
  const [lookups, setLookups] = useState(null)
  const { fetchRecord, fetchRecords, securedSendRequest } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {

    const filter = {
      baseFilterDTO: {
        filterType: "AND",
        filterProperties: [
          {
            fieldName: "manifestId",
            operatorType: "EQUAL",
            value1: id
          }
        ],
        sortProperties: []
      }
    }

    Promise.all([
      // Data
      fetchRecord.execute(
        AceOcnManifestApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
    
    ]).then(([data]) => {
      setData({ ...data })
      
    })
  }, [])
  console.log(data)

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  // function getCountryStateLabel(country, state) {
  //   if (!lookups || !country || !state) return state
  // }

  // if (data === null || statusNotificationData === null) {
  //   console.log(statusNotificationData)
  //   return <CircularProgress />
  // }

  return (
    <ManifestViewContent
      data={data}
      //statusNotificationData={statusNotificationData}
      //getLookupValue={getLookupValue}
      //getCountryStateLabel={getCountryStateLabel}
    />
  )
}

export default ManifestViewPage
