import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    saveFlag: Yup.boolean().required(requiredMessage),
    productIdentification: Yup.string()
      .when('saveFlag', {
        is: false,
        then: (schema) => schema,
        otherwise: Yup.string().when('saveFlag', {
          is: true,
          then: Yup.string()
            .trim()
            .min(1, 'Max length allowed is 1.')
            .max(35, 'Max length is 35.')
            .required(requiredMessage)
            .matches(
              '[a-zA-Z0-9s]$',
              'Only alphanumeric characters are allowed.'
            )
        })
      })
      .nullable(),
    itemNo: Yup.string()
      .trim()
      .max(4, 'Max length allowed is 4')
      .required(requiredMessage)
      .matches(/^\d+$/, 'Only numbers are allowed!')
      .nullable(),
    ladingQuantity: Yup.string()
      .trim()
      .max(7, 'Max length allowed is 7')
      .required(requiredMessage)
      .matches(/^\d+$/, 'Only numbers are allowed!')
      .nullable(),
    ladingUom: Yup.string()
      .max(3)
      .required(requiredMessage)
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric values are allowed.')
      .nullable(),
    productDesc: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']+$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc1: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc2: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc3: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc4: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc5: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc6: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc7: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    productDesc8: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(
        /^[a-zA-Z0-9\s-.,']*$/,
        'Product Description cannot contain special characters.'
      ),
    grossWeight: Yup.string()
      .trim()
      .max(14, 'Max length allowed is 14')
      .required(requiredMessage)
      .matches(
        /^\d+(\.\d{0,2})?$/,
        'Only numbers with two decimals are allowed.'
      ),
    grossWeightUom: Yup.string()
      .max(3, 'Max length allowed is 3')
      .required(requiredMessage)
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric values are allowed.')
      .nullable(),
    measurementVol: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .trim()
            .max(14, 'Max length allowed is 14')
            .matches(
              /^\d+(\.\d{0,3})?$/,
              'Only numbers with three decimals are allowed.'
            )
            .nullable()
    ),
    measurementUom: Yup.string()
      .when('measurementVol', {
        is: (value) => value,
        then: Yup.string()
          .max(3, 'Max length allowed is 3')
          .required(requiredMessage)
          .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric values are allowed.'),
        otherwise: Yup.string()
      })
      .nullable(),
    undgCode: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .max(4, 'Max length allowed is 4')
            .nullable()
            .matches(/^\d+$/, 'Only numbers are allowed!')
    ),
    markNo: Yup.string()
      .trim()
      .max(35, 'Max length allowed is 35')
      .nullable()
      .matches(
        /^[a-zA-Z0-9\s-.,]*$/,
        'Shipping Marks and Numbers cannot contain special characters.'
      ),
    hsCode: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .min(8, 'Min length needed is 8')
            .max(10, 'Max length allowed is 10')
            .nullable()
            .matches(
              /^\d+$/,
              'H.S. Number accepts only maximum of 10 digits. Please specify a correct value.'
            )
    )
  })
}

export default makeValidationSchema
