import React, { useContext } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import AciAirResponseKeys from '../../../constants/locale/key/AciAirResponse'
import AciAirResponseApiUrls from '../../../apiUrls/AciAirResponseApiUrls'
import AciAirD4NoticesApiUrls from '../../../apiUrls/AciAirD4NoticesApiUrls'
import { LookupsContext } from '../../../contexts/LookupsContext'
import Table from '../../../components/aciacehighway/Table'
import CngSection from '../../../components/cngcomponents/CngSection'
import { Box, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import MaterialTable from "material-table"

const { CngTabs } = components

const {
  filter: { EQUAL }
} = constants

function ViewLogsHistory(props) {
  const { ccnValue, headerId, showNotification } = props

  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const response = translate(Namespace.ACI_AIR, AciAirKeys.Response.TITLE)
    const acrResponse = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.RESPONSE_MSG_TITLE
    )
    const errorTextLabel = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.ERROR_TEXT_LABEL
    )
    const errorCodeLabel = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.ERROR_CODE_LABEL
    )
    const rejectTypeLabel = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.REJECT_TYPE_LABEL
    )
    const errorDescLabel = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.ERROR_DESC_LABEL
    )
    const responseTextLabel = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.RESPONSE_TEXT_LABEL
    )
    const errorInfoLabel = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.ERROR_INFO_LABEL
    )    
    const ccn = translate(Namespace.ACI_AIR, AciAirResponseKeys.CCN)
    const status = translate(Namespace.ACI_AIR, AciAirResponseKeys.STATUS)
    const receivedDate = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.RECEIVED_DATE
    )
    const cbsaProcessingDate = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.CBSA_PROCESSING_DATE
    )
    const cbsaRefNo = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.CBSA_REF_NO
    )
    const documentType = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.DOCUMENT_TYPE
    )
    const remarks = translate(Namespace.ACI_AIR, AciAirResponseKeys.REMARKS)
    const systemDateTime = translate(
      Namespace.ACI_AIR,
      AciAirResponseKeys.SYSTEM_PROCESSED_DATE_TIME
    )
    const activityLogs = translate(Namespace.ACI_AIR, AciAirKeys.ACTIVITY_LOGS)
    const createdDate = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CREATED_DATE_TIME
    )
    const loginUserId = translate(Namespace.ACI_AIR, AciAirKeys.LOGIN_USER_ID)
    const functionCode = translate(Namespace.ACI_AIR, AciAirKeys.FUNCTION_CODE)
    const auditDescription = translate(Namespace.ACI_AIR, AciAirKeys.AUDIT_DESC)

    return {
      response,
      acrResponse,
      errorTextLabel,
      errorCodeLabel,
      rejectTypeLabel,
      errorDescLabel,
      responseTextLabel,
      errorInfoLabel,
      ccn,
      status,
      receivedDate,
      cbsaProcessingDate,
      cbsaRefNo,
      documentType,
      remarks,
      systemDateTime,
      activityLogs,
      createdDate,
      loginUserId,
      functionCode,
      auditDescription
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.acrResponse,
              tabContent: (
                <ACRResponse
                  headerId={headerId}
                  showNotification={showNotification}
                  translatedTextsObject={translatedTextsObject}
                />
              )
            },
            {
              tabName: 'D4 Notices',
              tabContent: (
                <D4Response
                  ccnValue={ccnValue}
                  showNotification={showNotification}
                  translatedTextsObject={translatedTextsObject}
                />
              )
            }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <ActivityLogs
          headerId={headerId}
          showNotification={showNotification}
          translatedTextsObject={translatedTextsObject}
        />
      </Grid>
    </Grid>
  )
}

function ACRResponse(props) {
  const { headerId, showNotification, translatedTextsObject } = props
  const { getLookupValue } = useContext(LookupsContext)

  const columns = [
    {
      field: 'errorCode',
      title: translatedTextsObject.errorInfoLabel,
      render: (rowData) => {
        const errorCodes = rowData?.errorResponses || []

        return (
          <Grid container spacing={2}>
            {errorCodes.map((error, index) => {
              const { errorCode, rejectType, errorDesc, responseText } = error

              return (
                <span>
                  <MaterialTable
                    options={{
                      paging: false,
                      showTitle: false,
                      search: false,
                      sorting: false,
                      draggable: false,
                      toolbar: false
                    }}
                    columns={[
                      { title: translatedTextsObject.errorCodeLabel, field: "code" },
                      { title: translatedTextsObject.rejectTypeLabel, field: "rejectType" },
                      { title: translatedTextsObject.errorDescLabel, field: "description" },
                      { title: translatedTextsObject.responseTextLabel, field: "text" },
                    ]}
                    data={[
                      {
                        code: errorCode,
                        rejectType: rejectType,
                        description: errorDesc,
                        text: responseText
                      }
                    ]}
                  />
                </span>
              )
            })}
          </Grid>
        )
      }
    },
    {
      field: 'convRefNo',
      title: translatedTextsObject.ccn
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (rowData) => getLookupValue('status', rowData.status)
    },
    {
      field: 'receivedDate',
      title: translatedTextsObject.receivedDate,
      render: (rowData) =>
        rowData.receivedDate &&
        moment(rowData.receivedDate).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: 'cbsaProcessingDate',
      title: translatedTextsObject.cbsaProcessingDate,
      render: (rowData) =>
        rowData.cbsaProcessingDate &&
        moment(rowData.cbsaProcessingDate).format('DD/MM/YYYY HH:mm:ss')
    }
  ]

  return (
    <Table
      columns={columns}
      exportData={{ url: AciAirResponseApiUrls.EXPORT }}
      fetch={{ url: AciAirResponseApiUrls.GET }}
      fetchFilters={[{ field: 'headerId', operator: EQUAL, value: headerId }]}
      showNotification={showNotification}
      sortConfig={{
        type: 'column',
        defaultField: 'receivedDate',
        defaultDirection: 'DESC'
      }}
    />
  )
}

function D4Response(props) {
  const { ccnValue, showNotification, translatedTextsObject } = props

  const columns = [
    {
      field: 'cbsaRefNo',
      title: translatedTextsObject.cbsaRefNo
    },
    {
      field: 'documentType',
      title: translatedTextsObject.documentType
    },
    {
      field: 'remarks',
      title: translatedTextsObject.remarks
    },
    {
      field: 'receiveDateTime',
      title: translatedTextsObject.systemDateTime,
      render: (rowData) =>
        rowData.receiveDateTime &&
        moment(rowData.receiveDateTime).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: 'cbsaProcessDateTime',
      title: translatedTextsObject.cbsaProcessingDate,
      render: (rowData) =>
        rowData.cbsaProcessDateTime &&
        moment(rowData.cbsaProcessDateTime).format('DD/MM/YYYY HH:mm:ss')
    }
  ]

  return (
    <Table
      columns={columns}
      compact
      exportData={{ url: AciAirD4NoticesApiUrls.EXPORT }}
      fetch={{ url: AciAirD4NoticesApiUrls.GET }}
      fetchFilters={[
        { field: 'noticeType', operator: EQUAL, value: 'AIR' },
        { field: 'cbsaRefNo', operator: EQUAL, value: ccnValue }
      ]}
      showNotification={showNotification}
      sortConfig={{
        type: 'column',
        defaultField: 'receivedDate',
        defaultDirection: 'DESC'
      }}
    />
  )
}

function ActivityLogs(props) {
  const { headerId, showNotification, translatedTextsObject } = props

  const columns = [
    {
      field: 'functionCode',
      title: translatedTextsObject.functionCode
    },
    {
      field: 'auditDescription',
      title: translatedTextsObject.auditDescription
    },
    {
      field: 'userName',
      title: translatedTextsObject.loginUserId
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate &&
        moment(rowData.createdDate).format('DD/MM/YYYY HH:mm:ss')
    }
  ]

  return (
    <CngSection title={translatedTextsObject.activityLogs}>
      <Table
        columns={columns}
        compact
        exportData={{ url: AciAirResponseApiUrls.EXPORT }}
        fetch={{ url: AciAirResponseApiUrls.GET }}
        fetchFilters={[
          {
            field: 'keyReferenceNo',
            operator: EQUAL,
            value: 'ACI_AIR_' + headerId
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'receivedDate',
          defaultDirection: 'DESC'
        }}
      />
    </CngSection>
  )
}

export default ViewLogsHistory
