import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import moment from 'moment-timezone'
import AceOcnVesselArrivalKeys from 'src/constants/locale/key/AceOcnVesselArrival'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let arrivalDateInvalid = translate(
    Namespace.ACE_OCN_VESSEL_ARRIVAL,
    AceOcnVesselArrivalKeys.Validations.ARRIVAL_DATE_INVALID
  )

  let arrivalTimeInvalid = translate(
    Namespace.ACE_OCN_VESSEL_ARRIVAL,
    AceOcnVesselArrivalKeys.Validations.ARRIVAL_TIME_INVALID
  )

  let errMsgNumeric = translate(
    Namespace.ACE_OCN_VESSEL_ARRIVAL,
    AceOcnVesselArrivalKeys.Validations.ERROR_MSG_NUMERIC
  )

  let errMsgMaxLength = translate(
    Namespace.ACE_OCN_VESSEL_ARRIVAL,
    AceOcnVesselArrivalKeys.Validations.ERROR_MSG_MAX_LENGTH
  )

  let regexNumeric = "^[0-9]*$";
  let regexDate = "[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])"
  let regexTime = "(2[0-3]|[01][0-9]):[0-5][0-9]"

  return Yup.object({
    manifestHeaderId: Yup.string().nullable(),
    carrierScac: Yup.string().nullable().max(4, "Max allowed length is 4"),
    vesselName: Yup.string().nullable().max(23, "Max allowed length is 23"),
    voyageNumber: Yup.string().nullable().max(5, "Max allowed length is 5"),
    vesselCountry: Yup.string().nullable().max(2, "Max allowed length is 2"),
    portOfDischarge: Yup.string().nullable().max(4, "Max allowed length is 4"),
    arrivalDate: Yup.string().required(requiredMessage)
      .test(
        'arrivalDate',
        arrivalDateInvalid,
        value => {
          return moment(value, 'YYYY-MM-DD').isValid()
        },
      ).nullable(),
    arrivalTime: Yup.string().required(requiredMessage).test(
      'arrivalTime',
      arrivalTimeInvalid,
      value => {
        return moment(value, 'HH:mm').isValid()
      },
    ).nullable(),
    lastForeignPort: Yup.string().nullable(),
    noOfBl: Yup.string().nullable(),
    arrivalStatus: Yup.string().nullable(),
    responseDate: Yup.string().nullable(),
    responseTime: Yup.string().nullable(),
    modeOfTransport: Yup.string().nullable(),
    eta: Yup.string().nullable(),
    partyId: Yup.string().nullable(),
    submittedBy: Yup.string().nullable(),
    submittedDate: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    createdDate: Yup.string().nullable(),
    updatedBy: Yup.string().nullable(),
    updatedDate: Yup.string().nullable(),
  })
}

export default makeValidationSchema
