import React, { useRef, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import { Box, Grid } from '@material-ui/core'
import CngField from 'src/components/cngcomponents/CngField'
import ContainerDetailsFormProperties from './ContainerDetailsFormProperties'

const {
    button: { CngPrimaryButton },
    form: { CngForm },
    CngDialog
} = components

const { FormState } = constants

function ContainersDialog(props) {
    const { open, onClose, container, showNotification, disabled } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)
    const formContext = useFormContext()

    const lastIndex = useRef(0)

    const { initialValues, makeValidationSchema } = ContainerDetailsFormProperties.formikProps

    function handleAddContainer(data) {
        const containers = [...formContext.getValues('containerDetails'), data].map(
            (container) => ({ ...container, _id: ++lastIndex.current })
        )

        formContext.setValue('containerDetails', containers)
        onClose()
    }

    function handleEditContainer(data) {
        const containers = [...formContext.getValues('containerDetails')]
        const index = containers.findIndex((container) =>
            container.id ? container.id === data.id : container._id === data._id
        )

        containers[index] = data

        formContext.setValue('containerDetails', containers)
        onClose()
    }

    if (!open) {
        return null
    }

    let viewContent = container && (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <CngField label={getTranslatedText('BOL', 'containerNumber')}>
                    {container.containerNumber}
                </CngField>
            </Grid>
            <Grid item xs={12} sm={4} >
                <CngField label={getTranslatedText('BOL', 'typeCode')}>
                    {container.typeCode}
                </CngField>
            </Grid>
            <Grid item xs={12} sm={4} >
                <CngField label={getTranslatedText('BOL', 'descriptionCode')}>
                    {container.descriptionCode}
                </CngField>
            </Grid>
            <Grid item xs={12} sm={4} >
                <CngField label={getTranslatedText('BOL', 'sealNumber')}>
                    {container.sealNumber}
                </CngField>
            </Grid>
            <Grid item xs={12} sm={4}>
                <CngField label={getTranslatedText('BOL', 'serviceCode')}>
                    {container.serviceCode}
                </CngField>
            </Grid>
            <Grid item xs={12} sm={4}>
                <CngField label={getTranslatedText('BOL', 'containerStatus')}>
                    {container.containerStatus}
                </CngField>
            </Grid>
        </Grid>
    )

    return (
        <CngDialog
            dialogContent={
                disabled ? (viewContent) : (
                    <CngForm
                        fieldLevel='form'
                        formikProps={{
                            initialValues: container || initialValues,
                            makeValidationSchema: makeValidationSchema,
                            onSubmit: (data) => {
                                if (container) {
                                    handleEditContainer(data)
                                } else {
                                    handleAddContainer(data)
                                }
                            }
                        }}
                        formState={FormState.COMPLETED}
                        innerForm={true}
                        renderBodySection={(labelMap, shouldHideMap) => (
                            <ContainerDetailsFormProperties.Fields showNotification={showNotification} shouldHideMap={shouldHideMap} />
                        )}
                        renderButtonSection={() => (
                            <Box display='flex' justifyContent='flex-end'>
                                <CngPrimaryButton type='submit' disabled={formContext ? formContext.formState.isSubmitting : false}>
                                    {container ? "Update" : "Save"}
                                </CngPrimaryButton>
                            </Box>
                        )}
                    />)
            }
            dialogTitle={getTranslatedText('BOL', 'containerDetails')}
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            fullWidth
            maxWidth='lg'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default ContainersDialog
