import makeValidationSchema from './AciAirEquipmentListMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from "react-hook-form";

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerid: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `aciAirEquipmentList.${index}.${field}` : field
  }, [])

  function makeTranslatedTextsObject() {
    const containerid = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirEquipmentList.CONTAINERID
    )

    return { containerid }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.containerid}>
        <CngTextField
          name={getFieldName('containerid')}
          inputProps={{ maxLength: 10 }}
          label={translatedTextsObject.containerid}
          isRequired
          disabled={disabled}
          size='small'
          onBlur={(e) => {
            const value = e.target.value.toUpperCase().trim()

            setValue('containerid', value, { shouldValidate: true })
          }}
        />
      </CngGridItem>
    </Grid>
	)
}

const AciAirEquipmentListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AciAirEquipmentListFormProperties
