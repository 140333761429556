import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import VesselMasterApiUrls from 'src/apiUrls/AceOcnVesselMasterApiUrls'
import React, { useState, useRef, useEffect } from 'react'
import VesselMasterKeys from 'src/constants/locale/key/AceOcnVesselMaster'
import Namespace from 'src/constants/locale/Namespace'
import { FileForUserGetPartyId, GetFilterByUserIdCriteria } from 'src/common/FileForUserCommon'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {  LIKE, BETWEEN, EQUAL, IN }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)
  const notification = useDefaultNotification(showNotification)
  const { deleteRecord, securedSendRequest } = useServices()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACE_OCN_VESSEL_MASTER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let vesselMaster = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: vesselMaster
      }
    )
    let vesselCode = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_CODE
    )
    let vesselName = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_NAME
    )
    let vesselOwner = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_OWNER
    )
    let vesselCountry = translate(
      Namespace.ACE_OCN_VESSEL_MASTER,
      VesselMasterKeys.VESSEL_COUNTRY
    )

    return {
      vesselMaster,
      tableTitle,
      vesselCode,
      vesselName,
      vesselOwner,
      vesselCountry
    }
  }

  const columns = [
    {
      field: "vesselCode",
      title: translatedTextsObject.vesselCode,
    },
    {
      field: "vesselName",
      title: translatedTextsObject.vesselName,
    },
    {
      field: "vesselOwner",
      title: translatedTextsObject.vesselOwner,
    },
    {
      field: "vesselCountry",
      title: translatedTextsObject.vesselCountry,
    },
    {
      field: "createdDate",
      title: "Created Date",
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.vesselCode,
      type: 'textfield',
      name: 'vesselCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.vesselName,
      type: 'textfield',
      name: 'vesselName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.vesselOwner,
      type: 'textfield',
      name: 'vesselOwner',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.vesselCountry,
      type: 'textfield',
      name: 'vesselCountry',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    }
  ]

  useEffect(() => {
    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, [])

  // let filter = GetFilterByUserIdCriteria("ACEOCN")

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {sbPartyIdFetched && (<CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: VesselMasterApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: VesselMasterApiUrls.EXPORT }}
              fetch={{ url: VesselMasterApiUrls.GET }}
              fetchFilters={[
                { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
                // ...filter
              ]}
              filters={filters}
              idAccessor="id"
              notification={notification}
              viewRoute={`${pathname}/view`}
            />)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
