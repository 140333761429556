import makeValidationSchema from './AciAirRouteListMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import Grid from '@material-ui/core/Grid'
import { NaCarrierAutocompleteField, NaAirPortAutocompleteField } from 'src/components/na'

const { CngGridItem } = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  carrierCode: "",
  locationCode: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `aciAirRouteList.${index}.${field}` : field
  }, [])

  function makeTranslatedTextsObject() {
    const carrierCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirRouteList.CARRIER_CODE
    )
    const locationCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirRouteList.LOCATION_CODE
    )

    return { carrierCode, locationCode }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.carrierCode}>
        <NaCarrierAutocompleteField
          name={getFieldName('carrierCode')}
          label={translatedTextsObject.carrierCode}
          isRequired
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.locationCode}>
        <NaAirPortAutocompleteField
          name={getFieldName('locationCode')}
          label={translatedTextsObject.locationCode}
          isRequired
          multiple
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const AciAirRouteListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AciAirRouteListFormProperties
