import React, { useEffect, useState, useContext } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import moment from 'moment-timezone'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'
import ResponseApiUrls from 'src/apiUrls/AceOcnResponseApiUrls'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function StatusNotification(props) {
  const { id, isViewOnly = false } = props

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecord, fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const classes = useStyles()

  function fetchAceOnStatusNotification() {
    return fetchRecords.execute(ResponseApiUrls.GET_SN,
      {
        filters: [
          {
            field: 'manifestId',
            operator: 'equal',
            value: id
          }
        ]
      }, (res) => res)
  }

  function showAceOCnStatusNotification() {
    Promise.all([fetchAceOnStatusNotification()]).then(([respData]) => setData(respData))
  }

  useEffect(() => {
    showAceOCnStatusNotification();
    // Promise.all([
    //   fetchCodeMaintenanceLookup(
    //     constants.CodeMaintenanceType.CODE_MASTER,
    //     undefined,
    //     [
    //       {
    //         field: 'codeType',
    //         operator: EQUAL,
    //         value: 'ACI_HW_SUBMISSION_TYPE'
    //       }
    //     ],
    //     undefined,
    //     'code'
    //   ),
    //   fetchCodeMaintenanceLookup(
    //     constants.CodeMaintenanceType.CODE_MASTER,
    //     undefined,
    //     [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
    //     undefined,
    //     'code'
    //   )
    // ]).then(([submissionType, status]) => {
    //   setLookups({ submissionType, status })
    // })
  }, [])

  const columns = [
    {
      field: 'cbpbatchNo',
      title: "CBP BATCH NO"
    },
    {
      field: 'cbpRemarks',
      title: "CBP REMARKS"
    },
    {
      field: 'blNumber',
      title: "BILL NUMBER"
    },
    {
      field: 'dispositionCode',
      title: "DISPOSITION CODE"
    },
    {
      field: 'quantity',
      title: "SN QTY"
    },
    {
      field: 'totalPackages',
      title: "BL QTY"
    },
    {
      field: 'entityNo',
      title: "ENTITY NUMBER"
    },
    {
      field: 'entryType',
      title: "ENTRY TYPE"
    },
    {
      field: 'entryNo',
      title: "ENTRY NUMBER"
    },
    {
      field: 'actionDateTime',
      title: "ACTION DATE",
      render: (data) =>
        data.actionDateTime &&
        moment(data.actionDateTime).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'cbpProcessingDate',
      title: "CBP PROCESSING DATE",
      render: (data) =>
        data.cbpProcessingDate &&
        moment(data.cbpProcessingDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => {
                      let cellValue = d[column.field];
                      
                      if (column.field === "quantity") {
                        cellValue = String(cellValue).replace(/^0+/, '');
                      }
                      
                      return (
                        <TableCell key={index}>
                          {column.render ? column.render(d) : cellValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 2000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

export default StatusNotification
