import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import AceOcnVesselDepartureKeys from 'src/constants/locale/key/AceOcnVesselDeparture'

import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let departureDateInvalid = translate(
    Namespace.ACE_OCN_VESSEL_DEPARTURE,
    AceOcnVesselDepartureKeys.Validations.DEPARTURE_DATE_INVALID
  )

  let departureTimeInvalid = translate(
    Namespace.ACE_OCN_VESSEL_DEPARTURE,
    AceOcnVesselDepartureKeys.Validations.DEPARTURE_TIME_INVALID
  )

  let errMsgNumeric = translate(
    Namespace.ACE_OCN_VESSEL_DEPARTURE,
    AceOcnVesselDepartureKeys.Validations.ERROR_MSG_NUMERIC
  )

  let errMsgMaxLength = translate(
    Namespace.ACE_OCN_VESSEL_DEPARTURE,
    AceOcnVesselDepartureKeys.Validations.ERROR_MSG_MAX_LENGTH
  )

  let regexNumeric = "^[0-9]*$";

  return Yup.object({
    manifestHeaderId: Yup.string().nullable(),
    carrierScac: Yup.string().nullable(),
    vesselName: Yup.string().nullable(),
    voyageNumber: Yup.string().nullable(),
    vesselCountry: Yup.string().nullable(),
    portOfDischarge: Yup.string().nullable(),
    departureDate: Yup.string().required(requiredMessage)
    .test(
      'departureDate',
      departureDateInvalid,
      value =>  {
        return moment(value, 'YYYY-MM-DD').isValid()
      },
    ).nullable(),
    departureTime: Yup.string().required(requiredMessage).test(
      'departureTime',
      departureTimeInvalid,
      value =>  {
        return moment(value, 'HH:mm').isValid()
      },
    ).nullable(),
    lastForeignPort: Yup.string().nullable().required(requiredMessage),
    noOfBl: Yup.string().nullable().required(requiredMessage).matches(regexNumeric,errMsgNumeric).max(5,errMsgMaxLength+" 5"),
    departureStatus: Yup.string().nullable(),
    responseDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    vesselCode: Yup.string().nullable(),
    entityNo: Yup.string().nullable(),
    modeOfTransport: Yup.string().nullable(),
    eta: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    partyId: Yup.string().nullable(),
    submittedBy: Yup.string().nullable(),
    submittedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    createdBy: Yup.string().nullable(),
    createdDate: Yup.string().nullable(),
    updatedBy: Yup.string().nullable(),
    updatedDate: Yup.string().nullable(),
  })
}

export default makeValidationSchema
