import { Typography } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import AceOcnManifestApiUrls from 'src/apiUrls/AceOcnManifestApiUrls'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'
import Namespace from 'src/constants/locale/Namespace'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from 'src/components/aciacehighway/Table'
import { getStatusMetadata } from 'src/common/NACommon'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetPartyId, GetFilterByUserIdCriteria,FileForUserGetCurrentLoginId } from 'src/common/FileForUserCommon'
import moment from 'moment-timezone'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";
import FileForUserContext from 'src/contexts/FileForUserContext'

const { 
  table: { 
    DateRangeFilter: CngDateRangeFilter,
    useFetchCodeMaintenanceLookup 
  } 
} = components

const {
  locale: { key: { UiComponentKeys } },
  filter: { EQUAL, LIKE, IN, BETWEEN }
} = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const tableRef = useRef(null)
  const { deleteRecord, securedSendRequest } = useServices()
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)
  const [codeMaintenanceFetched, setCodeMaintenanceFetched] = useState(false)
  const [hqAdmin, setHqAdmin] = useState(false)

  const { translate } = useTranslation([Namespace.UI_COMPONENT, Namespace.ACE_OCN_MANIFEST])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const { partyConfigFetched } = useContext(FileForUserContext)

  function makeTranslatedTextsObject() {
    let aceOcnManifest = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      { nameTitleised: aceOcnManifest }
    )
    let scac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SCAC
    )
    let vesselName = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_NAME
    )
    let voyageNumber = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VOYAGE_NUMBER
    )
    let vesselCountry = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_COUNTRY
    )
    let portOfDischarge = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PORT_OF_DISCHARGE
    )
    let etaDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.E_T_A
    )
    let modeOfTransport = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_TRANSPORT
    )
    let paperlessInbondParticipant = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PAPERLESS_INBOND_PARTICIPANT
    )
    let issuerScac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.ISSUER_SCAC
    )
    let partyId = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_DATE
    )
    let modeOfSubmission = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_SUBMISSION
    )
    let status = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.STATUS
    )
    let messageFunCode = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MESSAGE_FUN_CODE
    )
    let saveButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SAVE_BUTTON
    )

    let editButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.EDIT_BUTTON
    )

    let viewButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VIEW_BUTTON
    )

    let cloneButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.DELETE_BUTTON
    )

    let updateButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.UPDATE_BUTTON
    )

    let submitButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.EDIT_SENT_RECORDS
    )
    let createdDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.CREATED_DATE
    )
    let responseDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.RESPONSE_DATE
    )

    let createdByLoginId = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.CREATED_BY_LOGIN_ID
    )

    return {
      aceOcnManifest,
      tableTitle,
      scac,
      vesselName,
      voyageNumber,
      vesselCountry,
      portOfDischarge,
      etaDate,
      modeOfTransport,
      paperlessInbondParticipant,
      issuerScac,
      partyId,
      submittedBy,
      submittedDate,
      modeOfSubmission,
      status,
      messageFunCode,
      saveButton,
      editButton,
      viewButton,
      cloneButton,
      deleteButton,
      updateButton,
      submitButton,
      editSentRecord,
      createdDate,
      responseDate,
      createdByLoginId
    }
  }

  const [confirmDialog, setConfirmDialog] = useState({ open: false, aceocnmanifest: null })


  let columns = [
    {
      field: "id",
      sortKey: "id",
      title: "ID",
    },
    {
      field: "scac",
      sortKey: "scac",
      title: translatedTextsObject.scac,
    },
    {
      field: "vesselName",
      sortKey: "vesselName",
      title: translatedTextsObject.vesselName,
    },
    {
      field: "voyageNumber",
      sortKey: "voyageNumber",
      title: translatedTextsObject.voyageNumber,
    },
    {
      field: "portOfDischarge",
      sortKey: "portOfDischarge",
      title: translatedTextsObject.portOfDischarge,
    },
    {
      field: "etaDate",
      sortKey: "etaDate",
      title: translatedTextsObject.etaDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "issuerScac",
      sortKey: "issuerScac",
      title: translatedTextsObject.issuerScac,
    },
    {
      field: "submittedDate",
      sortKey: "submittedDate",
      title: translatedTextsObject.submittedDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
      render: (data) => data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (rowData) => {
        const { color, label } = getStatusMetadata(rowData.status)
        return <Typography style={{ color }}>{label}</Typography>
      }
    },
    {
      field: "responseDate",
      sortKey: "responseDate",
      title: translatedTextsObject.responseDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
      render: (data) => data.responseDate && moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "createdDate",
      sortKey: "createdDate",
      title: translatedTextsObject.createdDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
      render: (data) => data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  let hqAdminColumns = [
    {
      field: "id",
      sortKey: "id",
      title: "ID",
    },
    {
      field: "scac",
      sortKey: "scac",
      title: translatedTextsObject.scac,
    },
    {
      field: "vesselName",
      sortKey: "vesselName",
      title: translatedTextsObject.vesselName,
    },
    {
      field: "voyageNumber",
      sortKey: "voyageNumber",
      title: translatedTextsObject.voyageNumber,
    },
    {
      field: "portOfDischarge",
      sortKey: "portOfDischarge",
      title: translatedTextsObject.portOfDischarge,
    },
    {
      field: "etaDate",
      sortKey: "etaDate",
      title: translatedTextsObject.etaDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "issuerScac",
      sortKey: "issuerScac",
      title: translatedTextsObject.issuerScac,
    },
    {
      field: "submittedDate",
      sortKey: "submittedDate",
      title: translatedTextsObject.submittedDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
      render: (data) => data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (rowData) => {
        const { color, label } = getStatusMetadata(rowData.status)
        return <Typography style={{ color }}>{label}</Typography>
      }
    },
    {
      field: "responseDate",
      sortKey: "responseDate",
      title: translatedTextsObject.responseDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
      render: (data) => data.responseDate && moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: "createdByLoginId",
      sortKey: "createdByLoginId",
      title: translatedTextsObject.createdByLoginId
    },
    {
      field: "createdDate",
      sortKey: "createdDate",
      title: translatedTextsObject.createdDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
      render: (data) => data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  const filters = [
    {
      label: "ID",
      type: 'textfield',
      name: 'id',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.scac,
      type: 'textfield',
      name: 'scac',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.vesselName,
      type: 'textfield',
      name: 'vesselName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.voyageNumber,
      type: 'textfield',
      name: 'voyageNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.portOfDischarge,
      type: 'textfield',
      name: 'portOfDischarge',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.etaDate,
      type: 'daterange',
      name: 'etaDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.issuerScac,
      type: 'textfield',
      name: 'issuerScac',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: '1005',
          filterValue: { value: '1005' }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: '1000',
          filterValue: { value: '1000' }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: '1008',
          filterValue: { value: '1008' }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: '1001',
          filterValue: { value: '1001' }
        },
        {
          fullWidth: true,
          label: 'Cancel',
          value: '1011',
          filterValue: { value: '1011' }
        },
        {
          fullWidth: true,
          label: 'Status Notification',
          value: '1006',
          filterValue: { value: '1006' }
        }
      ]
    },
    {
      label: translatedTextsObject.responseDate,
      type: 'daterange',
      name: 'responseDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdByLoginId,
      type: 'textfield',
      name: 'createdByLoginId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    }
  ]

  function handleDeleteManifest() {
    if (confirmDialog.aceocnmanifest) {
      deleteRecord.execute(
        AceOcnManifestApiUrls.DELETE,
        confirmDialog.aceocnmanifest,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, aceocnmanifest: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong while deleting record.')
        }
      )
    }
  }

  useEffect(() => {

    Promise.all([
      //HQ_ADMINISTRATOR
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'HQ_ADMINISTRATOR' },
          { field: 'code', operator: EQUAL, value: FileForUserGetCurrentLoginId() }
        ],
        undefined,
        'code'
      )
    ]).then(
      ([hqAdmin]) => {
        if(hqAdmin && hqAdmin[FileForUserGetCurrentLoginId()] == "true"){
          setHqAdmin(true)
        }
        setCodeMaintenanceFetched(true);
      }
    )

    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }

  }, [])

  let filter = GetFilterByUserIdCriteria("ACEOCN")

  return (
    <>
      {sbPartyIdFetched && codeMaintenanceFetched && partyConfigFetched && ( <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACE_OCN_MANIFEST_ADD_VIEW)
            },
            label: 'Create Record'
          }
        ]}
        columns={hqAdmin ? hqAdminColumns : columns}
        compact
        exportData={{ url: AceOcnManifestApiUrls.EXPORT }}
        fetch={{ url: AceOcnManifestApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === '1008') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) => history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008' ? 'Cannot edit Sent records' : null
          },
          {
            disabled: (rowData) => rowData.status !== '1005',
            label: translatedTextsObject.deleteButton,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (rowData) => setConfirmDialog({ open: true, aceocnmanifest: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005'
                ? "Cannot delete record with status other than 'Draft'"
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'eye']} />,
            label: translatedTextsObject.viewButton,
            onClick: (rowData) => history.push(`${location.pathname}/view/${rowData.id}`),
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />)}
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, manifest: null })}
        confirmDialog={handleDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default TablePage
