import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    carrierCode: Yup.string()
      .required(requiredMessage)
      .min(2, 'Min Length needed is 2.')
      .max(3, 'Max Length allowed is 3.')
      .matches('[a-zA-Z0-9]*$', 'Only alphanumeric values are allowed.')
      .nullable(),
    locationCode: Yup.array()
      .required(requiredMessage)
      .min(3, 'Min Length needed is 3.')
      .max(4, 'Max Length allowed is 4.')
      .matches('[a-zA-Z0-9]*$', 'Only alphanumeric values are allowed.')
      .nullable()
  })
}

export default makeValidationSchema
