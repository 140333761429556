import AceOcnManifestApiUrls from 'src/apiUrls/AceOcnManifestApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from 'react-router-dom'
import ConfirmDialog from 'src/views/common/ConfirmDialog'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function ManifestAddPage({ history, showNotification, onSetLoading }) {
  const loc = useLocation()
  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.ACE_OCN_MANIFEST_PATH}/edit/` + e.id)
  )
  const [lookups, setLookups] = useState(null)
  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }
  
  let formikPropsData;
  if (loc.state !== undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: loc.state,
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  function handleSaveAndContinue() {
    onSuccessCallback.current = (e) => {
      history.push({
        pathname: `${pathMap.ACE_OCN_MANIFEST_PATH}/edit/` + e.id,
        state: { step: 2, manifestData: e }
      })
    }
  }

  return (
    <CngAddForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      history={history}
      showNotification={showNotification} 
      renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
        <FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          shouldHideMap={shouldHideMap}
          getLookupValue={getLookupValue}
          getCountryStateLabel={getCountryStateLabel}
        />
      )}
      formikProps={formikPropsData}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      create={{
        url: AceOcnManifestApiUrls.POST,
        onSuccess: (e) => {
          showNotification('success', 'Add record has succeeded.')
          onSuccessCallback.current(e)
          onSetLoading(false)
        }
      }}
      renderButtonSection={(loading) => (
        <ManifestAddPageButtonSection
          history={history}
          loading={loading}
          onSaveAndContinue={handleSaveAndContinue}
          onSetLoading={onSetLoading}
        />
      )}
    />
  )
}

export default ManifestAddPage

function ManifestAddPageButtonSection(props) {
  const { history, loading, onSaveAndContinue, onSetLoading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                // onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                type='submit'
                onClick={onSaveAndContinue}
                size='medium'
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACE_OCN_MANIFEST_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
