import AceOcnManifestApiUrls from 'src/apiUrls/AceOcnManifestApiUrls'
import FormProperties from './FormProperties'
import React,{useState, useEffect} from 'react'
import { components, useServices,constants } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'
import ViewContent from './ManifestViewPage'
import ViewLogsHistory from './ViewLogsHistory'
import pathMap from 'src/paths/pathMap'
import { Grid } from '@material-ui/core'

import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'


const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup },
  CngTabs
} = components

const { filter: { EQUAL } } = constants

function ViewPage(props) {
  const { history, showNotification } = props
  const { id } = useParams()
  const { fetchRecord } = useServices()

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(AceOcnManifestApiUrls.GET, id, (res) => res.content[0]),

      
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACEOCN_MANI_MOT' }],
        undefined, 'code'
      ),
    ]).then(([data, modeOfTransport]) => {
      setData({ ...data })
      setLookups({ modeOfTransport })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }


  if (data === null) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: "View Manifest",
              tabContent: (
                <ViewContent
                  showNotification={showNotification}
                  id={id}
                  data={data}
                  setData={setData}
                />
              )
            },
            {
              tabName: "Response",
              tabContent: (
                <ViewLogsHistory
                  manifestId={id}
                  history={history}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );

}

export default ViewPage
