import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import { Box, Grid, makeStyles, Select, Tooltip, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from 'src/paths/pathMap'

import Namespace from 'src/constants/locale/Namespace'
import AceOcnManifestApiUrls from 'src/apiUrls/AceOcnManifestApiUrls'
import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import AceOcnBillofladingKeys from 'src/constants/locale/key/AceOcnBilloflading'
import BillofLadingFormProperties from '../../billoflading/FormProperties'
import Table from '../../../../components/aciacehighway/Table'
import AttachBillofLadingDialog from './AttachBillofLadingDialog'
import BillofLadingDialog from './BillofLadingDialog'
import ConfirmDialog from '../../../common/ConfirmDialog'
import SetResponseMessageDialog from './SetResponseMessageDialog'
import ChargeTypeDialog from '../../../common/ChargeTypeDialog'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'
import moment from 'moment-timezone'
import { format } from 'date-fns'
import { getStatusMetadata } from '../../../../common/NACommon'
import { FileForUserGetUserDetails,GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'

const { button: { CngIconButton },
table: {
    useFetchCodeMaintenanceLookup
  } } = components

const { filter: { EQUAL, LIKE } } = constants

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.name === 'ONE_DARK' ? 'transparent' : theme.palette.common.white,
    fontSize: 14,
    maxWidth: '100%',
    width: 150,
    '& > .MuiOutlinedInput-input': {
      padding: 8
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32
    }
  }
}))

function BillAttachTable(props) {
  const {
    history,
    manifestId,
    onChangeStep,
    loading,
    onSetLoading,
    showNotification,
    step,
    manifestData
  } = props

  const { createRecord, deleteRecord, securedSendRequest, updateRecord } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const theme = useTheme()
  const tableRef = useRef(null)

  const { translate } = useTranslation([Namespace.UI_COMPONENT, Namespace.ACE_OCN_BILLOFLADING])
  const translatedTextsObject = makeTranslatedTextsObject()

  const [attachBillofLadingDialog, setAttachBillofLadingDialog] = useState(false)
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })
  const [billofLadingDialog, setBillofLadingDialog] = useState({ open: false, billofLading: null, isView: false })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, billofLading: null })
  const [chargeTypes, setChargeTypes] = useState([])
  const [isSuperParty, setSuperParty] = useState(false);
  const [submitDialog, setSubmitDialog] = useState({ open: false, billofLadings: [] })
  const [amendmentCodeOptions, setAmendmentCodeOptions] = useState([])
  const [setResponseMsgDialog, setSetResponseMsgDialog] = useState({ open: false, billofLadings: [] })

  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    let manifestId = 0;
    if (manifestData != null && manifestData != undefined && manifestData != "") {
      manifestId = manifestData.current.id;
    }
    const filter = {
      baseFilterDTO: {
        filterType: "AND",
        filterProperties: [{ fieldName: "manifestId", operatorType: "EQUAL", value1: manifestId }],
        sortProperties: []
      }
    }

    Promise.all([
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      ),
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SYSTEM_STATUS' }],
        undefined,
        'code'
      ),
      //ace ocean bill
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_OCN_BL_INDICATOR' }],
        undefined,
        'code'
      ),
    ]).then(
      ([
        billingSuperParty,
        billingChargeTypes,
        status,
        billIndicator,
      ]) => {
        console.log(billingChargeTypes)
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
        
        setLookups({
          status,
          billIndicator,
        })
      });
  }, []);

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function handleDetachBills(rows) {
    const result = {
      manifestId: Number(manifestId),
      billofLadingId: rows.map((billofLading) => billofLading.id)
    }

    securedSendRequest.execute(
      'POST',
      AceOcnManifestApiUrls.DETACH,
      result,
      (response) => {
        const data = response.data
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else if (
          data.errorMessages === null ||
          data.errorMessages === undefined
        ) {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }
          showNotification('success', 'Detached successfully')
        }
      },
      (error) => console.log(error)
    )
  }

  function handleAttachBill(rows) {
    const result = {
      manifestId,
      billofLadingId: [rows.id]
    }

    securedSendRequest.execute(
      'POST',
      AceOcnManifestApiUrls.ATTACH,
      result,
      (response) => {
        const data = response.data
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else if (
          data.errorMessages === null ||
          data.errorMessages === undefined
        ) {
          tableRef.current.performRefresh()
          showNotification('success', translatedTextsObject.attachSuccessMsg)
        }
      },
      (error) => console.log(error)
    )
  }

  function handleCreateBillofLading(data) {
    onSetLoading(true)

    createRecord.execute(
      AceOcnBillofladingApiUrls.POST,
      BillofLadingFormProperties.toServerDataFormat(data),
      (res) => {
        const data = res
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else {
          tableRef.current.performRefresh()
          setBillofLadingDialog({ open: false, billofLading: null, isView: false })
          showNotification('success', translatedTextsObject.saveSuccessMsg)
        }

      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleEditBillofLading(data) {
    onSetLoading(true)
    updateRecord.execute(
      AceOcnBillofladingApiUrls.PUT,
      BillofLadingFormProperties.toServerDataFormat(data),
      () => {
        tableRef.current.performRefresh()
        setBillofLadingDialog({ open: false, billofLading: null, isView: false })
        showNotification('success', 'Bill of Lading updated.')
      },
      (error) => {
        if (error.response.data.errorMessages) {
          error.response.data.errorMessages.forEach((errorMessage) => {
            showNotification('error', errorMessage)
          })
        }
      },
      () => onSetLoading(false)
    )
  }

  function handleDeleteBillofLading() {
    if (confirmDialog.billofLading) {
      deleteRecord.execute(
        AceOcnBillofladingApiUrls.DELETE,
        confirmDialog.billofLading,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, billofLading: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handleCloneBillofLading(data) {
    createRecord.execute(
      AceOcnBillofladingApiUrls.CLONE,
      data,
      (res) => {
        res.manifestId = manifestId
        console.log(res)

        history.push({
          pathname: pathMap.ACE_OCN_BILLOFLADING_ADD_VIEW,
          state: res
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function preSubmitBills(billofLadings) {
    if (isSuperParty) {
      setSubmitDialog({ open: true, billofLadings: billofLadings })
    } else {
      billofLadings.forEach(e => {
        e.chargeAs = 'REGULAR'
      })
      handleSubmitbillofLadings(billofLadings)
    }
  }

  function handleSubmitbillofLadings(billofLadings) {
    try {
      tableRef.current.setLoading(true)
      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        billofLadings.forEach(data => {
          data.fileForUserId = fileForUserDetails.fileForUserId;
          data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        });
      }

      securedSendRequest.execute(
        'POST',
        AceOcnManifestApiUrls.SUBMIT_ALL,
        billofLadings,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          var obj = data;
          if (obj.errorMessages != null && obj.errorMessages.length > 0) {
            errorMessages.push(obj.errorMessages)
            errorFlag = true
          }

          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMessage)
            tableRef.current.performRefresh()
          }
        },
        error => {
          console.log(error)
          showNotification('error', translatedTextsObject.submitErrorMessage)
        },
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function handleModifyBillofLadings(billofLadings) {
    if (tableRef.current) {
      tableRef.current.updateData(billofLadings)
    }
  }

  function handlePrintBol(bol) {
    function onSuccess(response) {
      let blob = new Blob([response.data]);

      let first = "ACE_OCN_BL";
      let curDate = format(new Date(), 'yyyyMMddHHmmss');

      let filename = first + curDate + ".pdf";
      // if (bol.length > 1) {
      //   filename = first + curDate + ".zip";
      // }

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }

    function onError(error) {
      console.error(error);
    }

    function onComplete() {
    }

    const config = {
      responseType: 'blob'
    }

    let bolList = bol.map((x) => x.billNumber);
    console.log("bolList:" + JSON.stringify(bolList));
    let fileForUserDetails = FileForUserGetUserDetails();

    const data = {
      bolList: bolList,
      fileForUserId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserId : null,
      fileForUserLoginId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserLoginId : null,
      fileForUserPartyId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserPartyId : null
    }
    securedSendRequest.execute('POST', AceOcnBillofladingApiUrls.PRINTBOL, data, onSuccess, onError, onComplete, config, null);

  }

  function handlePrintResponse(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AceOcnBillofladingApiUrls.PRINTRESPONSE,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACE_OCN_RESPONSE_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function makeTranslatedTextsObject() {
    let aceOcnBilloflading = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TITLE
    )
    let manifestId = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.MANIFEST_ID
    )
    let billIndicator = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.BILL_INDICATOR
    )
    let billNumber = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.BILL_NUMBER
    )
    let issuerScac = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ISSUER_SCAC
    )
    let oceanBillNumber = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.OCEAN_BILL_NUMBER
    )
    let notifyScac = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.NOTIFY_SCAC
    )
    let carrierScac = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CARRIER_SCAC
    )
    let vesselName = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.VESSEL_NAME
    )
    let voyageNumber = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.VOYAGE_NUMBER
    )
    let vesselCountry = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.VESSEL_COUNTRY
    )
    let portOfDischarge = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.PORT_OF_DISCHARGE
    )
    let etaDateArr = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.E_T_A
    )
    let placeofReciept = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.PLACEOF_RECIEPT
    )
    let portOfLoading = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.PORT_OF_LOADING
    )
    let lastForeignPort = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.LAST_FOREIGN_PORT
    )
    let partyId = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SUBMITTED_DATE
    )
    let templateName = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TEMPLATE_NAME
    )
    let templateFlag = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TEMPLATE_FLAG
    )
    let modeOfSubmission = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.MODE_OF_SUBMISSION
    )
    let status = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.STATUS
    )
    let totalPackages = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TOTAL_PACKAGES
    )
    let totalPackagesUom = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TOTAL_PACKAGES_UOM
    )
    let totalGrossWeight = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TOTAL_GROSS_WEIGHT
    )
    let totalGrossWeightUom = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TOTAL_GROSS_WEIGHT_UOM
    )
    let totalVolume = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TOTAL_VOLUME
    )
    let totalValueUom = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.TOTAL_VALUE_UOM
    )
    let messageFunCode = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.MESSAGE_FUN_CODE
    )
    let commercialParties = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.TITLE
    )
    let headerId = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.HEADER_ID
    )
    let partyIdentifier = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.PARTY_IDENTIFIER
    )
    let partyType = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.PARTY_TYPE
    )
    let partyName = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.PARTY_NAME
    )
    let address1 = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.ADDRESS1
    )
    let address2 = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.ADDRESS2
    )
    let address3 = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.ADDRESS3
    )
    let telNo = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CommercialParties.TEL_NO
    )
    let containerDetails = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ContainerDetails.TITLE
    )
    let containerNumber = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ContainerDetails.CONTAINER_NUMBER
    )
    let typeCode = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ContainerDetails.TYPE_CODE
    )
    let descriptionCode = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ContainerDetails.DESCRIPTION_CODE
    )
    let sealNumber = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ContainerDetails.SEAL_NUMBER
    )
    let serviceCode = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ContainerDetails.SERVICE_CODE
    )
    let containerStatus = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.ContainerDetails.CONTAINER_STATUS
    )
    let goodsDetails = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.TITLE
    )
    let containerId = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.CONTAINER_ID
    )
    let containerDesc = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.CONTAINER_DESC
    )
    let goodsDesc = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.GOODS_DESC
    )
    let numberOfPackages = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.NUMBER_OF_PACKAGES
    )
    let packageUom = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.PACKAGE_UOM
    )
    let marksAndNum = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.MARKS_AND_NUM
    )
    let grossWeight = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.GROSS_WEIGHT_UOM
    )
    let volume = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.VOLUME
    )
    let volumeUOM = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.GoodsDetails.VOLUME_U_O_M
    )
    let dGgoodsDetails = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.TITLE
    )
    let _goodsDetailsId = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.GOODS_DETAILS
    )
    let imdgNo = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.IMDG_NO
    )
    let flashPointTemp = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.FLASH_POINT_TEMP
    )
    let dgCode = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.DG_CODE
    )
    let dgClass = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.DG_CLASS
    )
    let dgCodeQual = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.DG_CODE_QUAL
    )
    let hazardousDesc = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.HAZARDOUS_DESC
    )
    let hazardousPageNo = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.HAZARDOUS_PAGE_NO
    )
    let materialDesc = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.MATERIAL_DESC
    )
    let materialClass = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.MATERIAL_CLASS
    )
    let emergencyContact = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DGgoodsDetails.EMERGENCY_CONTACT
    )

    let msgFuncOriginal = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.MSG_FUNC_ORIGINAL
    )

    let msgFuncChange = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.MSG_FUNC_CHANGE
    )

    let msgFuncCancel = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.MSG_FUNC_CANCEL
    )

    let msgFuncProposedAmendment = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.MSG_FUNC_PROPOSED_AMENDMENT
    )

    let submitButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SUBMIT_BUTTON
    )

    let submitSentRecord = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SUBMIT_SENT_RECORD
    )

    let detachButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DETACH_BUTTON
    )

    let detachSentRecord = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DETACH_SENT_RECORD
    )

    let editButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.EDIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.EDIT_SENT_RECORD
    )

    let cloneButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DELETE_BUTTON
    )

    let deleteNotDraftRecord = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DELETE_NOT_DRAFT_RECORD
    )

    let backButton = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.BACK_BUTTON
    )

    let detachSuccessMsg = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DETACH_SUCCESS_MESSAGE
    )

    let saveSuccessMsg = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SAVE_SUCCESS_MSG
    )

    let submitSuccessMessage = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SUBMIT_SUCCESS_MSG
    )

    let selectRecordSubmit = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.SELECT_RECORD_SUBMIT
    )

    let dispositionCode = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.DISPOSITION_CODE
    )

    let createdDate = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.CREATED_DATE
    )
    let printBol = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.PRINTBOL
    )
    let printResponse = translate(
      Namespace.ACE_OCN_BILLOFLADING,
      AceOcnBillofladingKeys.PRINTRESPONSE
    )

    return {
      aceOcnBilloflading,
      manifestId,
      billIndicator,
      billNumber,
      issuerScac,
      oceanBillNumber,
      notifyScac,
      carrierScac,
      vesselName,
      voyageNumber,
      vesselCountry,
      portOfDischarge,
      etaDateArr,
      placeofReciept,
      portOfLoading,
      lastForeignPort,
      //partyId,
      //submittedBy,
      //submittedDate,
      templateName,
      templateFlag,
      modeOfSubmission,
      status,
      totalPackages,
      totalPackagesUom,
      totalGrossWeight,
      totalGrossWeightUom,
      totalVolume,
      totalValueUom,
      messageFunCode,
      commercialParties,
      headerId,
      partyIdentifier,
      partyType,
      partyName,
      address1,
      address2,
      address3,
      telNo,
      containerDetails,
      containerNumber,
      typeCode,
      descriptionCode,
      sealNumber,
      serviceCode,
      containerStatus,
      goodsDetails,
      containerId,
      containerDesc,
      goodsDesc,
      numberOfPackages,
      packageUom,
      marksAndNum,
      grossWeight,
      grossWeightUom,
      volume,
      volumeUOM,
      dGgoodsDetails,
      _goodsDetailsId,
      imdgNo,
      flashPointTemp,
      dgCode,
      dgClass,
      dgCodeQual,
      hazardousDesc,
      hazardousPageNo,
      materialDesc,
      materialClass,
      emergencyContact,

      msgFuncOriginal,
      msgFuncChange,
      msgFuncCancel,
      msgFuncProposedAmendment,
      submitButton,
      submitSentRecord,
      detachButton,
      detachSentRecord,
      editButton,
      editSentRecord,
      cloneButton,
      deleteButton,
      deleteNotDraftRecord,
      backButton,
      detachSuccessMsg,
      saveSuccessMsg,
      submitSuccessMessage,
      selectRecordSubmit,
      dispositionCode,
      createdDate,
      printBol,
      printResponse
    }
  }

  const columns = [
    {
      field: "billNumber",
      sortKey: "billNumber",
      title: translatedTextsObject.billNumber,
    },
    {
      field: "oceanBillNumber",
      sortKey: "oceanBillNumber",
      title: translatedTextsObject.oceanBillNumber,
    },
    {
      field: "vesselName",
      sortKey: "vesselName",
      title: translatedTextsObject.vesselName,
    },
    {
      field: "voyageNumber",
      sortKey: "voyageNumber",
      title: translatedTextsObject.voyageNumber,
    },
    {
      field: "etaDateArr",
      sortKey: "etaDateArr",
      title: translatedTextsObject.etaDateArr,
    },
    {
      field: "messageFunCode",
      sortKey: "messageFunCode",
      title: translatedTextsObject.messageFunCode,
      render: (rowData) => {
        const selectedRows = tableRef.current.selectedRows
        const isSelected =
          selectedRows.length > 0
            ? selectedRows.map((rowData) => rowData.id).includes(rowData.id)
            : false

        return (
          <MessageFuncAndAmendmentCode
            amendmentCodeOptions={amendmentCodeOptions}
            isSelected={isSelected}
            isSentStatus={rowData.status === '1008'}
            onUpdateBillofLading={handleModifyBillofLadings}
            onSubmitBill={(data) => preSubmitBills([data])}
            billofLading={rowData}
            translatedTextsObject={translatedTextsObject}
          />
        )
      }
    },
    {
      field: "dispositionCode",
      sortKey: "dispositionCode",
      title: translatedTextsObject.dispositionCode,
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (data) => {

        let status = getStatusMetadata(data.status === 'AC' ? '1000' : data.status)

        let content = [
          <Box>
            <Typography variant='inherit' style={{ color: status.color }}>
              {status.label}
            </Typography>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {data.responseDatetime && moment(data.responseDatetime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
        ]

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            {content}
          </Box>
        )
      }
    }
  ]

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => {

                let bill = BillofLadingFormProperties.formikProps.initialValues;

                if (manifestData != null && manifestData != undefined && manifestData != "") {
                  bill.carrierScac = manifestData.current.scac
                  bill.vesselName = manifestData.current.vesselName
                  bill.voyageNumber = manifestData.current.voyageNumber
                  bill.vesselCountry = manifestData.current.vesselCountry
                  bill.portOfDischarge = manifestData.current.portOfDischarge
                  bill.etaDateArr = manifestData.current.etaDate
                }
                console.log("bill", bill)
                setBillofLadingDialog({ open: true, billofLading: bill, isView: false })
              }
            },
            label: 'Create new Bill Of Lading'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'link']} />,
              onClick: () => setAttachBillofLadingDialog(true)
            },
            label: 'Attach Bill Of Lading'
          },
        ]}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            disabled: (rowData) => {
              let disableButton=false;
              if(rowData.status === '1001' || rowData.status === '1000' || rowData.status === '1008' || rowData.status === '1006'){
                 disableButton=true;
                 return disableButton;
            }},
            iconButtonProps: {
              icon: ['fal', 'minus-circle'],
              style: { color: theme.palette.error.main }
            },
            onClick: (rowData) => handleDetachBills([rowData]),
            tooltip: (rowData) =>  {if(rowData.status === '1001' || rowData.status === '1000' || rowData.status === '1008' || rowData.status === '1006') {
              let displayError = true
              return displayError ? 'Only Draft and Cancel Records can be detached' : null
            }
          }
          }
        ]}
        fetch={{ url: AceOcnBillofladingApiUrls.GET }}
        fetchFilters={[
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'manifestId', operator: EQUAL, value: manifestId },
        ]}
        onRowClick={(rowData) =>
          setBillofLadingDialog({ open: true, billofLading: BillofLadingFormProperties.toClientDataFormat(rowData), isView: true })
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) => {
              setBillofLadingDialog({
                open: true,
                billofLading: BillofLadingFormProperties.toClientDataFormat(rowData),
                isView: false
              })},
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? 'Sent records cannot be Edited'
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneBillofLading
          },
          {
            disabled: (rowData) => rowData.status !== '1005',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, billofLading: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005'
                ? 'Only Draft records can be deleted'
                : null
          },
          {
            disabled: (rowData) => rowData.status == '1005' && rowData.status == '1008',
            label: translatedTextsObject.printResponse,
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintResponse
          }
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: { minWidth: 'auto' }
            },
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printBol,
            onClick: (rows) => handlePrintBol(rows),
            disabled: (rows) => rows.length > 100,
            tooltip: (rows) => rows.length > 100 ? "Maximum of 100 records allowed" : null
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: { minWidth: 'auto' }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === '1008') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.submitButton,
            onClick: (rows) => preSubmitBills(rows),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === '1008') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords ? translatedTextsObject.submitSentRecord : null
            }
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: { minWidth: 'auto' }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === '1008') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'cog']} />,
            label: 'Set Message Function',
            onClick: (rows) => setSetResponseMsgDialog({ open: true, billofLadings: rows }),
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (rowData.status === '1008') {
                  includesSentRecords = true
                }
              })

              return includesSentRecords ? translatedTextsObject.submitSentRecord : null
            }
          },
          {
            buttonProps: {
              size: 'medium',
              color: 'secondary',
              style: { color: theme.palette.error.main, minWidth: 'auto' }
            },
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (
                  rowData.status === '1008' || rowData.status === '1000' || rowData.status === '1006'
                ) {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'minus-circle']} />,
            label: translatedTextsObject.detachButton,
            onClick: handleDetachBills,
            tooltip: (rows) => {
              let includesSentRecords = false

              rows.forEach((rowData) => {
                if (
                  rowData.status === '1008' || rowData.status === '1000' || rowData.status === '1006'
                ) {
                  includesSentRecords = true
                }
              })

              return includesSentRecords ? translatedTextsObject.detachSentRecord : null
            }
          }
        ]}
        search={{ field: 'ccn', operator: LIKE }}
        showNotification={showNotification}
        tableRef={tableRef}
      />
      <AttachBillofLadingDialog
        columns={columns}
        manifestId={manifestId}
        open={attachBillofLadingDialog}
        onAttachBillofLading={() => {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }
        }}
        onClose={() => setAttachBillofLadingDialog(false)}
        showNotification={showNotification}
      />
      <BillofLadingDialog
        isView={billofLadingDialog.isView}
        manifestId={manifestId}
        loading={loading}
        onClose={() =>
          setBillofLadingDialog({ open: false, billofLading: null, isView: false })
        }
        onCreateBillofLading={handleCreateBillofLading}
        onEditBillofLading={handleEditBillofLading}
        open={billofLadingDialog.open}
        billofLading={billofLadingDialog.billofLading}
        showNotification={showNotification}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, billofLading: null })}
        confirmDialog={handleDeleteBillofLading}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <SetResponseMessageDialog
        amendmentCodeOptions={amendmentCodeOptions}
        onClose={() => setSetResponseMsgDialog({ open: false, billofLadings: [] })}
        onSaveBillofLading={(billofLadings) => {
          handleModifyBillofLadings(billofLadings)
          setSetResponseMsgDialog({ open: false, billofLadings: [] })
        }}
        onSubmitBillofLading={preSubmitBills}
        open={setResponseMsgDialog.open}
        billofLadings={setResponseMsgDialog.billofLadings}
        translatedTextsObject={translatedTextsObject}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, billofLadings: [] })}
        onSubmit={(data) => {
          handleSubmitbillofLadings(data)
        }}
        open={submitDialog.open}
        items={submitDialog.billofLadings}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </>
  )
}

export default BillAttachTable


function MessageFuncAndAmendmentCode(props) {
  const {
    amendmentCodeOptions,
    isSelected,
    isSentStatus,
    onSubmitBill,
    billofLading,
    onUpdateBillofLading,
    translatedTextsObject
  } = props

  const theme = useTheme()
  const classes = useStyles()

  function handleSubmitButtonClick(event) {
    event.stopPropagation()
    onSubmitBill(billofLading)
  }

  return (
    <Grid alignItems='center' container spacing={1} wrap='nowrap'>
      <Grid item xs='auto'>
        <Select
          className={classes.select}
          disabled={isSentStatus || !isSelected}
          native
          onChange={(event) =>
            onUpdateBillofLading({ ...billofLading, messageFunCode: event.target.value })
          }
          onClick={(event) => event.stopPropagation()}
          size='small'
          value={billofLading.messageFunCode || ''}
          variant='outlined'
        >
          <option value="" disabled>
            {translatedTextsObject.messageFunction}
          </option>
          <option value='A'>{translatedTextsObject.msgFuncOriginal}</option>
          <option value='R'>{translatedTextsObject.msgFuncChange}</option>
          <option value='D'>{translatedTextsObject.msgFuncCancel}</option>
        </Select>
      </Grid>
      <Grid item xs='auto'>
        <Tooltip
          arrow
          placement='top'
          title={
            isSentStatus
              ? translatedTextsObject.submitSentRecord
              : !isSelected ? translatedTextsObject.selectRecordSubmit : translatedTextsObject.submitButton
          }
        >
          <span>
            <CngIconButton
              disabled={isSentStatus || !isSelected || !billofLading.messageFunCode}
              size='small'
              icon={['fal', 'arrow-alt-right']}
              onClick={handleSubmitButtonClick}
              style={{
                color:
                  isSentStatus || !isSelected
                    ? theme.palette.action.disabled
                    : theme.palette.primary.main
              }}
              type='outlined'
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
