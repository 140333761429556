import React, { useState, useContext } from 'react'
import StatusBar from 'src/components/aciacehighway/StatusBar'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'
import moment from 'moment-timezone'
import { Box, Card, Grid, Typography, useTheme } from '@material-ui/core'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import TinyChip from '../../../components/aciacehighway/TinyChip'

function VesselArrivalViewContent(props) {
    const { id, data, getLookupValue } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const theme = useTheme()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StatusBar status={data.arrivalStatus} />
            </Grid>

            <Grid item xs={12}>
                <CngSection title={getTranslatedText("VESSEL_ARRIVAL", "aceOcnVesselArrival")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "arrivalStatus")}>
                                {getLookupValue("status", data.arrivalStatus)}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "carrierScac")}>
                                {data.carrierScac}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "vesselName")}>
                                {data.vesselName}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "voyageNumber")}>
                                {data.voyageNumber}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "vesselCountry")}>
                                <Typography component='div' variant='inherit'>
                                    {getLookupValue('country', data.vesselCountry)}
                                    <TinyChip label={data.vesselCountry} variant='outlined' />
                                </Typography>
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "portOfDischarge")}>
                                <Typography component='div' variant='inherit'>
                                    {getLookupValue('portOfDischarge', data.portOfDischarge)}
                                    <TinyChip label={data.portOfDischarge} variant='outlined' />
                                </Typography>
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "arrivalDate")}>
                                {data.arrivalDate}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CngField label={getTranslatedText("VESSEL_ARRIVAL", "arrivalTime")}>
                                {data.arrivalTime}
                            </CngField>
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>
        </Grid>
    )
}

export default VesselArrivalViewContent