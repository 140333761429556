import React, { useEffect, useState, useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import { useFormContext} from "react-hook-form"

const {
    form: {
        field: {
            CngTextField,
            CngCodeMasterAutocompleteField
        }
    },
    CngGridItem
} = components

function BillOfLadingGoodsDetailsSection(props) {
    const { disabled, shouldHideMap, goodsDetails } = props
    const { setValue } = useFormContext();
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)

    const [formData, setFormData] = useState({
        totalPackages: 0,
        totalGrossWeight: 0,
        totalVolume: 0,
        totalValueUom: '',
        totalPackagesUom: '',
        totalGrossWeightUom: '',
      });


    useEffect(() => {
        setValue('totalPackages', formData.totalPackages);
        setValue('totalGrossWeight', formData.totalGrossWeight);
        setValue('totalVolume', formData.totalVolume);
        setValue('totalValueUom', formData.totalValueUom);
        setValue('totalPackagesUom', formData.totalPackagesUom);
        setValue('totalGrossWeightUom', formData.totalGrossWeightUom);
      }, [formData, setValue]);

    useEffect(() => {
        if (goodsDetails.length > 0) {
            const firstItem = goodsDetails[0]; 
            const firstUom = firstItem.packageUom; // Extract the uom value
            setFormData((prevFormData) => ({
                ...prevFormData,
                totalPackages: goodsDetails.reduce((acc, goodDetail) => {const numberOfPackages = parseFloat(goodDetail.numberOfPackages); return acc + (isNaN(numberOfPackages) ? 0 : numberOfPackages)}, 0),
                totalGrossWeight: goodsDetails.reduce((acc, goodDetail) => {const grossWeight = parseFloat(goodDetail.grossWeight); return acc + (isNaN(grossWeight) ? 0 : grossWeight)}, 0),
                totalVolume: goodsDetails.reduce((acc, goodDetail) => {const volume = parseFloat(goodDetail.volume); return acc + (isNaN(volume) ? 0 : volume)}, 0),
                totalValueUom: firstItem.volumeUOM && firstItem.volumeUOM.trim() !== '' ? firstItem.volumeUOM : 'CF',
                totalPackagesUom: firstItem.packageUom,
                totalGrossWeightUom: firstItem.grossWeightUom,
                
              }));
        }
      }, [goodsDetails])

    return (
        <Grid item xs={12} md={12}>
            <CngSection title={"Goods Information"}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>

                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalPackages}>
                        <CngTextField
                            name='totalPackages'
                            label={getTranslatedText('BOL', 'totalPackages')}
                            disabled={disabled}
                            required
                            size='small'
                            value={formData.totalPackages}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalPackagesUom}>
                        <CngCodeMasterAutocompleteField
                            name='totalPackagesUom'
                            label={getTranslatedText('BOL', 'totalPackagesUom')}
                            disabled={disabled}
                            codeType='BOL_PACKAGES_UOM'
                            lookupProps={{
                                label: (record) => `${record.code};${record.descriptionEn}`
                            }}
                            required
                            key={formData.totalPackagesUom}
                            value={formData.totalPackagesUom}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalGrossWeight}>
                        <CngTextField
                            name='totalGrossWeight'
                            label={getTranslatedText('BOL', 'totalGrossWeight')}
                            disabled={disabled}
                            required
                            size='small'
                            value={formData.totalGrossWeight}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalGrossWeightUom}>
                        <CngCodeMasterAutocompleteField
                            name='totalGrossWeightUom'
                            label={getTranslatedText('BOL', 'totalGrossWeightUom')}
                            disabled={disabled}
                            codeType='BOL_GOODS_GROSS_UOM'
                            required
                            size='small'
                            key={formData.totalGrossWeightUom}
                            value={formData.totalGrossWeightUom}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalVolume}>
                        <CngTextField
                            name='totalVolume'
                            label={getTranslatedText('BOL', 'totalVolume')}
                            disabled={disabled}
                            size='small'
                            value={formData.totalVolume}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalValueUom}>
                        <CngCodeMasterAutocompleteField
                            name='totalValueUom'
                            label={getTranslatedText('BOL', 'totalValueUom')}
                            disabled={disabled}
                            codeType='BOL_GOODS_VOLUME_UOM'
                            size='small'
                            key={formData.totalValueUom}
                            value={formData.totalValueUom}
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default BillOfLadingGoodsDetailsSection