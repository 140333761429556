import { Grid, Box, Card, useTheme } from '@material-ui/core'
import React, { useContext } from 'react'
import { components } from 'cng-web-lib'
import Stepper from '../../../components/aciacehighway/Stepper'
import VesselArrivalAddForm from './VesselArrivalAddPage'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {

  const theme = useTheme()
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  return (
    <Card>
      <Stepper steps={[getTranslatedText("VESSEL_ARRIVAL", "createVesselArrivalTab")]} activeStep={0} />
      <Box
        padding={2}
        style={{ backgroundColor: theme.palette.background.sectionOddBg }}
      >
        <VesselArrivalAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => console.log('submit success')}
          onSetLoading={onSetLoading}
        />
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)
