import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    containerid: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(9, 'Minimum length needed is 9.')
      .max(10, 'Max length allowed is 10.')
      .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed.')
  })
}

export default makeValidationSchema
