import React, { useRef, useState, useContext, useEffect } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { Chip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import pathMap from 'src/paths/pathMap'
import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import Table from 'src/components/aciacehighway/Table'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import { getStatusMetadata } from '../../../common/NACommon'
import { FileForUserGetPartyId, GetFilterByUserIdCriteria, FileForUserGetCurrentLoginId } from 'src/common/FileForUserCommon'
import TemplateSelectDialogWithOptionACEOCN from 'src/views/aceocean/billoflading/TemplateSelectDialogWithOptionACEOCN'
import { format } from 'date-fns'
import sweetalert from 'sweetalert'
import moment from 'moment-timezone'
import _ from 'lodash'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";

const { 
  table: { 
    useFetchCodeMaintenanceLookup 
  } 
} = components

const { filter: { EQUAL, IN, LIKE, BETWEEN } } = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const { securedSendRequest, deleteRecord } = useServices()
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  const [billTemplateDialog, setBillTemplateDialog] = useState(false)

  const tableRef = useRef(null)

  const [confirmDelete, setConfirmDelete] = useState({ open: false, bol: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, bol: null })
  const [confirmTemplateDialog, setConfirmTemplateDialog] = useState({ open: false, bol: null })
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [codeMaintenanceFetched, setCodeMaintenanceFetched] = useState(false)
  const [hqAdmin, setHqAdmin] = useState(false)

  let columns = [
    {
      field: 'billNumber',
      sortKey: 'billNumber',
      title: getTranslatedText('BOL', 'billNumber')
    },
    {
      field: 'oceanBillNumber',
      sortKey: 'oceanBillNumber',
      title: getTranslatedText('BOL', 'oceanBillNumber')
    },
    {
      field: 'vesselName',
      sortKey: 'vesselName',
      title: getTranslatedText('BOL', 'vesselName')
    },
    {
      field: 'voyageNumber',
      sortKey: 'voyageNumber',
      title: getTranslatedText('BOL', 'voyageNumber')
    },
    {
      field: 'etaDateArr',
      sortKey: 'etaDateArr',
      title: getTranslatedText('BOL', 'etaDateArr'),
      render: (data) => (data.etaDateArr ? moment(data.etaDateArr).tz('Canada/Eastern').format('D MMM YYYY, HH:mm') : "")
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('BOL', 'createdDate'),
      render: (data) => data.createdDate && moment(data.createdDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: getTranslatedText('BOL', 'submittedDate'),
      render: (data) => data.submittedDate && moment(data.submittedDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: getTranslatedText('BOL', 'status'),
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip label={status.label} size='small'
            style={{ backgroundColor: status.color, color: status.contrastColor }}
          />
        )
      }
    },
    {
      field: 'dispositionCode',
      sortKey: 'dispositionCode',
      title: getTranslatedText('BOL', 'dispositionCode')
    },    
    {
      field: 'manifestId',
      sortKey: 'manifestId',
      title: getTranslatedText('BOL', 'manifestId')
    }
  ]

  let hqAdminColumns = [
    {
      field: 'billNumber',
      sortKey: 'billNumber',
      title: getTranslatedText('BOL', 'billNumber')
    },
    {
      field: 'oceanBillNumber',
      sortKey: 'oceanBillNumber',
      title: getTranslatedText('BOL', 'oceanBillNumber')
    },
    {
      field: 'vesselName',
      sortKey: 'vesselName',
      title: getTranslatedText('BOL', 'vesselName')
    },
    {
      field: 'voyageNumber',
      sortKey: 'voyageNumber',
      title: getTranslatedText('BOL', 'voyageNumber')
    },
    {
      field: 'etaDateArr',
      sortKey: 'etaDateArr',
      title: getTranslatedText('BOL', 'etaDateArr'),
      render: (data) => (data.etaDateArr ? moment(data.etaDateArr).tz('Canada/Eastern').format('D MMM YYYY, HH:mm') : "")
    },
    {
      field: 'createdByLoginId',
      sortKey: 'createdByLoginId',
      title: getTranslatedText('BOL', 'createdByLoginId')
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('BOL', 'createdDate'),
      render: (data) => data.createdDate && moment(data.createdDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: getTranslatedText('BOL', 'submittedDate'),
      render: (data) => data.submittedDate && moment(data.submittedDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: getTranslatedText('BOL', 'status'),
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip label={status.label} size='small'
            style={{ backgroundColor: status.color, color: status.contrastColor }}
          />
        )
      }
    },
    {
      field: 'manifestId',
      sortKey: 'manifestId',
      title: getTranslatedText('BOL', 'manifestId')
    }
  ]

  const filters = [
    {
      label: getTranslatedText('BOL', 'billNumber'),
      type: 'textfield',
      name: 'billNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'oceanBillNumber'),
      type: 'textfield',
      name: 'oceanBillNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'vesselName'),
      type: 'textfield',
      name: 'vesselName',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'voyageNumber'),
      type: 'textfield',
      name: 'voyageNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'etaDateArr'),
      type: 'daterange',
      name: 'etaDateArr',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('BOL', 'createdByLoginId'),
      type: 'textfield',
      name: 'createdByLoginId',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'createdDate'),
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('BOL', 'submittedDate'),
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('BOL', 'status'),
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        { label: 'Draft', value: 'draft', filterValue: { value: '1005' } },
        { label: 'Accepted', value: 'accepted', filterValue: { value: '1000' } },
        { label: 'Rejected', value: 'rejected', filterValue: { value: '1001' } },
        { label: 'Sent', value: 'sent', filterValue: { value: '1008' } },
        { label: 'Status Notification', value: 'status_notification', filterValue: { value: '1006' } },
        { label: 'Cancel', value: 'cancel', filterValue: { value: '1011' } },
      ]
    },
    {
      label: getTranslatedText('BOL', 'dispositionCode'),
      type: 'checkbox',
      name: 'dispositionCode',
      operatorType: IN,
      options: [
        { label: '6H', value: '6H', filterValue: { value: '6H' } },
        { label: '6I', value: '6I', filterValue: { value: '6I' } },
        { label: '1A', value: '1A', filterValue: { value: '1A' } },
        { label: '1B', value: '1B', filterValue: { value: '1B' } },
        { label: '1C', value: '1C', filterValue: { value: '1C' } },
        { label: '1F', value: '1F', filterValue: { value: '1F' } },
        { label: '1G', value: '1G', filterValue: { value: '1G' } },
        { label: '1H', value: '1H', filterValue: { value: '1H' } },
        { label: '1I', value: '1I', filterValue: { value: '1I' } },
        { label: '1J', value: '1J', filterValue: { value: '1J' } },
        { label: '1K', value: '1K', filterValue: { value: '1K' } },
        { label: '1L', value: '1L', filterValue: { value: '1L' } },
        { label: '1M', value: '1M', filterValue: { value: '1M' } },
        { label: '1N', value: '1N', filterValue: { value: '1N' } },
        { label: '1O', value: '1O', filterValue: { value: '1O' } },
        { label: '1P', value: '1P', filterValue: { value: '1P' } },
        { label: '1Q', value: '1Q', filterValue: { value: '1Q' } },
        { label: '1R', value: '1R', filterValue: { value: '1R' } },
        { label: '1S', value: '1S', filterValue: { value: '1S' } },
        { label: '1T', value: '1T', filterValue: { value: '1T' } },
        { label: '1U', value: '1U', filterValue: { value: '1U' } },
        { label: '1V', value: '1V', filterValue: { value: '1V' } },
        { label: '1W', value: '1W', filterValue: { value: '1W' } },
        { label: '1X', value: '1X', filterValue: { value: '1X' } },
        { label: '1Y', value: '1Y', filterValue: { value: '1Y' } },
        { label: '2F', value: '2F', filterValue: { value: '2F' } },
        { label: '2G', value: '2G', filterValue: { value: '2G' } },
        { label: '2H', value: '2H', filterValue: { value: '2H' } },
        { label: '2I', value: '2I', filterValue: { value: '2I' } },
        { label: '2Z', value: '2Z', filterValue: { value: '2Z' } },
        { label: '3F', value: '3F', filterValue: { value: '3F' } },
        { label: '3G', value: '3G', filterValue: { value: '3G' } },
        { label: '3H', value: '3H', filterValue: { value: '3H' } },
        { label: '3I', value: '3I', filterValue: { value: '3I' } },
        { label: '3Z', value: '3Z', filterValue: { value: '3Z' } },
        { label: '4A', value: '4A', filterValue: { value: '4A' } },
        { label: '4C', value: '4C', filterValue: { value: '4C' } },
        { label: '4E', value: '4E', filterValue: { value: '4E' } },
        { label: '5H', value: '5H', filterValue: { value: '5H' } },
        { label: '5I', value: '5I', filterValue: { value: '5I' } },
        { label: '6H', value: '6H', filterValue: { value: '6H' } },
        { label: '6I', value: '6I', filterValue: { value: '6I' } },
        { label: '01', value: '01', filterValue: { value: '01' } },
        { label: '02', value: '02', filterValue: { value: '02' } },
        { label: '03', value: '03', filterValue: { value: '03' } },
        { label: '04', value: '04', filterValue: { value: '04' } },
        { label: '11', value: '11', filterValue: { value: '11' } },
        { label: '12', value: '12', filterValue: { value: '12' } },
        { label: '13', value: '13', filterValue: { value: '13' } },
        { label: '14', value: '14', filterValue: { value: '14' } },
        { label: '15', value: '15', filterValue: { value: '15' } },
        { label: '16', value: '16', filterValue: { value: '16' } },
        { label: '17', value: '17', filterValue: { value: '17' } },
        { label: '18', value: '18', filterValue: { value: '18' } },
        { label: '19', value: '19', filterValue: { value: '19' } },
        { label: '20', value: '20', filterValue: { value: '20' } },
        { label: '21', value: '21', filterValue: { value: '21' } },
        { label: '22', value: '22', filterValue: { value: '22' } },
        { label: '23', value: '23', filterValue: { value: '23' } },
        { label: '24', value: '24', filterValue: { value: '24' } },
        { label: '25', value: '25', filterValue: { value: '25' } },
        { label: '26', value: '26', filterValue: { value: '26' } },
        { label: '27', value: '27', filterValue: { value: '27' } },
        { label: '28', value: '28', filterValue: { value: '28' } },
        { label: '50', value: '50', filterValue: { value: '50' } },
        { label: '51', value: '51', filterValue: { value: '51' } },
        { label: '52', value: '52', filterValue: { value: '52' } },
        { label: '53', value: '53', filterValue: { value: '53' } },
        { label: '54', value: '54', filterValue: { value: '54' } },
        { label: '55', value: '55', filterValue: { value: '55' } },
        { label: '56', value: '56', filterValue: { value: '56' } },
        { label: '57', value: '57', filterValue: { value: '57' } },
        { label: '58', value: '58', filterValue: { value: '58' } },
        { label: '59', value: '59', filterValue: { value: '59' } },
        { label: '60', value: '60', filterValue: { value: '60' } },
        { label: '61', value: '61', filterValue: { value: '61' } },
        { label: '62', value: '62', filterValue: { value: '62' } },
        { label: '63', value: '63', filterValue: { value: '63' } },
        { label: '64', value: '64', filterValue: { value: '64' } },
        { label: '65', value: '65', filterValue: { value: '65' } },
        { label: '66', value: '66', filterValue: { value: '66' } },
        { label: '67', value: '67', filterValue: { value: '67' } },
        { label: '68', value: '68', filterValue: { value: '68' } },
        { label: '69', value: '69', filterValue: { value: '69' } },
        { label: '7H', value: '7H', filterValue: { value: '7H' } },
        { label: '7I', value: '7I', filterValue: { value: '7I' } },
        { label: '70', value: '70', filterValue: { value: '70' } },
        { label: '71', value: '71', filterValue: { value: '71' } },
        { label: '72', value: '72', filterValue: { value: '72' } },
        { label: '73', value: '73', filterValue: { value: '73' } },
        { label: '74', value: '74', filterValue: { value: '74' } },
        { label: '75', value: '75', filterValue: { value: '75' } },
        { label: '76', value: '76', filterValue: { value: '76' } },
        { label: '77', value: '77', filterValue: { value: '77' } },
        { label: '78', value: '78', filterValue: { value: '78' } },
        { label: '79', value: '79', filterValue: { value: '79' } },
        { label: '80', value: '80', filterValue: { value: '80' } },
        { label: '81', value: '81', filterValue: { value: '81' } },
        { label: '82', value: '82', filterValue: { value: '82' } },
        { label: '83', value: '83', filterValue: { value: '83' } },
        { label: '84', value: '84', filterValue: { value: '84' } },
        { label: '85', value: '85', filterValue: { value: '85' } },
        { label: '86', value: '86', filterValue: { value: '86' } },
        { label: '87', value: '87', filterValue: { value: '87' } },
        { label: '88', value: '88', filterValue: { value: '88' } },
        { label: '89', value: '89', filterValue: { value: '89' } },
        { label: '90', value: '90', filterValue: { value: '90' } },
        { label: '91', value: '91', filterValue: { value: '91' } },
        { label: '92', value: '92', filterValue: { value: '92' } },
        { label: '93', value: '93', filterValue: { value: '93' } },
        { label: '94', value: '94', filterValue: { value: '94' } },
        { label: '95', value: '95', filterValue: { value: '95' } },
        { label: '96', value: '96', filterValue: { value: '96' } },
        { label: '97', value: '97', filterValue: { value: '97' } },
        { label: '98', value: '98', filterValue: { value: '98' } },
        { label: '99', value: '99', filterValue: { value: '99' } },
        { label: 'AR', value: 'AR', filterValue: { value: 'AR' } },
        { label: 'SR', value: 'SR', filterValue: { value: 'SR' } },
        { label: 'A1', value: 'A1', filterValue: { value: 'A1' } },
        { label: 'A2', value: 'A2', filterValue: { value: 'A2' } },
        { label: 'A3', value: 'A3', filterValue: { value: 'A3' } },
        { label: 'AAD', value: 'AAD', filterValue: { value: 'AAD' } },
        { label: 'BC', value: 'BC', filterValue: { value: 'BC' } },
        { label: 'BD', value: 'BD', filterValue: { value: 'BD' } },
        { label: 'BE', value: 'BE', filterValue: { value: 'BE' } },
        { label: 'BG', value: 'BG', filterValue: { value: 'BG' } },
        { label: 'BH', value: 'BH', filterValue: { value: 'BH' } },
        { label: 'OCA', value: 'OCA', filterValue: { value: 'OCA' } },
        { label: 'COC', value: 'COC', filterValue: { value: 'COC' } },
      ]
    },    
    {
      label: getTranslatedText('BOL', 'manifestId'),
      type: 'textfield',
      name: 'manifestId',
      operatorType: LIKE
    },
  ]

  function handleDeleteBOL() {
    if (!confirmDelete.bol) return

    setConfirmDelete({ open: false })

    deleteRecord.execute(AceOcnBillofladingApiUrls.DELETE, confirmDelete.bol,
      () => {
        showNotification('success', 'Delete succeeded')
        setConfirmDelete({ open: false, bol: null })
        tableRef.current.performRefresh()
      },
      (error) => { console.log(error) }
    )
  }
  
   function handleDeleteBOLTemplate() {
    if (!confirmTemplateDialog.bol) return
    setConfirmTemplateDialog({ open: false })
    deleteRecord.execute(AceOcnBillofladingApiUrls.DELETE, confirmTemplateDialog.bol,
      () => {
        showNotification('success', 'Delete succeeded')
        setConfirmTemplateDialog({ open: false, bol: null })
        setBillTemplateDialog(false)
      },
      (error) => { console.log(error) }
    )
  }

  function DisableEditButton(rowData) {
    return rowData.status == '1008'
  }

  function DisableDeleteButton(rowData) {
    return rowData.status != '1005' && rowData.status != '1002'
  }

  async function exportBOL(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      securedSendRequest.execute('POST', AceOcnBillofladingApiUrls.EXPORT_BOL, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'ACEOCN_BOL_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  useEffect(() => {

    Promise.all([
      //HQ_ADMINISTRATOR
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'HQ_ADMINISTRATOR' },
          { field: 'code', operator: EQUAL, value: FileForUserGetCurrentLoginId() }
        ],
        undefined,
        'code'
      )
    ]).then(
      ([hqAdmin]) => {
        
        if(hqAdmin && hqAdmin[FileForUserGetCurrentLoginId()] == "true"){
          setHqAdmin(true)
        }
        setCodeMaintenanceFetched(true);
      }
    )

    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, [])

  let filter = GetFilterByUserIdCriteria("ACEOCN")

  return (
    <>
      {sbPartyIdFetched && codeMaintenanceFetched && ( <Table
        actions={[
          {
            label: 'Create Bill of Lading',
            buttonProps: {
              color: 'primary', size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACE_OCN_BILLOFLADING_ADD_VIEW)
            }
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setBillTemplateDialog(true)
            },
            label: 'ACEOCN bill Template'
          }
        ]}
        columns={hqAdmin ? hqAdminColumns : columns}
        compact
        checkboxSelection
        fetch={{ url: AceOcnBillofladingApiUrls.GET }}
        fetchFilters={[
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (DisableEditButton(rowData)) {
            history.push(`${location.pathname}/view/${rowData.id}`)
          } else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }}
        rowActions={[
          {
            label: getTranslatedText('BOL', 'editButton'),
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            disabled: (rowData) => DisableEditButton(rowData),
            onClick: (rowData) => history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) => DisableEditButton(rowData) ? 'Sent records cannot be Edited' : null
          },
          {
            label: getTranslatedText('BOL', 'deleteButton'),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            disabled: (rowData) => DisableDeleteButton(rowData),
            onClick: (rowData) => setConfirmDelete({ open: true, bol: rowData }),
            tooltip: (rowData) => DisableDeleteButton(rowData) ? 'Only Draft records can be deleted' : null
          },
        ]}
        showNotification={showNotification}
        sortConfig={{ type: 'column', defaultField: 'createdDate', defaultDirection: 'DESC' }}
        persistSettings
        tableRef={tableRef}
        tablePreference={{ module: 'ACEOCN', key: 'bol_table_visible_column' }}
        selectActions={[
          {
            // disabled: (rows) => rows.length > 100,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export Bill of Lading(s)",
            onClick: (rows) => {
              sweetalert("Exporting Bill of Lading in the background. The report will be downloaded automatically once ready.", "", "success");
              return exportBOL(rows);
            }
          }
        ]}
      />)}

      <ConfirmDialog
        isConfirmDialogOpen={confirmDelete.open}
        closeDialog={() => setConfirmDelete({ open: false, bol: null })}
        confirmDialog={handleDeleteBOL}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('BOL', 'deleteButton')}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmTemplateDialog.open}
        closeDialog={() => setConfirmTemplateDialog({ open: false, bol: null })}
        confirmDialog={handleDeleteBOLTemplate}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('BOL', 'deleteButton')}
      />
      <TemplateSelectDialogWithOptionACEOCN
         manifestTemplateDialog={billTemplateDialog}
         setManifestTemplateDialog={setBillTemplateDialog}
         setConfirmDialog={setConfirmTemplateDialog}
         showNotification={showNotification}
         isTable={true}
         location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(pathMap.ACE_OCN_BILLOFLADING+`/edit/${rowData.id}`),
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
            setConfirmTemplateDialog({ open: true, bol: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      />
    </>
  )
}

export default TablePage
